import { gql } from '@apollo/client';
import { ErrorMessageInfo, PatientInfoShared } from '@retainerclub/shared-api';

export default gql`
  mutation updateLabNote($input: LabNoteUpdateInput!) {
    updateLabNote(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      patient {
        ...PatientInfoShared
      }
    }
  }
  ${PatientInfoShared}
  ${ErrorMessageInfo}
`;
