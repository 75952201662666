/* eslint-disable jsx-a11y/control-has-associated-label */
import PropTypes from 'prop-types';
import dobFormatter from '../../../transforms/dobFormatter';
import Badge from '../../Badge/Badge';
import Button from '../../Button/Button';
import Card from '../../Card/Card';
import RcLink from '../../RcLink/RcLink';
import ShowTableStandard from '../ShowTableStandard/ShowTableStandard';
import ShowTableStandardRow from '../ShowTableStandard/ShowTableStandardRow';

function ShowPatients({ patients = [], title = 'Patients', className = '' }) {
  return (
    <Card className={`p-4 ${className}`} heading={title}>
      <ShowTableStandard
        tHead={
          <>
            <th scope="col" className="p-4">
              Name
            </th>
            <th scope="col" className="hidden p-4 sm:table-cell">
              DOB
            </th>
            <th scope="col" className="hidden p-4 sm:table-cell">
              Arches
            </th>
            <th scope="col" className="hidden p-4 sm:table-cell">
              EOT Included?
            </th>
            <th scope="col" className="hidden p-4 sm:table-cell">
              Ship First Set To Clinic?
            </th>
            <th scope="col" className="hidden p-4 sm:table-cell">
              Scan Ready?
            </th>
            <th scope="col" className="p-4 text-right">
              Actions
            </th>
          </>
        }
        tBody={
          patients.length > 0 ? (
            patients.map((patient) => (
              <ShowTableStandardRow key={patient.id}>
                <td className="max-w-0 p-4 capitalize">
                  <RcLink to={`/customers/patients/${patient.slug}`}>
                    {patient.firstName} {patient.lastName}
                  </RcLink>
                </td>
                <td className="hidden p-4 sm:table-cell">
                  {dobFormatter(patient.dob)}
                </td>
                <td className="hidden p-4 capitalize sm:table-cell">
                  {patient.arches}
                </td>
                <td className="hidden p-4 capitalize sm:table-cell">
                  <Badge
                    withDot={false}
                    name={patient.includeEot ? 'Yes' : 'No'}
                    color={patient.includeEot ? 'green' : 'yellow'}
                  />
                </td>
                <td className="hidden p-4 capitalize sm:table-cell">
                  <Badge
                    withDot={false}
                    name={patient.sendToClinic ? 'Yes' : 'No'}
                    color={patient.sendToClinic ? 'green' : 'yellow'}
                  />
                </td>
                <td className="hidden p-4 capitalize sm:table-cell">
                  <Badge
                    withDot={false}
                    name={patient.scanReady ? 'Yes' : 'No'}
                    color={patient.scanReady ? 'green' : 'yellow'}
                  />
                </td>

                <td className="flex justify-end gap-x-4 p-4 text-right">
                  <Button
                    to={`/customers/patients/${patient.slug}`}
                    show
                    size="xs"
                  />
                  <Button
                    to={`/customers/patients/${patient.slug}/edit`}
                    edit
                    size="xs"
                  />
                </td>
              </ShowTableStandardRow>
            ))
          ) : (
            <ShowTableStandardRow>
              <td className="p-4 text-center" colSpan="7">
                No patients found
              </td>
            </ShowTableStandardRow>
          )
        }
      />
    </Card>
  );
}

ShowPatients.propTypes = {
  patients: PropTypes.array,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default ShowPatients;
