import { gql } from '@apollo/client';

const PartnerLocationInfo = gql`
  fragment PartnerLocationInfo on PartnerLocation {
    id
    name
    email
    phone
    website
    active
    slug
    imageUrl
    insertImageUrl
    enablePaidInOffice
    hiresLogoUrl
    perArchEotFee
    priceBookId
    priceBook {
      id
      name
      slug
    }
    scannerBrandId
    scannerBrand {
      id
      name
    }
    address {
      id
      address
      address2
      city
      region
      postalCode
      country
      latitude
      longitude
    }
    partnerOrganizationId
    partnerOrganization {
      id
      name
      slug
    }
    createdAt
    updatedAt
  }
`;

export default PartnerLocationInfo;
