import { gql } from '@apollo/client';
import { UserInfoShared } from '../fragments';

export default gql`
  query user($slug: String, $id: ID) {
    user(slug: $slug, id: $id) {
      ...UserInfoShared
    }
  }
  ${UserInfoShared}
`;
