import { gql } from '@apollo/client';
import { OrderInfoPublic } from '../fragments';

export default gql`
  query orders {
    orders {
      ...OrderInfoPublic
    }
  }
  ${OrderInfoPublic}
`;
