import { gql } from '@apollo/client';

export default gql`
  query regions($country: String!) {
    regions(country: $country) {
      id
      value
      name
    }
  }
`;
