import { gql } from '@apollo/client';
import { ManufacturerInfo } from '../fragments';

export default gql`
  query manufacturers {
    manufacturers {
      ...ManufacturerInfo
    }
  }
  ${ManufacturerInfo}
`;
