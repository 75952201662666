import { gql } from '@apollo/client';

const AdminLocationInfo = gql`
  fragment AdminLocationInfo on AdminLocation {
    id
    name
    slug
    active
    website
    imageUrl
    address {
      id
      address
      address2
      city
      region
      postalCode
      country
      latitude
      longitude
    }
    createdAt
    updatedAt
  }
`;

export default AdminLocationInfo;
