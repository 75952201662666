import { NavLink } from 'react-router-dom';

function MobileNavAuthed() {
  return (
    <>
      <NavLink
        to="/"
        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
      >
        Home
      </NavLink>
      <a
        href="https://locator.retainerclub.com"
        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
      >
        Get Started
      </a>
      <a
        href="https://retainerclub.com/why-replace-retainers"
        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
      >
        Why Replace Retainers?
      </a>
      <a
        href="https://retainerclub.com/support"
        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
      >
        Support
      </a>
    </>
  );
}

export default MobileNavAuthed;
