import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { CartInfoPublic } from '../fragments';

export default gql`
  mutation createCart($input: CartCreateInput!) {
    createCart(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      cart {
        ...CartInfoPublic
      }
    }
  }
  ${CartInfoPublic}
  ${ErrorMessageInfo}
`;
