import { useQuery } from '@apollo/client';
import { GET_COUNTRIES } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldContainer } from '../FieldContainer';

function CountrySelect({
  name,
  style = {},
  className = '',
  required = false,
  hideLabel = false,
  autoComplete = 'country',
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation('forms');

  const { error, loading, data } = useQuery(GET_COUNTRIES);

  return (
    <FieldContainer
      style={style}
      className={className}
      required={required}
      hideLabel={hideLabel}
      name={name}
      label="Country"
    >
      {error || loading ? (
        <div>loading</div>
      ) : (
        <select
          {...register(name)}
          name={name}
          autoComplete={autoComplete}
          className={`block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${
            errors && errors[name]
              ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
              : 'focus:ring-rcprimary-400 ring-gray-300 placeholder:text-gray-400'
          }`}
          disabled={data?.countries === undefined || data?.countries === null}
        >
          <option disabled>{`${t('Select a Country')}...`}</option>
          <option value="US">{t('United States')}</option>
          <option value="CA">{t('Canada')}</option>
          {data?.countries.map((country) => (
            <option key={country.id} value={country.value}>
              {country.name}
            </option>
          ))}
        </select>
      )}
    </FieldContainer>
  );
}

CountrySelect.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  autoComplete: PropTypes.string,
};

export default CountrySelect;
