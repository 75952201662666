import { gql } from '@apollo/client';
import { ProductCategoryInfo } from '../fragments';

export default gql`
  query productCategory($slug: String, $id: ID) {
    productCategory(slug: $slug, id: $id) {
      ...ProductCategoryInfo
    }
  }
  ${ProductCategoryInfo}
`;
