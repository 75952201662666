import { useFormValues } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { arrayRange, getNested } from '../../../transforms';
import { FieldContainer } from '../FieldContainer';

function DateSelect({
  name,
  label = null,
  style = {},
  className = '',
  required = false,
  hideLabel = false,
  isFutureDate = false,
}) {
  const { t } = useTranslation('forms');

  const {
    formState: { errors },
    setValue,
    trigger,
  } = useFormContext();

  const values = useFormValues();
  const value = getNested(values, name);

  const [chosenDate, setChosenDate] = useState({
    day: null,
    month: null,
    year: null,
  });

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setChosenDate({
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      });
    }
  }, [value]);

  useEffect(() => {
    if (chosenDate?.day && chosenDate?.month && chosenDate?.year) {
      const newDate = new Date(
        +chosenDate.year,
        chosenDate.month - 1,
        chosenDate.day,
      );
      setValue(name, newDate.toString());
    }
  }, [chosenDate.day, chosenDate.month, chosenDate.year, name, setValue]);

  useEffect(() => {
    if (value) {
      trigger(name);
    }
  }, [name, trigger, value]);

  const selectClasses = `block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${
    errors && errors[name]
      ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
      : 'ring-gray-300 placeholder:text-gray-400 focus:ring-rcprimary-400'
  }`;

  const months = arrayRange(1, 12);
  const days = arrayRange(1, 31);
  const years = isFutureDate
    ? arrayRange(new Date().getFullYear(), new Date().getFullYear() + 10)
    : arrayRange(new Date().getFullYear() - 70, new Date().getFullYear());

  function handleMonthChange(e) {
    setChosenDate({ ...chosenDate, month: e.target.value });
  }

  function handleDayChange(e) {
    setChosenDate({ ...chosenDate, day: e.target.value });
  }

  function handleYearChange(e) {
    setChosenDate({ ...chosenDate, year: e.target.value });
  }

  return (
    <FieldContainer
      style={style}
      className={className}
      required={required}
      hideLabel={hideLabel}
      name={name}
      label={label}
    >
      <select
        name="month"
        onChange={handleMonthChange}
        className={selectClasses}
        value={chosenDate?.month || `${t('Month')}`}
      >
        <option disabled>{t('Month')}</option>
        {months.map((m) => (
          <option key={m} value={m}>
            {m}
          </option>
        ))}
      </select>
      <span className="mx-1" />
      <select
        name="day"
        onChange={handleDayChange}
        className={selectClasses}
        value={chosenDate?.day || `${t('Day')}`}
      >
        <option disabled>{t('Day')}</option>
        {days.map((d) => (
          <option key={d} value={d}>
            {d}
          </option>
        ))}
      </select>
      <span className="mx-1" />
      <select
        name="year"
        onChange={handleYearChange}
        className={selectClasses}
        value={chosenDate?.year || `${t('Year')}`}
      >
        <option disabled>{t('Year')}</option>
        {years.map((y) => (
          <option key={y} value={y}>
            {y}
          </option>
        ))}
      </select>
    </FieldContainer>
  );
}

DateSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  isFutureDate: PropTypes.bool,
};

export default DateSelect;
