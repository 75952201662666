import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { StoreInfo } from '../fragments';

export default gql`
  mutation updateStore($input: StoreUpdateInput!) {
    updateStore(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      store {
        ...StoreInfo
      }
    }
  }
  ${StoreInfo}
  ${ErrorMessageInfo}
`;
