import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { PriceBookInfo } from '../fragments';

export default gql`
  mutation createPriceBook($input: PriceBookCreateInput!) {
    createPriceBook(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      priceBook {
        ...PriceBookInfo
      }
    }
  }
  ${PriceBookInfo}
  ${ErrorMessageInfo}
`;
