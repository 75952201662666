import { gql } from '@apollo/client';

export default gql`
  query dashboard(
    $partnerOrganizationId: ID
    $partnerLocationId: ID
    $metric: String!
    $startDate: String
    $endDate: String
    $range: String
  ) {
    dashboard(
      partnerOrganizationId: $partnerOrganizationId
      partnerLocationId: $partnerLocationId
      metric: $metric
      startDate: $startDate
      endDate: $endDate
      range: $range
    )
  }
`;
