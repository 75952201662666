import { gql } from '@apollo/client';

const AdminTagInfo = gql`
  fragment AdminTagInfo on AdminTag {
    id
    name
    description
    symbol
    slug
    createdAt
    updatedAt
  }
`;

export default AdminTagInfo;
