import { gql } from '@apollo/client';
import { CartInfoPublic } from '../fragments';

export default gql`
  query cart($id: ID!) {
    cart(id: $id) {
      ...CartInfoPublic
    }
  }
  ${CartInfoPublic}
`;
