import PropTypes from 'prop-types';

function TooltipText({ tooltip }) {
  return <span className="tooltiptext">{tooltip}</span>;
}

TooltipText.propTypes = {
  tooltip: PropTypes.string.isRequired,
};

export default TooltipText;
