import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';

export default gql`
  mutation updatePassword($input: PasswordUpdateInput!) {
    updatePassword(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
    }
  }
  ${ErrorMessageInfo}
`;
