import { gql } from '@apollo/client';
import { OrderInfoShared } from '../fragments';

export default gql`
  query order($id: ID, $slug: String) {
    order(id: $id, slug: $slug) {
      ...OrderInfoShared
    }
  }
  ${OrderInfoShared}
`;
