import { gql } from '@apollo/client';
import { ProductRenewalIntervalInfo } from '../fragments';

export default gql`
  mutation destroyProductRenewalInterval(
    $input: ProductRenewalIntervalDestroyInput!
  ) {
    destroyProductRenewalInterval(input: $input) {
      clientMutationId
      productRenewalInterval {
        ...ProductRenewalIntervalInfo
      }
    }
  }
  ${ProductRenewalIntervalInfo}
`;
