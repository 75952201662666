import { gql } from '@apollo/client';
import { CartInfo } from '../fragments';

export default gql`
  query carts {
    carts {
      ...CartInfo
    }
  }
  ${CartInfo}
`;
