import { LoadingOrError } from '@retainerclub/common';
import PropTypes from 'prop-types';
import SidebarCard from './SidebarCard';

function SidebarCards({
  results,
  listItemClick,
  loading,
  error = null,
  networkStatus = null,
}) {
  if (loading || error || networkStatus < 7)
    <LoadingOrError
      error={error}
      loading={loading || networkStatus < 7}
      title="results..."
      style={{ backgroundColor: 'transparent' }}
    />;

  return (
    <ul className="divide-y divide-black/10">
      {results.map((result, index) => (
        <SidebarCard
          key={result.id}
          index={index}
          result={result.attributes}
          distance={result.distance}
          listItemClick={listItemClick}
        />
      ))}
    </ul>
  );
}

SidebarCards.propTypes = {
  results: PropTypes.array.isRequired,
  listItemClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  networkStatus: PropTypes.number,
};

export default SidebarCards;
