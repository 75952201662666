import { gql } from '@apollo/client';
import { AdminLocationInfo } from '../fragments';

export default gql`
  query adminLocations {
    adminLocations {
      ...AdminLocationInfo
    }
  }
  ${AdminLocationInfo}
`;
