/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';

function EotCheckbox({ setCheckboxChange, checkboxChange }) {
  return (
    <div className="relative flex gap-x-3">
      <div className="flex h-6 items-center">
        <input
          type="checkbox"
          checked={checkboxChange}
          onChange={() => setCheckboxChange(!checkboxChange)}
          className="text-rcprimary-400 focus:ring-rcprimary-400 disabled:bg-rcgray-600 disabled:ring-rcgray-700 disabled:text-rcgray-600 border-rcgray-600 size-5 rounded border-gray-300 disabled:cursor-not-allowed"
        />
      </div>
      <div className="text-sm leading-6">
        <label htmlFor="comments" className="text-md font-medium text-black/90">
          Are these End of Treatment Retainer(s)
          <span className="text-red-400">*</span>
        </label>
        <p className="text-xs text-black/60">
          Select to choose these as the initial end of treatment retainer(s) for
          the patient.
        </p>
      </div>
    </div>
  );
}

EotCheckbox.propTypes = {
  setCheckboxChange: PropTypes.func.isRequired,
  checkboxChange: PropTypes.bool.isRequired,
};

export default EotCheckbox;
