import { gql } from '@apollo/client';

const AdminUserInfo = gql`
  fragment AdminUserInfo on AdminUser {
    id
    firstName
    lastName
    email
    phone
    language
    state
    adminLocationId
    adminLocation {
      id
      name
      slug
    }
    roleTypeId
    roleType {
      id
      name
    }
    imageUrl
    slug
    adminTagIds
    lastLoginAt
    createdAt
    updatedAt
  }
`;

export default AdminUserInfo;
