import { gql } from '@apollo/client';

export default gql`
  query productsLabNote(
    $partnerLocationId: ID!
    $isPlan: Boolean
    $isRetainer: Boolean
    $isEot: Boolean
  ) {
    productsLabNote(
      partnerLocationId: $partnerLocationId
      isPlan: $isPlan
      isRetainer: $isRetainer
      isEot: $isEot
    ) {
      id
      name
      arches
      isEot
    }
  }
`;
