import { gql } from '@apollo/client';
import { ReferralTypeInfoShared } from '@retainerclub/shared-api';

export default gql`
  query referralType($slug: String, $id: ID) {
    referralType(slug: $slug, id: $id) {
      ...ReferralTypeInfoShared
    }
  }
  ${ReferralTypeInfoShared}
`;
