import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';

function SettingsItem(props) {
  const { item, name } = props;

  return (
    <li className="flex items-center gap-x-2">
      {item ? (
        <CheckIcon
          className="text-rcprimary-600 my-0 h-5 w-5 flex-none"
          aria-hidden="true"
        />
      ) : (
        <XMarkIcon
          className="my-0 h-5 w-5 flex-none text-red-600"
          aria-hidden="true"
        />
      )}
      {name}
    </li>
  );
}

SettingsItem.propTypes = {
  name: PropTypes.string.isRequired,
  item: PropTypes.bool.isRequired,
};

export default SettingsItem;
