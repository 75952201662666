import { gql } from '@apollo/client';
import { ReferralInfoShared } from '../fragments';

export default gql`
  query referral($slug: String, $id: ID) {
    referral(slug: $slug, id: $id) {
      ...ReferralInfoShared
    }
  }
  ${ReferralInfoShared}
`;
