import PropTypes from 'prop-types';
import { Card, ShowContact } from '../../../shared';
import ReferralShowDetails from './ReferralShowDetails';

function ShowBodyReferral({ referral }) {
  return (
    <div className="flex flex-col gap-2 md:flex-row">
      <div className="flex basis-1 flex-col md:basis-full lg:basis-2/3">
        <Card
          className="h-full p-4"
          heading="Details"
          wrapperClassName="h-full"
        >
          <div className="mt-4" />
          <ReferralShowDetails referral={referral} />
        </Card>
      </div>
      <div className="flex basis-1 flex-col md:basis-full lg:basis-1/3">
        <ShowContact
          email={referral?.userAttributes?.email}
          phone={referral?.userAttributes?.phone}
          user={referral?.userAttributes}
          account={referral?.account}
        />
      </div>
    </div>
  );
}

ShowBodyReferral.propTypes = {
  referral: PropTypes.object.isRequired,
};

export default ShowBodyReferral;
