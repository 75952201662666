/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormErrors from '../FieldContainer/FormErrors';
import Input from './Input';
import { days, months } from './helpers/dateElements';

function DateInput({
  dayName = 'birthDay',
  monthName = 'birthMonth',
  yearName = 'birthYear',
  label = 'Date of Birth',
  style = {},
  className = '',
  wrapperClass = '',
  required = false,
  disabled = false,
}) {
  const { t } = useTranslation('forms');
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={wrapperClass}>
      <label className="block text-sm font-medium capitalize leading-6 text-black/70">
        {t(label)}
        {required && <span className="text-red-400">*</span>}
      </label>
      <div className="grid grid-cols-6 gap-x-4 ">
        <div className="col-span-2">
          <select
            {...register(monthName)}
            name={monthName}
            className={`mt-2 block w-full rounded-md border-0 py-2.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-4 ${
              errors && errors[monthName]
                ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                : 'focus:ring-rcprimary-400 ring-gray-300 placeholder:text-gray-400'
            } ${className}`}
          >
            <option disabled>{t('Month')}</option>
            {months.map((m) => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </select>
          <FormErrors name={monthName} errors={errors} />
        </div>
        <div className="col-span-2">
          <select
            {...register(dayName)}
            name={dayName}
            className={`mt-2 block w-full rounded-md border-0 py-2.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-4 ${
              errors && errors[dayName]
                ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                : 'focus:ring-rcprimary-400 ring-gray-300 placeholder:text-gray-400'
            } ${className}`}
          >
            <option disabled>{t('Day')}</option>
            {days.map((d) => (
              <option key={d} value={d}>
                {d}
              </option>
            ))}
          </select>
          <FormErrors name={dayName} errors={errors} />
        </div>
        <Input
          className={`col-span-2 ${className}`}
          required={required}
          placeholder="YYYY"
          name={yearName}
          disabled={disabled}
          style={style}
          hideLabel
          attributes={{ minLength: 4, maxLength: 4, inputMode: 'numeric' }}
        />
      </div>
    </div>
  );
}

DateInput.propTypes = {
  dayName: PropTypes.string,
  monthName: PropTypes.string,
  yearName: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

export default DateInput;
