import { gql } from '@apollo/client';

const StoreInfo = gql`
  fragment StoreInfo on Store {
    id
    name
    description
    slug
    createdAt
    updatedAt
  }
`;

export default StoreInfo;
