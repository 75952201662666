import { gql } from '@apollo/client';

const ScannerBrandInfo = gql`
  fragment ScannerBrandInfo on ScannerBrand {
    id
    name
    description
    slug
    createdAt
    updatedAt
  }
`;

export default ScannerBrandInfo;
