import { gql } from '@apollo/client';

export default gql`
  query accountNotes($patientId: ID, $accountId: ID) {
    accountNotes(patientId: $patientId, accountId: $accountId) {
      id
      message
      accountId
      patientId
      accountNoteableType
      accountNoteable {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;
