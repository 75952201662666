import { gql } from '@apollo/client';

const OrderInfoPublic = gql`
  fragment OrderInfoPublic on Order {
    id
    subtotal
    state
    discountAmount
    taxAmount
    shippingAmount
    total
    wholesalePrice
    currency
    hexCode
    shippingAddress
    billingAddress
    createdAt
    productionOrders {
      id
      productionOrderItems {
        id
        trackingNumber
        shippingCarrier {
          id
          name
        }
      }
    }
    orderItems {
      id
      name
      price
      discountAmount
      taxAmount
      shippingAmount
      total
      quantity
      patientId
      patient {
        id
        firstName
        lastName
      }
      productId
      product {
        id
        featuredImage
        description
        productFeatures {
          id
          text
        }
      }
    }
  }
`;

export default OrderInfoPublic;
