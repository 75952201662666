import { gql } from '@apollo/client';
import { SubscriptionInfoShared } from '../fragments';

export default gql`
  query subscription($slug: String, $id: ID) {
    subscription(slug: $slug, id: $id) {
      ...SubscriptionInfoShared
    }
  }
  ${SubscriptionInfoShared}
`;
