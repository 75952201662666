import PropTypes from 'prop-types';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useLocation } from 'react-router-dom';
import Card from '../../Card/Card';
import RcDl from '../../RcDl/RcDl';
import RcLink from '../../RcLink/RcLink';
import ShowDetailsSection from '../ShowDetails/ShowDetailsSection';

const NOT_AVAILABLE = 'Not Available';

function ShowContact({
  email = null,
  phone = null,
  website = null,
  user = null,
  account = null,
  className = '',
}) {
  const { pathname } = useLocation();
  const notUserPath =
    !pathname.includes('/users/') && !pathname.includes('/admins/');
  const referralPath = pathname.includes('/referrals/');

  return (
    <Card
      className={`h-full p-4 md:mt-0 ${className}`}
      wrapperClassName="h-full"
      heading={`${referralPath ? 'Customer' : ''} Contact`}
    >
      <RcDl>
        {referralPath && account && (
          <ShowDetailsSection title="Account #" className="sm:col-span-1">
            <RcLink
              to={`/customers/accounts/${account?.accountNumber}`}
              className="text-sm"
            >
              {account?.accountNumber}
            </RcLink>
          </ShowDetailsSection>
        )}
        {referralPath && user && (
          <ShowDetailsSection title="Name" className="sm:col-span-1">
            <RcLink to={`/customers/users/${user?.slug}`} className="text-sm">
              {user?.firstName} {user?.lastName}
            </RcLink>
          </ShowDetailsSection>
        )}
        <ShowDetailsSection title="Email" className="sm:col-span-1">
          {email ? (
            <RcLink
              href={`mailto: ${email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {email}
            </RcLink>
          ) : (
            NOT_AVAILABLE
          )}
        </ShowDetailsSection>
        <ShowDetailsSection title="Phone" className="sm:col-span-1">
          {phone ? (
            <RcLink
              href={`tel: ${phone}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatPhoneNumber(phone)}
            </RcLink>
          ) : (
            NOT_AVAILABLE
          )}
        </ShowDetailsSection>

        {notUserPath && !referralPath && (
          <ShowDetailsSection title="Website" className="sm:col-span-1">
            {website ? (
              <RcLink
                href={`https://${website}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {website}
              </RcLink>
            ) : (
              NOT_AVAILABLE
            )}
          </ShowDetailsSection>
        )}
      </RcDl>
    </Card>
  );
}

ShowContact.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
  className: PropTypes.string,
  user: PropTypes.object,
  account: PropTypes.object,
};

export default ShowContact;
