import { gql } from '@apollo/client';
import { PartnerLocationInfoPartner } from '../fragments';

export default gql`
  query partnerLocations {
    partnerLocations {
      ...PartnerLocationInfoPartner
    }
  }
  ${PartnerLocationInfoPartner}
`;
