import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const LocaleContext = createContext();

export function LocaleProvider(props) {
  const { children } = props;

  const { i18n } = useTranslation();

  const [locale, setLocale] = useState(
    window.localStorage.getItem('defaultLanguage') || 'en',
  );

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [i18n, locale]);

  const value = useMemo(() => ({ locale, setLocale }), [locale]);

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
}

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useLocale() {
  return useContext(LocaleContext);
}
