import { gql } from '@apollo/client';
import { ErrorMessageInfo, ReferralInfoShared } from '../fragments';

export default gql`
  mutation actionReferralResend($input: ReferralResendActionInput!) {
    actionReferralResend(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      referral {
        ...ReferralInfoShared
      }
    }
  }
  ${ReferralInfoShared}
  ${ErrorMessageInfo}
`;
