import PropTypes from 'prop-types';

function Badge({
  name,
  withDot = true,
  size = 'large',
  color = 'gray',
  className = '',
  fullWidth = false,
}) {
  function getColor() {
    switch (color) {
      case 'indigo':
        return 'bg-indigo-100 text-indigo-700';
      case 'red':
        return 'bg-red-100 text-red-700';
      case 'gray':
        return 'bg-gray-100 text-gray-600';
      case 'yellow':
        return 'bg-yellow-100 text-yellow-800';
      case 'green':
        return 'bg-green-100 text-green-700';
      case 'blue':
        return 'bg-blue-100 text-blue-700';
      case 'pink':
        return 'bg-pink-100 text-pink-700';
      case 'purple':
        return 'bg-purple-100 text-purple-700';

      default:
        return 'bg-gray-100 text-gray-600';
    }
  }

  function getDotColor() {
    switch (color) {
      case 'indigo':
        return 'text-indigo-400';
      case 'red':
        return 'text-red-400';
      case 'gray':
        return 'text-gray-400';
      case 'yellow':
        return 'text-black';
      case 'green':
        return 'text-green-400';
      case 'blue':
        return 'text-blue-400';
      case 'pink':
        return 'text-pink-400';
      case 'purple':
        return 'text-purple-400';

      default:
        return 'text-gray-400';
    }
  }

  return (
    <span
      className={`inline-flex ${
        fullWidth ? 'w-full justify-center' : ''
      } items-center rounded-full py-1 font-medium capitalize ${
        size === 'large' ? 'px-3 text-sm' : 'px-2 text-xs'
      } ${getColor()} ${className}`}
    >
      {withDot && (
        <svg
          className={`-ml-1 mr-1.5 h-2 w-2 ${getDotColor()}}`}
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          <circle cx={4} cy={4} r={3} />
        </svg>
      )}

      {name}
    </span>
  );
}

Badge.propTypes = {
  name: PropTypes.string.isRequired,
  withDot: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  color: PropTypes.oneOf([
    'indigo',
    'red',
    'green',
    'yellow',
    'blue',
    'purple',
    'pink',
    'gray',
  ]),
};

export default Badge;
