import { useContext } from 'react';
import { MapViewContext } from '../../../shared/Context';
import RcMarker from './RcMarker';

function RcMarkers() {
  const { results, markersRef } = useContext(MapViewContext);

  return results.map((result, index) => (
    <RcMarker
      key={result.id}
      partner={result.attributes}
      distance={result.distance}
      index={index}
      markersRef={markersRef}
    />
  ));
}

export default RcMarkers;
