import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import Select, { components } from 'react-select';

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className="size-5 shrink-0 self-center" />
    </components.DropdownIndicator>
  );
}

function ClearIndicator(props) {
  return (
    <components.ClearIndicator {...props}>
      <XMarkIcon className="size-5 shrink-0 self-center" />
    </components.ClearIndicator>
  );
}

function MultiValueRemove(props) {
  return (
    <components.MultiValueRemove {...props}>
      <XMarkIcon className="size-3 shrink-0 self-center" />
    </components.MultiValueRemove>
  );
}

const controlStyles = {
  base: 'border rounded-lg bg-white hover:cursor-pointer',
  focus: 'border-rcprimary-400 ring-1 ring-rcprimary-400',
  nonFocus: 'border-gray-300 hover:border-gray-400',
};
const placeholderStyles = 'text-gray-500 pl-1 py-0.5';
const selectInputStyles = 'pl-1 py-0.5';
const valueContainerStyles = 'p-1 gap-1';
const singleValueStyles = 'leading-7 ml-1';
const multiValueStyles =
  'bg-rcprimary-400 rounded items-center py-0.5 pl-2 pr-1 gap-1.5';
const multiValueLabelStyles = 'leading-6 py-0.5';
const multiValueRemoveStyles =
  'border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md';
const indicatorsContainerStyles = 'p-1 gap-1';
const clearIndicatorStyles =
  'text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800';
const indicatorSeparatorStyles = 'bg-gray-300';
const dropdownIndicatorStyles =
  'p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black';
const menuStyles = 'p-1 mt-2 border border-gray-200 bg-white rounded-lg';
const groupHeadingStyles = 'ml-3 mt-2 mb-1 text-gray-500 text-sm';
const optionStyles = {
  base: 'hover:cursor-pointer px-3 py-2 rounded',
  focus: 'bg-rcprimary-400 active:bg-gray-200',
  selected:
    "after:content-['✔'] after:ml-2 after:text-rcprimary-400 text-gray-500",
};
const noOptionsMessageStyles =
  'text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm';

function RcSelect({
  name,
  data,
  disabled = false,
  searchable = false,
  clearable = true,
  loading = false,
}) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <Select
          inputRef={ref}
          isDisabled={disabled}
          classNamePrefix="addl-class"
          isClearable={clearable}
          isSearchable={searchable}
          isLoading={loading}
          onBlur={onBlur}
          options={data}
          value={data.find((c) => value === c?.id)}
          onChange={(val) => onChange(val?.id || null)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          closeMenuOnSelect
          hideSelectedOptions={false}
          unstyled
          components={{
            DropdownIndicator,
            ClearIndicator,
            MultiValueRemove,
          }}
          styles={{
            input: (base) => ({
              ...base,
              'input:focus': {
                boxShadow: 'none',
              },
            }),
            multiValueLabel: (base) => ({
              ...base,
              whiteSpace: 'normal',
              overflow: 'visible',
            }),
            control: (base) => ({
              ...base,
              transition: 'none',
            }),
            container: (base) => ({
              ...base,
              width: '100%',
            }),
          }}
          classNames={{
            control: ({ isFocused }) =>
              `${isFocused ? controlStyles.focus : controlStyles.nonFocus}
                     ${controlStyles.base}`,
            placeholder: () => placeholderStyles,
            input: () => selectInputStyles,
            valueContainer: () => valueContainerStyles,
            singleValue: () => singleValueStyles,
            multiValue: () => multiValueStyles,
            multiValueLabel: () => multiValueLabelStyles,
            multiValueRemove: () => multiValueRemoveStyles,
            indicatorsContainer: () => indicatorsContainerStyles,
            clearIndicator: () => clearIndicatorStyles,
            indicatorSeparator: () => indicatorSeparatorStyles,
            dropdownIndicator: () => dropdownIndicatorStyles,
            menu: () => menuStyles,
            groupHeading: () => groupHeadingStyles,
            option: ({ isFocused, isSelected }) =>
              `${isFocused && optionStyles.focus} ${
                isSelected && optionStyles.selected
              } ${optionStyles.base}`,
            noOptionsMessage: () => noOptionsMessageStyles,
          }}
        />
      )}
    />
  );
}

RcSelect.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  loading: PropTypes.bool,
};

export default RcSelect;
