import { gql } from '@apollo/client';
import { ProductionOrderInfo } from '../fragments';

export default gql`
  query productionOrder($id: ID, $slug: String) {
    productionOrder(id: $id, slug: $slug) {
      ...ProductionOrderInfo
    }
  }
  ${ProductionOrderInfo}
`;
