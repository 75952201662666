import { gql } from '@apollo/client';
import { ErrorMessageInfo, ReferralInfoShared } from '../fragments';

export default gql`
  mutation createReferral($input: ReferralCreateInput!) {
    createReferral(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      referral {
        ...ReferralInfoShared
      }
    }
  }
  ${ReferralInfoShared}
  ${ErrorMessageInfo}
`;
