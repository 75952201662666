import { Popover } from '@headlessui/react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function NavColumn(props) {
  const { title, links } = props;

  return (
    <div>
      <h3 className="text-rcprimary-300 font-oswald text-md leading-6">
        {title}
      </h3>
      <div className="mt-6 flow-root">
        <div className="-my-2">
          {links.map((item) => (
            <Popover.Button
              as={NavLink}
              key={item.name}
              to={item.to}
              className="hover:text-rcprimary-400 group flex gap-x-4 py-2 text-sm font-semibold leading-6 text-white"
            >
              <item.icon
                className="group-hover:text-rcprimary-400 h-6 w-6 flex-none text-white/60"
                aria-hidden="true"
              />
              {item.name}
            </Popover.Button>
          ))}
        </div>
      </div>
    </div>
  );
}

NavColumn.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
};

export default NavColumn;
