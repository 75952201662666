import { gql } from '@apollo/client';

const ProductInfoPublic = gql`
  fragment ProductInfoPublic on Product {
    id
    name
    renewalPrice
    description
    sku
    slug
    defaultSubscriptionRetaner
    productCategories {
      id
      name
      slug
    }
    featuredImage
    productRenewalInterval {
      id
      name
    }
    productFeatures {
      id
      text
    }
    productImages {
      id
      imageUrl
    }
    productType
    planSubscription {
      id
      name
    }
    planProducts {
      id
      overridePrice
      price
    }
    createdAt
    updatedAt
  }
`;

export default ProductInfoPublic;
