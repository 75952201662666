/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { MapViewContext } from '../../../shared/Context';
import MarkerSvg from './MarkerSvg';

function RcMarker({ partner, distance = 0, markersRef, index }) {
  const { setCurrentPartner, setRequestScanOpen } = useContext(MapViewContext);

  const phoneNumber = useMemo(
    () => parsePhoneNumber(partner.phone || '', 'US'),
    [partner.phone],
  );

  function handleRequestScan() {
    setCurrentPartner(partner);
    setRequestScanOpen(true);
  }

  return (
    <Marker
      position={[partner.coordinates.lat, partner.coordinates.lon]}
      key={partner.id}
      riseOnHover
      icon={MarkerSvg}
      ref={(marker) => {
        markersRef.current[index] = marker;
      }}
    >
      <Popup>
        <div className="flex w-[350px] items-center justify-between gap-x-2">
          {partner.logoUrl && (
            <div
              className="flex h-[112px] w-[167px] min-w-0 gap-x-4 rounded-l-lg bg-white bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: `url(${partner.logoUrl})`,
              }}
            />
          )}

          <div
            className={`mr-2 min-w-0 flex-auto ${partner.logoUrl ? '' : 'p-4'}`}
          >
            <h2 className="leading-2 text-sm font-semibold text-white">
              {partner.name}
            </h2>
            <div className="flex flex-row items-center justify-between">
              <div>
                <span className="text-white/70">
                  {partner.city ? `${partner.city},` : null}{' '}
                  {partner.region ? partner.region : null}{' '}
                  {partner.postalCode ? partner.postalCode : null}
                </span>
                {partner.phone && (
                  <>
                    <br />
                    <span className="mt-0 text-xs leading-4 text-white/70">
                      <a
                        href={`tel:${partner.phone}`}
                        style={{
                          textDecoration: 'underline',
                          color: 'rgba(255, 255, 255, 0.8)',
                        }}
                      >
                        {phoneNumber
                          ? formatPhoneNumber(phoneNumber.number)
                          : partner.phone}
                      </a>
                    </span>
                  </>
                )}
                {partner.website && (
                  <>
                    <br />
                    <span className="mt-0 text-xs leading-4 text-white/70">
                      <a
                        href={`https://${partner.website}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-rcprimary-400"
                        style={{
                          textDecoration: 'underline',
                          color: 'rgba(255, 255, 255, 0.8)',
                        }}
                      >
                        {partner.website}
                      </a>
                    </span>
                  </>
                )}
              </div>

              {distance > 0 && (
                <span className="text-white/60">{`${distance.toFixed(
                  2,
                )}mi`}</span>
              )}
            </div>

            <span className="mt-2 flex flex-row items-center justify-between">
              <button
                type="button"
                className="bg-rcgray-200 hover:bg-rcprimary-300 ring-rcprimary-400 px-2.5 py-1 text-xs font-semibold text-black shadow-sm ring-1 ring-inset"
                onClick={() => handleRequestScan()}
              >
                Contact Partner
              </button>
            </span>
          </div>
        </div>
      </Popup>
    </Marker>
  );
}

RcMarker.propTypes = {
  partner: PropTypes.object.isRequired,
  distance: PropTypes.number,
  markersRef: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default RcMarker;
