import { gql } from '@apollo/client';

export default gql`
  query scannerBrands {
    scannerBrands {
      id
      name
      description
      slug
      createdAt
      updatedAt
    }
  }
`;
