/* eslint-disable no-console */
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';

function signout() {
  signOut()
    .then(() => {
      // client.resetStore();
      window.localStorage.removeItem('apollo-cache-persist');
    })
    .catch((err) => {
      console.error(err);
    });
}

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_API_SERVER,
  credentials: 'same-origin',
});

const authLink = setContext(async () => {
  // if you have a cached value, return it immediately
  try {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};

    return {
      headers: {
        authorization: idToken ? `Bearer ${idToken}` : '',
        'x-app-name': import.meta.env.VITE_APP_NAME,
      },
    };
  } catch (e) {
    return {
      headers: {
        'x-app-name': import.meta.env.VITE_APP_NAME,
      },
    };
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log(graphQLErrors, 'onError - graphqlerrors - apolloClient');
  console.log(networkError, 'onError - networkerror - apolloClient');
  if (graphQLErrors)
    graphQLErrors.forEach(({ status = 200 }) => {
      console.log(status, 'onError - graphqlerrors - status');
      if (status === 401) {
        console.warn(
          `You've attempted to access ${
            import.meta.env.VITE_UNAUTHORIZED
          } section`,
        );
      }
      if (status === 403) {
        console.warn(
          `You've attempted a ${import.meta.env.VITE_FORBIDDEN} action`,
        );
      }
    });
  if (networkError && networkError.statusCode === 401) {
    console.warn(import.meta.env.VITE_UNAUTHORIZED);
    signout();
  }
  if (networkError && networkError.statusCode === 403) {
    console.warn(import.meta.env.VITE_FORBIDDEN);
  }
});

const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {},
  },
});

const link = ApolloLink.from([errorLink, authLink, httpLink]);

const apolloClient = new ApolloClient({
  link,
  cache,
  resolvers: {},
  connectToDevTools: true,
});

export default apolloClient;
