import { gql } from '@apollo/client';
import { ErrorMessageInfo, PatientInfoShared } from '../fragments';

export default gql`
  mutation updatePatient($input: PatientUpdateInput!) {
    updatePatient(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      patient {
        ...PatientInfoShared
      }
    }
  }
  ${PatientInfoShared}
  ${ErrorMessageInfo}
`;
