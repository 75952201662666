import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useApp } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

function NavigationDesktopDropdown(props) {
  const { menuItems, title } = props;

  const { t } = useTranslation('navigation');
  const { isCustomer } = useApp();

  return (
    <Popover className="relative">
      <Popover.Button className="hover:text-rcprimary-200 hover:bg-rcgray-200/20 hover:text-rcprimary-400 hover:outline-rcprimary-400 hover:bg-rcprimary-400/0 inline-flex items-center gap-x-1 rounded-md p-2 text-sm font-medium text-black outline outline-2 outline-offset-0 outline-white/0">
        <span>{t(title)}</span>
        <ChevronDownIcon className="size-5" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-2 flex w-screen max-w-max -translate-x-1/4 px-4">
          <div
            className={`border-rcprimary-100/30 w-screen max-w-md flex-auto overflow-hidden rounded-3xl border ${isCustomer ? 'bg-rcgray-50' : 'bg-black/90'} text-sm leading-6 shadow-lg ring-1 ring-gray-900/5`}
          >
            {menuItems.map((item) => (
              <div
                key={item.name}
                className={`${isCustomer ? 'hover:bg-rcprimary-400/20' : 'hover:bg-rcprimary-400'} group relative flex gap-x-6 p-4`}
              >
                <div
                  className={`bg-rcprimary-400 mt-1 flex size-11 flex-none items-center justify-center rounded-lg ${isCustomer ? 'group-hover:border-rcprimary-400 group-hover:border group-hover:bg-transparent' : 'group-hover:bg-black'}`}
                >
                  <item.icon
                    className="group-hover:text-rcprimary-400 size-6 text-black"
                    aria-hidden="true"
                  />
                </div>
                <div>
                  <Popover.Button
                    as={NavLink}
                    to={item.to}
                    className={`font-semibold ${isCustomer ? 'text-black/80' : 'text-white'} group-hover:text-black`}
                  >
                    {t(item.name)}
                    <span className="absolute inset-0" />
                  </Popover.Button>
                  <p
                    className={`mt-1 ${isCustomer ? 'text-black/70' : 'text-white/70'} group-hover:text-black`}
                  >
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

NavigationDesktopDropdown.propTypes = {
  menuItems: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default NavigationDesktopDropdown;
