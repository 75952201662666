import { gql } from '@apollo/client';

const ProductRenewalIntervalInfo = gql`
  fragment ProductRenewalIntervalInfo on ProductRenewalInterval {
    id
    name
    value
    scale
    slug
    createdAt
    updatedAt
  }
`;

export default ProductRenewalIntervalInfo;
