import { gql } from '@apollo/client';
import { AdminTagInfo } from '../fragments';

export default gql`
  query adminTags {
    adminTags {
      ...AdminTagInfo
    }
  }
  ${AdminTagInfo}
`;
