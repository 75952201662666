import { gql } from '@apollo/client';

const ContactFormInfoShared = gql`
  fragment ContactFormInfoShared on ContactForm {
    id
    firstName
    lastName
    email
    phone
    message
    city
    region
    postalCode
    country
    contactFormType
    adminLocationId
    partnerLocationId
    partnerOrganizationId
    partnerLocation {
      id
      name
      slug
      address {
        id
        city
        region
        country
      }
    }
    partnerOrganization {
      id
      name
      slug
      address {
        id
        city
        region
        country
      }
    }
    createdAt
    updatedAt
  }
`;

export default ContactFormInfoShared;
