import { useQuery } from '@apollo/client';
import { GET_PRODUCT_PRICE } from '@retainerclub/customer-api';
import PropTypes from 'prop-types';

function ProductPrice({ partnerLocationId, productId, callback = null }) {
  const { loading, error, data } = useQuery(GET_PRODUCT_PRICE, {
    variables: {
      partnerLocationId,
      productId,
    },
    onCompleted: (d) => {
      if (callback) {
        callback(d.productPrice);
      }
    },
  });

  return loading || error ? 'Loading' : (data.productPrice / 100).toFixed(2);
}

ProductPrice.propTypes = {
  partnerLocationId: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  callback: PropTypes.func,
};

export default ProductPrice;
