import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { ProductCategoryInfo } from '../fragments';

export default gql`
  mutation createProductCategory($input: ProductCategoryCreateInput!) {
    createProductCategory(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      productCategory {
        ...ProductCategoryInfo
      }
    }
  }
  ${ProductCategoryInfo}
  ${ErrorMessageInfo}
`;
