import { gql } from '@apollo/client';
import { ProductCategoryInfo } from '../fragments';

export default gql`
  query productCategories {
    productCategories {
      ...ProductCategoryInfo
    }
  }
  ${ProductCategoryInfo}
`;
