import { gql } from '@apollo/client';

export default gql`
  query accountVersions($id: ID!, $klass: String!) {
    accountVersions(id: $id, klass: $klass) {
      id
      itemId
      itemType
      affectedObject
      eventVerb
      eventSubject
      createdAt
      user
    }
  }
`;
