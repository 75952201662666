import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { OrderInfoPublic } from '../fragments';

export default gql`
  mutation createOrder($input: OrderCreateInput!) {
    createOrder(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      order {
        ...OrderInfoPublic
      }
    }
  }
  ${OrderInfoPublic}
  ${ErrorMessageInfo}
`;
