import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

function UpdateCenter({ currentPosition }) {
  const map = useMap();

  useEffect(() => {
    map.flyTo(currentPosition, map.getZoom());
  }, [currentPosition, map]);

  return null;
}

UpdateCenter.propTypes = {
  currentPosition: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default UpdateCenter;
