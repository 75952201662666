/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';

function Fieldset({
  title = null,
  description = null,
  children,
  hideBorder = false,
  doubleCols = false,
  className = '',
}) {
  return (
    <div
      className={`col-span-full grid max-w-full grid-cols-1 gap-4 pb-8 ${
        doubleCols ? 'sm:grid-cols-12' : 'sm:grid-cols-6'
      } ${hideBorder ? '' : 'border-rcgray-600/30 border-b'} ${className}`}
    >
      <div className="col-span-full">
        {title && (
          <h2 className="text-xl font-bold capitalize leading-6 text-black/70">
            {title}
          </h2>
        )}

        {description && (
          <p className="text-rcprimary-400 font-oswald my-0 text-sm uppercase leading-6">
            {description}
          </p>
        )}
      </div>
      {children}
    </div>
  );
}

Fieldset.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  hideBorder: PropTypes.bool,
  doubleCols: PropTypes.bool,
  className: PropTypes.string,
};

export default Fieldset;
