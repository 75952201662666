import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

function SelectMenu({ children, title = 'Actions' }) {
  return (
    <Menu as="div" className="inline-block text-left">
      <div>
        <Menu.Button className="bg-rcprimary-400 hover:bg-rcprimary-400/0 hover:text-rcprimary-400 hover:outline-rcprimary-400 group inline-flex w-full justify-center gap-x-1.5 px-3 py-1.5 text-sm font-medium text-black outline outline-2 outline-offset-0 outline-white/0">
          {title}
          <ChevronDownIcon
            className="group-hover:text-rcprimary-400 -mr-1 h-5 w-5 text-black"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="border-rcprimary-100/30 absolute right-0 z-10 mt-2 w-80 origin-top-right divide-y divide-white/15 rounded-md border bg-black/90 py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

SelectMenu.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default SelectMenu;
