import './init';

import { ApolloProvider } from '@apollo/client';
import { RootLoader, apolloClient } from '@retainerclub/common';
import '@retainerclub/common/src/config/i18n/i18next';
import * as Sentry from '@sentry/react';
import { Amplify } from 'aws-amplify';
import posthog from 'posthog-js';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import awsAuthConfig from './config/amplify/awsAuthConfig';

import './index.css';

posthog.init(import.meta.env.VITE_POSTHOG, {
  api_host: 'https://app.posthog.com',
});

Sentry.init({
  dsn: 'https://1f83a3758f713b915bf079b5bec66b10@o1097881.ingest.us.sentry.io/4507137807876096',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/retainerclub.com/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const ampConfig = awsAuthConfig[import.meta.env.VITE_APP_ENV];

Amplify.configure({ ...ampConfig });

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Suspense fallback={<RootLoader />}>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </Suspense>
  </React.StrictMode>,
);
