import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateProfile($input: ProfileUpdateInput!) {
    updateProfile(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      currentUser {
        id
        firstName
        lastName
        imageUrl
        phone
        language
      }
    }
  }
  ${ErrorMessageInfo}
`;
