import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/20/solid';
import { useFormValues } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Datepicker from 'tailwind-datepicker-react';

const icons = {
  prev: () => (
    <span>
      <ChevronDoubleLeftIcon className="size-4" />
    </span>
  ),
  next: () => (
    <span>
      <ChevronDoubleRightIcon className="size-4" />
    </span>
  ),
};

function DatePicker({
  name,
  isFutureDate = false,
  title = null,
  label = '',
  required = false,
}) {
  const [show, setShow] = useState(false);
  const { setValue } = useFormContext();
  const values = useFormValues();
  const { t } = useTranslation('forms');

  const parsedVal = get(values, name);

  const options = useMemo(
    () => ({
      title,
      autoHide: true,
      todayBtn: false,
      clearBtn: false,
      clearBtnText: 'Clear',
      maxDate: isFutureDate ? new Date('2030-01-01') : new Date(),
      minDate: isFutureDate ? new Date() : new Date('2021-01-01'),
      theme: {
        background: 'bg-white border border-rcgray-200',
        todayBtn: '',
        clearBtn: '',
        icons: '',
        text: '',
        disabledText: 'bg-rcgray-100 text-rcgray-300',
        input:
          'disabled:bg-rcgray-600 disabled:ring-rcgray-700 disabled:text-rcgray-600 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset disabled:cursor-not-allowed sm:text-sm sm:leading-6 focus:ring-rcprimary-400 ring-gray-300 placeholder:text-gray-400',
        inputIcon: '',
        selected: 'bg-rcprimary-400 text-black',
      },
      datepickerClassNames: 'top-16',
      defaultDate: values[name] ? new Date(values[name]) : null,
      language: 'en',
      disabledDates: [],
      weekDays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
      inputNameProp: name,
      inputIdProp: name,
      inputPlaceholderProp: label,
      inputDateFormatProp: {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      },
    }),
    [isFutureDate, label, name, title, values],
  );

  function handleChange(date) {
    setValue(name, date);
  }

  function handleClose(s) {
    setShow(s);
  }

  return (
    <>
      <label
        className="mb-2 block text-sm font-medium capitalize leading-6 text-black/70"
        htmlFor={name}
      >
        {t(label || name)}
        {required && <span className="text-red-400">*</span>}
      </label>
      <Datepicker
        options={{ ...options, icons }}
        onChange={(date) => handleChange(date)}
        show={show}
        setShow={(s) => handleClose(s)}
        value={parsedVal ? new Date(parsedVal) : null}
      />
    </>
  );
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  isFutureDate: PropTypes.bool,
  title: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
};

export default DatePicker;
