import { gql } from '@apollo/client';
import { CartItemInfoPublic } from '../fragments';

export default gql`
  mutation destroyCartItem($input: CartItemDestroyInput!) {
    destroyCartItem(input: $input) {
      clientMutationId
      cartItem {
        ...CartItemInfoPublic
      }
    }
  }
  ${CartItemInfoPublic}
`;
