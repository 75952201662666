function TermsAndConditions() {
  return (
    <>
      <p>
        Last Modified: <span className="italic">Januaray 1st 2022</span>
      </p>
      <h1 className="text-2xl font-bold">Terms & Conditions</h1>
      <p>
        At Retainer Club, we do not provide orthodontic treatment. If you would
        like to improve your smile and are interested in having your teeth
        straightened you should see an orthodontist or dentist. At Retainer
        Club, we are focused exclusively on maintaining your perfect smile AFTER
        you have already completed orthodontic treatment.
      </p>

      <p>
        Retainer Club only produces retainers and mouthguards that retain your
        teeth in their current position. We do not practice orthodontics (or
        dentistry) to straighten or otherwise move your teeth -- that&apos;s the
        job of your orthodontist or dentist. We view our job as taking the baton
        from your orthodontist or dentist so that when they finish straightening
        your teeth, we regularly ship you fresh new retainers to wear when you
        sleep at night in order to maintain straight teeth for life!
      </p>

      <p>
        The 3-D scan of your teeth from the partner office where you were
        scanned is only for the purpose of receiving a retainer from Retainer
        Club, Inc. or a mouthguard from Mouthguard Club, Inc. The orthodontist
        or dentist office (the &quot;Practice&quot;) where you get scanned from
        does not make you a patient of record or constitute orthodontic and/or
        dental patient services from that Practice. If you are interested in
        becoming a patient of record or otherwise interested in seeking
        orthodontic or dental treatment from that Practice it is incumbent upon
        you to make such a request of that Practice and conform with their
        onboarding procedures.
      </p>

      <ul>
        <li>
          In order to get started with Retainer Club, you consent for Retainer
          Club to obtain a 3-D scan of your teeth from the partner offices where
          you were scanned.
        </li>
        <li>
          As a subscriber, you agree to be responsible for the periodic payments
          that are associated with your subscription.
        </li>
        <li>
          Retainer Club fees are not a covered insurance and, therefore, our
          fees should not be submitted as claims to insurance. Because Retainer
          Club is a post orthodontic treatment service to maintain your teeth in
          their current position, you agree not to submit for insurance
          reimbursement any fees associated with your purchase of Retainer Club
          retainers and to instead make direct payment to Retainer Club for your
          Retainer Club retainers.
        </li>
      </ul>
      <p>
        By becoming a subscriber of Retainer Club, you agree to the terms and
        conditions listed above.
      </p>
    </>
  );
}

export default TermsAndConditions;
