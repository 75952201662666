import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { ProductInfo } from '../fragments';

export default gql`
  mutation updateProduct($input: ProductUpdateInput!) {
    updateProduct(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      product {
        ...ProductInfo
      }
    }
  }
  ${ProductInfo}
  ${ErrorMessageInfo}
`;
