import { gql } from '@apollo/client';

const CartInfo = gql`
  fragment CartInfo on Cart {
    id
    createdAt
    updatedAt
  }
`;

export default CartInfo;
