import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { ProductionOrderInfo } from '../fragments';

export default gql`
  mutation actionProductionOrderStatus(
    $input: ProductionOrderStatusActionInput!
  ) {
    actionProductionOrderStatus(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      productionOrder {
        ...ProductionOrderInfo
      }
    }
  }
  ${ProductionOrderInfo}
  ${ErrorMessageInfo}
`;
