import { gql } from '@apollo/client';

const PartnerOrganizationInfo = gql`
  fragment PartnerOrganizationInfo on PartnerOrganization {
    id
    name
    email
    phone
    website
    active
    slug
    imageUrl
    scannerBrandId
    priceBookId
    address {
      id
      address
      address2
      city
      region
      postalCode
      country
      latitude
      longitude
    }
    createdAt
    updatedAt
  }
`;

export default PartnerOrganizationInfo;
