import { useApp, useAuth } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { RcDl, RcLink, ShowDetailsSection, ShowStatus } from '../../../shared';
import { trackingUrl } from '../../../transforms';

function OrderShowDetails({ order }) {
  const { isAdmin, isPartner } = useApp();
  const { currentUser } = useAuth();

  return (
    <RcDl>
      {(isAdmin || (isPartner && !currentUser.scopeToLocation)) && (
        <ShowDetailsSection
          title={`${isAdmin ? 'Partner Location' : 'Location'}`}
        >
          <RcLink
            to={`${isPartner ? '/locations/' : '/partners/locations/'}${
              order?.partnerLocation?.slug
            }`}
            className="text-sm"
          >
            {order?.partnerLocation?.name}
          </RcLink>
          <br />
          {order?.partnerLocation?.address?.city},{' '}
          {order?.partnerLocation?.address?.region}
        </ShowDetailsSection>
      )}
      <ShowDetailsSection title="Payment Status">
        <span>
          <ShowStatus state={order.paymentState} />
        </span>
      </ShowDetailsSection>
      <ShowDetailsSection title="EOT Order">
        <span>
          <ShowStatus state={order.eotOrder ? 'Yes' : 'No'} />
        </span>
      </ShowDetailsSection>
      {isAdmin && (
        <ShowDetailsSection title="Paid By Partner">
          <span>
            <ShowStatus state={order.paidByPartner ? 'Yes' : 'No'} />
          </span>
        </ShowDetailsSection>
      )}
      {isAdmin && (
        <ShowDetailsSection title="Delivery Method">
          <span>{order.deliveryMethod}</span>
        </ShowDetailsSection>
      )}

      <ShowDetailsSection title="Tracking Numbers">
        <span>
          {order.productionOrders.map((productionOrder) => (
            <Fragment key={productionOrder.id}>
              {productionOrder.productionOrderItems.some(
                (x) => x.trackingNumber,
              )
                ? productionOrder.productionOrderItems.map((item) => (
                    <span key={item.id} className="block text-sm">
                      <RcLink
                        href={trackingUrl(
                          item?.shippingCarrier?.name,
                          item.trackingNumber,
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.trackingNumber}
                      </RcLink>{' '}
                      {item.shippingCarrier
                        ? `(${item.shippingCarrier.name})`
                        : ''}
                    </span>
                  ))
                : null}
            </Fragment>
          ))}
        </span>
      </ShowDetailsSection>
    </RcDl>
  );
}

OrderShowDetails.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderShowDetails;
