import { gql } from '@apollo/client';
import { AccountInfoPublic } from '../fragments';

export default gql`
  query account {
    account {
      ...AccountInfoPublic
    }
  }
  ${AccountInfoPublic}
`;
