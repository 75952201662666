import PropTypes from 'prop-types';
import { get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function FormErrors({ errors = {}, name = '' }) {
  const { t } = useTranslation('forms');

  const e = get(errors, name);

  return errors && e && e.message ? (
    <p className="mt-2 text-sm text-red-500" id={`${name}-error`}>
      {t(e.message)}
    </p>
  ) : null;
}

FormErrors.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string,
};

export default FormErrors;
