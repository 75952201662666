import { gql } from '@apollo/client';
import { PartnerUserInfoShared } from '../fragments';

export default gql`
  mutation destroyPartnerUser($input: PartnerUserDestroyInput!) {
    destroyPartnerUser(input: $input) {
      clientMutationId
      partnerUser {
        ...PartnerUserInfoShared
      }
    }
  }
  ${PartnerUserInfoShared}
`;
