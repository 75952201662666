import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';

export default gql`
  mutation actionUserResendInvite($input: ResendInviteActionInput!) {
    actionUserResendInvite(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
    }
  }
  ${ErrorMessageInfo}
`;
