import { gql } from '@apollo/client';
import { OrderInfo } from '../fragments';

export default gql`
  query order($id: ID, $slug: String) {
    order(id: $id, slug: $slug) {
      ...OrderInfo
    }
  }
  ${OrderInfo}
`;
