import { gql } from '@apollo/client';
import { PartnerUserInfoShared } from '../fragments';

export default gql`
  query partnerUsers($partnerOrganizationId: ID, $partnerLocationId: ID) {
    partnerUsers(
      partnerOrganizationId: $partnerOrganizationId
      partnerLocationId: $partnerLocationId
    ) {
      ...PartnerUserInfoShared
    }
  }
  ${PartnerUserInfoShared}
`;
