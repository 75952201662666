import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { PriceBookInfo } from '../fragments';

export default gql`
  mutation updatePriceBook($input: PriceBookUpdateInput!) {
    updatePriceBook(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      priceBook {
        ...PriceBookInfo
      }
    }
  }
  ${PriceBookInfo}
  ${ErrorMessageInfo}
`;
