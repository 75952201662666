import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';

const ToastContext = createContext();

export function ToastProvider(props) {
  const { children } = props;

  const [toast, setToast] = useState({
    show: false,
    message: '',
    title: '',
    success: true,
  });

  const value = useMemo(() => ({ setToast, toast }), [toast]);

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
}

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useToast() {
  return useContext(ToastContext);
}
