import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { UserInfoPublic } from '../fragments';

export default gql`
  mutation createUser($input: UserCreateInput!) {
    createUser(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      user {
        ...UserInfoPublic
      }
    }
  }
  ${UserInfoPublic}
  ${ErrorMessageInfo}
`;
