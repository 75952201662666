import { gql } from '@apollo/client';
import { PartnerLocationInfo } from '../fragments';

export default gql`
  query partnerLocations($partnerOrganizationId: ID) {
    partnerLocations(partnerOrganizationId: $partnerOrganizationId) {
      ...PartnerLocationInfo
    }
  }
  ${PartnerLocationInfo}
`;
