import { AppInner, ErrorPage } from '@retainerclub/common';
import { MainLayout } from '@retainerclub/customer-shared';
import { useEffect, useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { LocationProvider } from './shared/Context';
import Locator from './views/Locator/Locator';

function App() {
  const [cookies, setCookie] = useCookies(['rcGeo']);
  const [locationData, setLocationData] = useState({});

  useEffect(() => {
    const { rcGeo } = cookies;

    if (!rcGeo) {
      // GET AN API KEY IF REQUESTS ARE BLOCKED - 30K REQUESTS PER MONTH
      fetch(`https://ipapi.co/json/`, {
        method: 'GET',
      })
        .then((response) => response.json())
        .then((data) => {
          const locData = {
            address: null,
            city: data.city,
            region: data.region,
            postal: data.postal,
            lat: data.latitude,
            lng: data.longitude,
            country: data.country,
            formattedAddress: null,
          };

          setCookie('rcGeo', locData, {
            path: '/',
            maxAge: 3600 * 24 * 365,
          });

          setLocationData(locData);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('error', error);
        });
    }

    if (rcGeo) {
      setLocationData(rcGeo);
    }
  }, [cookies, setCookie]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AppInner />} errorElement={<ErrorPage />}>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Locator />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Route>,
    ),
  );

  return (
    <CookiesProvider>
      <LocationProvider
        value={{
          locationData,
          setLocationData,
        }}
      >
        <RouterProvider router={router} />
      </LocationProvider>
    </CookiesProvider>
  );
}

export default App;
