import { gql } from '@apollo/client';
import { StoreInfo } from '../fragments';

export default gql`
  query stores {
    stores {
      ...StoreInfo
    }
  }
  ${StoreInfo}
`;
