import { gql } from '@apollo/client';

export default gql`
  query countries {
    countries {
      id
      value
      name
    }
  }
`;
