import { gql } from '@apollo/client';
import { ContactFormInfoShared } from '../fragments';

export default gql`
  query contactForms(
    $partnerOrganizationId: ID
    $partnerLocationId: ID
    $adminLocationId: ID
  ) {
    contactForms(
      partnerOrganizationId: $partnerOrganizationId
      partnerLocationId: $partnerLocationId
      adminLocationId: $adminLocationId
    ) {
      ...ContactFormInfoShared
    }
  }
  ${ContactFormInfoShared}
`;
