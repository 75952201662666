import { gql } from '@apollo/client';
import { AccountInfoShared } from '@retainerclub/shared-api';

export default gql`
  query account($slug: String, $id: ID) {
    account(slug: $slug, id: $id) {
      ...AccountInfoShared
    }
  }
  ${AccountInfoShared}
`;
