import { gql } from '@apollo/client';
import { SubscriptionInfoShared } from '../fragments';

export default gql`
  query subscriptions($partnerOrganizationId: ID, $partnerLocationId: ID) {
    subscriptions(
      partnerOrganizationId: $partnerOrganizationId
      partnerLocationId: $partnerLocationId
    ) {
      ...SubscriptionInfoShared
    }
  }
  ${SubscriptionInfoShared}
`;
