import { gql } from '@apollo/client';
import { CartInfo } from '../fragments';

export default gql`
  query cart($id: ID) {
    cart(id: $id) {
      ...CartInfo
    }
  }
  ${CartInfo}
`;
