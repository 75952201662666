import { Tab } from '@headlessui/react';
import PropTypes from 'prop-types';

function ProductShowImages({ images = [], featuredImage }) {
  return (
    <Tab.Group as="div" className="flex flex-col-reverse">
      <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
        <Tab.List className="grid grid-cols-4 gap-6">
          <Tab className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4">
            {({ selected }) => (
              <>
                <span className="sr-only">Featured Image</span>
                <span className="absolute inset-0 overflow-hidden rounded-md">
                  <img
                    src={featuredImage}
                    alt=""
                    className="h-full w-full object-cover object-center"
                  />
                </span>
                <span
                  className={`${
                    selected ? 'ring-rcprimary-200' : 'ring-transparent'
                  }
                      pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2`}
                  aria-hidden="true"
                />
              </>
            )}
          </Tab>
          {images.map((image) => (
            <Tab
              key={image.id}
              className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4"
            >
              {({ selected }) => (
                <>
                  <span className="sr-only">{image.name}</span>
                  <span className="absolute inset-0 overflow-hidden rounded-md">
                    <img
                      src={image.src}
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    className={`${
                      selected ? 'ring-rcprimary-200' : 'ring-transparent'
                    }
                      pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2`}
                    aria-hidden="true"
                  />
                </>
              )}
            </Tab>
          ))}
        </Tab.List>
      </div>

      <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
        <Tab.Panel>
          <img
            src={featuredImage}
            alt="featured"
            className="h-full w-full object-cover object-center sm:rounded-lg"
          />
        </Tab.Panel>
        {images.map((image) => (
          <Tab.Panel key={image.id}>
            <img
              src={image.src}
              alt={image.alt}
              className="h-full w-full object-cover object-center sm:rounded-lg"
            />
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}

ProductShowImages.propTypes = {
  featuredImage: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
    }),
  ),
};

export default ProductShowImages;
