import { useApp } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { Card } from '../../../shared';

function OrderShowRevenue({ order }) {
  const { isAdmin } = useApp();

  return (
    <Card className="h-full p-4">
      <table className="w-full text-black/80">
        <thead className="sr-only text-left text-sm text-black/80 sm:not-sr-only">
          <tr>
            <th scope="col" className="py-2 pr-8 text-right font-medium">
              Total Charged to Customer
            </th>
            {isAdmin && (
              <>
                <th
                  scope="col"
                  className="hidden py-2 pr-8 text-right font-medium sm:table-cell"
                >
                  Top Line Revenue
                </th>
                <th
                  scope="col"
                  className="hidden py-2 pr-8 text-right font-medium sm:table-cell"
                >
                  Earned Revenue
                </th>
              </>
            )}

            <th
              scope="col"
              className="hidden py-2 pr-8 text-right font-medium sm:table-cell"
            >
              {isAdmin ? 'Partner Rev. (Platform)' : 'Paid Online'}
            </th>
            <th
              scope="col"
              className="hidden py-2 pr-8 text-right font-medium sm:table-cell"
            >
              {isAdmin ? 'Partner Rev. (In Office)' : 'Paid in Office'}
            </th>
            <th
              scope="col"
              className="py-2 pr-8 text-right font-medium sm:table-cell"
            >
              {isAdmin ? 'Total Partner Rev.' : 'Total Revenue'}
            </th>
            <th
              scope="col"
              className="py-2 pr-8 text-right font-medium sm:table-cell"
            >
              {isAdmin ? 'RC Partner Fees' : 'RC Fee'}
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-black/20 border-b border-black/20 text-sm sm:border-t">
          <tr>
            <td className="hidden py-4 pr-8 text-right sm:table-cell">
              ${(order.total / 100).toFixed(2)}
            </td>
            {isAdmin && (
              <>
                <td className="hidden py-4 pr-8 text-right sm:table-cell">
                  ${(order.toplineRevenue / 100).toFixed(2)}
                </td>
                <td className="hidden py-4 pr-8 text-right sm:table-cell">
                  ${(order.earnedRevenue / 100).toFixed(2)}
                </td>
              </>
            )}

            <td className="hidden py-4 pr-8 text-right sm:table-cell">
              ${(order.partnerRevenuePlatform / 100).toFixed(2)}
            </td>
            <td className="hidden py-4 pr-8 text-right sm:table-cell">
              ${(order.partnerRevenueOffice / 100).toFixed(2)}
            </td>
            <td className="hidden py-4 pr-8 text-right sm:table-cell">
              ${(order.partnerRevenueTotal / 100).toFixed(2)}
            </td>
            <td className="hidden py-4 pr-8 text-right sm:table-cell">
              ${(order.partnerFee / 100).toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
}

OrderShowRevenue.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderShowRevenue;
