import { gql } from '@apollo/client';
import { ReferralInfoShared } from '../fragments';

export default gql`
  mutation destroyReferral($input: ReferralDestroyInput!) {
    destroyReferral(input: $input) {
      clientMutationId
      referral {
        ...ReferralInfoShared
      }
    }
  }
  ${ReferralInfoShared}
`;
