import { gql } from '@apollo/client';

export default gql`
  query partnerLocations($partnerOrganizationId: ID) {
    partnerLocations(partnerOrganizationId: $partnerOrganizationId) {
      id
      name
    }
  }
`;
