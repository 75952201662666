import * as Yup from 'yup';

function schema() {
  return Yup.object().shape({
    firstName: Yup.string().nullable(true),
    lastName: Yup.string().nullable(true),
    email: Yup.string().email().nullable(true),
    phone: Yup.string().nullable(true),
    message: Yup.string().nullable(true),
    postalCode: Yup.string().nullable(true),
    contactFormType: Yup.string().required('contact form type is required'),
  });
}

export default schema;
