import { Dialog } from '@headlessui/react';
import PropTypes from 'prop-types';
import {
  CheckboxInput,
  CountrySelect,
  Input,
  RegionSelect,
} from '../../../FormElements';
import Modal from '../../../Modal/Modal';
import AccountAddressModalWrapper from './AccountAddressModalWrapper';

function AccountAddressModal({
  open,
  handleCloseModal,
  currentAddress = null,
  accountId,
}) {
  return (
    <Modal open={open} setOpen={handleCloseModal} size="2xl">
      <div>
        <div className="text-center">
          <Dialog.Title
            as="h2"
            className="text-rcgray-900 text-2xl font-semibold capitalize leading-6"
          >
            {currentAddress ? 'Update' : 'New'} shipping address
          </Dialog.Title>
        </div>
      </div>
      <div className="mt-4">
        <AccountAddressModalWrapper
          accountAddress={currentAddress}
          accountId={accountId}
          handleCloseModal={handleCloseModal}
        >
          <div className="sm:col-span-6">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-full">
                <CountrySelect name="country" required />
              </div>
              <div className="sm:col-span-6">
                <Input name="address" label="Address" required />
              </div>
              <div className="translate-y-4 text-xs text-red-700 sm:col-span-6">
                Please add Unit, Suite, or Apt number to avoid shipping delays.
              </div>
              <div className="sm:col-span-6">
                <Input name="address2" label="Address Line 2" />
              </div>
              <div className="sm:col-span-2">
                <Input name="city" label="City" required />
              </div>
              <div className="sm:col-span-2">
                <RegionSelect name="region" countryFieldName="country" />
              </div>
              <div className="sm:col-span-2">
                <Input name="postalCode" label="Postal Code" required />
              </div>
              <div className="mt-4 sm:col-span-full">
                <CheckboxInput
                  name="isDefault"
                  label="Default?"
                  description="Make this the default shipping address?"
                />
              </div>
            </div>
          </div>
        </AccountAddressModalWrapper>
      </div>
    </Modal>
  );
}

AccountAddressModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  currentAddress: PropTypes.object,
  accountId: PropTypes.string.isRequired,
};

export default AccountAddressModal;
