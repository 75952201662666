import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { PartnerUserInfoShared } from '../fragments';

export default gql`
  mutation updatePartnerUser($input: PartnerUserUpdateInput!) {
    updatePartnerUser(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      partnerUser {
        ...PartnerUserInfoShared
      }
    }
  }
  ${PartnerUserInfoShared}
  ${ErrorMessageInfo}
`;
