import { useApolloClient } from '@apollo/client';
import {
  ArchiveBoxXMarkIcon,
  ArrowPathIcon,
  ExclamationTriangleIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/20/solid';
import {
  ACTION_REFERRAL_RESEND,
  ACTION_REFERRAL_STATUS,
} from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { SelectMenuItem } from '../../../shared';

function ReferralShowActions({ referral }) {
  const client = useApolloClient();

  const resendStates = ['sent', 'overdue1', 'overdue2', 'overdue3'];

  async function changeState(action) {
    await client.mutate({
      mutation: ACTION_REFERRAL_STATUS,
      variables: {
        input: {
          input: {
            id: referral.id,
            action,
          },
          clientMutationId: uuidv4(),
        },
      },
    });
  }

  async function resend() {
    await client.mutate({
      mutation: ACTION_REFERRAL_RESEND,
      variables: {
        input: {
          input: {
            id: referral.id,
          },
          clientMutationId: uuidv4(),
        },
      },
    });
  }

  return (
    <>
      {referral.state === 'unsent' && (
        <SelectMenuItem
          onClick={() => changeState('send_out')}
          icon={PaperAirplaneIcon}
        >
          Send
        </SelectMenuItem>
      )}
      {resendStates.includes(referral.state) && (
        <SelectMenuItem onClick={() => resend()} icon={ArrowPathIcon}>
          Resend
        </SelectMenuItem>
      )}
      {referral.state !== 'canceled' && referral.state !== 'completed' && (
        <SelectMenuItem
          onClick={() => changeState('cancel')}
          icon={ArchiveBoxXMarkIcon}
        >
          Cancel
        </SelectMenuItem>
      )}
      {referral.state === 'canceled' && (
        <SelectMenuItem
          onClick={() => changeState('uncancel')}
          icon={ExclamationTriangleIcon}
        >
          Uncancel
        </SelectMenuItem>
      )}
    </>
  );
}

ReferralShowActions.propTypes = {
  referral: PropTypes.object.isRequired,
};

export default ReferralShowActions;
