import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useContext, useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { LocationContext } from '../../../shared/Context/LocationContext';
import BoundsOnEvents from './BoundsOnEvents';
import RcMarkers from './RcMarkers';
import SetViewOnClick from './SetViewOnClick';
import UpdateCenter from './UpdateCenter';

function LocatorMap() {
  const { locationData } = useContext(LocationContext);
  const [currentPosition, setCurrentPosition] = useState(() => {
    if ((locationData.lat, locationData.lng)) {
      return [locationData.lat, locationData.lng];
    }

    return [39.7392, -104.9903];
  });

  useEffect(() => {
    const { lat, lng } = locationData;
    if ((lat, lng)) {
      setCurrentPosition([lat, lng]);
    }
  }, [locationData]);

  L.Icon.Default.imagePath =
    '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.4/images/';

  if (!locationData.lat || !locationData.lng) {
    return null;
  }

  return (
    <div className="leaflet-container size-full">
      <MapContainer
        center={[locationData.lat, locationData.lng]}
        zoom={11}
        minZoom={7}
        style={{ width: '100%', height: '100%' }}
        boundsOptions={{ padding: [150, 150] }}
      >
        <BoundsOnEvents />

        <TileLayer
          url={`https://api.mapbox.com/styles/v1/retainerclub/clmsbq9b701z701rc20a42o7w/tiles/256/{z}/{x}/{y}@2x?access_token=${
            import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
          }`}
        />
        <SetViewOnClick />
        <UpdateCenter currentPosition={currentPosition} />
        <RcMarkers />
      </MapContainer>
    </div>
  );
}

export default LocatorMap;
