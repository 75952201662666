import PropTypes from 'prop-types';
import { Card } from '../../../shared';

function OrdersShowProductDetails({ order }) {
  return (
    <Card className="h-full p-4">
      <table className="w-full text-black/80">
        <thead className="sr-only text-left text-sm text-black/80 sm:not-sr-only">
          <tr>
            <th scope="col" className="py-2 pr-8 font-medium sm:w-2/5 lg:w-1/3">
              Product
            </th>
            <th
              scope="col"
              className="hidden py-2 pr-8 text-right font-medium sm:table-cell"
            >
              Quantity
            </th>
            <th
              scope="col"
              className="hidden py-2 pr-8 text-right font-medium sm:table-cell"
            >
              Discounts
            </th>
            <th
              scope="col"
              className="hidden py-2 pr-8 text-right font-medium sm:table-cell"
            >
              Tax
            </th>
            <th
              scope="col"
              className="hidden py-2 pr-8 text-right font-medium sm:table-cell"
            >
              Shipping
            </th>
            <th
              scope="col"
              className="py-2 pr-8 text-right font-medium sm:table-cell"
            >
              Total
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-black/20 border-b border-black/20 text-sm sm:border-t">
          {order.orderItems.map((orderItem) => (
            <tr key={orderItem.id}>
              <td className="py-2 pr-8">
                <div className="flex items-center">
                  <img
                    src={orderItem.product.featuredImage}
                    alt={orderItem.name}
                    className="mr-6 size-12 rounded object-cover object-center"
                  />
                  <div>
                    <div className="font-medium text-black">
                      {orderItem.name}{' '}
                      {orderItem.patient && (
                        <span className="text-xs font-normal capitalize">
                          (For {orderItem.patient.firstName}{' '}
                          {orderItem.patient.lastName})
                        </span>
                      )}
                    </div>
                    <div className="mt-1 sm:hidden">
                      ${(orderItem.price / 100).toFixed(2)}
                    </div>
                  </div>
                </div>
              </td>
              <td className="hidden py-4 pr-8 text-right sm:table-cell">
                {orderItem.quantity}
              </td>
              <td className="text-rccallouts-danger hidden py-4 pr-8 text-right sm:table-cell">
                (${(orderItem.discountAmount / 100).toFixed(2)})
              </td>
              <td className="hidden py-4 pr-8 text-right sm:table-cell">
                ${(orderItem.taxAmount / 100).toFixed(2)}
              </td>
              <td className="hidden py-4 pr-8 text-right sm:table-cell">
                ${(orderItem.shippingAmount / 100).toFixed(2)}
              </td>
              <td className="hidden py-4 pr-8 text-right sm:table-cell">
                ${(orderItem.price / 100).toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
}

OrdersShowProductDetails.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrdersShowProductDetails;
