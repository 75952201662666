import { Popover, Transition } from '@headlessui/react';
import { CogIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import NavColumn from './NavColumn';

function NavigationDesktopDropdownFull(props) {
  const { sections } = props;

  return (
    <Popover className="relative">
      <div>
        <Popover.Button className="hover:text-rcprimary-400 flex max-w-xs items-center rounded-full text-sm text-black focus:outline-none">
          <CogIcon className="h-6 w-6" aria-hidden="true" />
        </Popover.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 -translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-1"
      >
        <Popover.Panel className="fixed right-0 -z-10 mx-4 mt-2">
          <div className="border-rcprimary-100/30 grid grid-cols-3 gap-x-6 rounded-3xl border bg-black/90 p-12 shadow-lg sm:gap-x-32">
            {sections.map((section) => (
              <NavColumn
                key={section.title}
                title={section.title}
                links={section.links}
              />
            ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

NavigationDesktopDropdownFull.propTypes = {
  sections: PropTypes.array.isRequired,
};

export default NavigationDesktopDropdownFull;
