import { gql } from '@apollo/client';

const SettingGroupInfoShared = gql`
  fragment SettingGroupInfoShared on SettingGroup {
    id
    name
    description
    symbol
    optionRestriction
    settingGroupOptions {
      id
      name
      description
      symbol
      globalDefault
    }
  }
`;

export default SettingGroupInfoShared;
