import { gql } from '@apollo/client';

const ProductCategoryInfo = gql`
  fragment ProductCategoryInfo on ProductCategory {
    id
    name
    description
    slug
  }
`;

export default ProductCategoryInfo;
