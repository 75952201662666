import PropTypes from 'prop-types';

function ShowSectionTitle({ title }) {
  return <h2 className="font-oswald text-xl leading-6 text-black">{title}</h2>;
}
ShowSectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ShowSectionTitle;
