import { useContext, useState } from 'react';
import { LocationContext } from '../../../../shared/Context';
import SearchLocation from './SearchLocation/SearchLocation';

function SidebarActions() {
  const { locationData } = useContext(LocationContext);
  const [open, setOpen] = useState(false);

  function formatAddress() {
    const { address, city, region, postal } = locationData;

    return (
      <>
        {address ? (
          <>
            {address}
            <br />
          </>
        ) : null}
        {city ? `${city}, ` : null} {region ? `${region} ` : null}{' '}
        {postal ? `${postal} ` : null}
      </>
    );
  }

  return (
    <>
      <div className="border-b border-black/10 bg-white/90 px-8 py-4">
        <h2 className="text-4xl font-bold">Find A Partner</h2>
        <div className="border-rcprimary-400 my-2 w-1/3 border-2 border-b" />
        <p className="mb-4 text-sm">
          Retainer Club partners with orthodontists and dentists across the
          country to provide the most advanced 3D scans of your teeth. Enter
          your location to find an office near you.
        </p>
        <div>
          <div className="flex flex-row items-center justify-between">
            <div>
              <p className="block text-sm font-medium capitalize leading-6 text-black/70">
                Current Location
              </p>
              <p className="text-rcprimary-400 text-sm font-bold">
                {formatAddress()}
              </p>
            </div>

            <button
              type="button"
              onClick={() => setOpen(true)}
              className="bg-rcprimary-400 hover:ring-rcprimary-400 hover:text-rcprimary-400 flex flex-row items-center rounded-md px-2.5 py-1 text-xs font-medium text-black shadow-md ring-1 ring-inset ring-transparent hover:bg-transparent"
            >
              Update Location
            </button>
          </div>
        </div>
        {/*
          <div className="flex flex-row gap-x-4 ">
            <SearchFilter totalObjects={totalObjects} />
          </div>
        */}
      </div>
      <SearchLocation open={open} setOpen={setOpen} />
    </>
  );
}

export default SidebarActions;
