import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateAccountNote($input: AccountNoteUpdateInput!) {
    updateAccountNote(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      accountNote {
        id
        message
        accountId
        patientId
        accountNoteableType
        accountNoteable {
          id
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
    }
  }

  ${ErrorMessageInfo}
`;
