import { gql } from '@apollo/client';

const UserInfoPublic = gql`
  fragment UserInfoPublic on User {
    id
    firstName
    lastName
    email
    phone
    imageUrl
    slug
    account {
      id
      accountNumber
      slug
      patients {
        id
        firstName
        lastName
        slug
        arches
        dob
        birthDay
        birthMonth
        birthYear
      }
    }
    lastLoginAt
    createdAt
    updatedAt
  }
`;

export default UserInfoPublic;
