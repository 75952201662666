import PropTypes from 'prop-types';
import { FieldContainer } from '../FieldContainer';
import RcSelect from './RcSelect';

function ArchSelect({
  label = null,
  style = {},
  className = '',
  required = false,
  hideLabel = false,
  name = 'arches',
}) {
  const arches = [
    { id: 'both', name: 'both' },
    { id: 'upper', name: 'upper' },
    { id: 'lower', name: 'lower' },
  ];

  return (
    <FieldContainer
      style={style}
      className={className}
      required={required}
      hideLabel={hideLabel}
      name={name}
      label={label}
    >
      <RcSelect name={name} data={arches} />
    </FieldContainer>
  );
}

ArchSelect.propTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  name: PropTypes.string,
};

export default ArchSelect;
