import { Dialog } from '@headlessui/react';
import { MapIcon } from '@heroicons/react/24/outline';
import { Modal } from '@retainerclub/common';
import PropTypes from 'prop-types';
import SearchLocationInput from './SearchLocationInput';

function SearchLocation({ open, setOpen }) {
  return (
    <Modal open={open} setOpen={setOpen}>
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
          <MapIcon className="text-rcprimary-400 h-6 w-6" aria-hidden="true" />
        </div>
        <div className="mt-3 sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-center text-base font-semibold leading-6 text-black"
          >
            Update Your Location
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-center text-sm text-gray-500">
              Find a Retainer Club partner near you.
            </p>
            <SearchLocationInput setOpen={setOpen} />
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <button
          type="button"
          className="bg-rcprimary-400 focus-visible:outline-rcprimary-400 hover:bg-rcprimary-300 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          onClick={() => setOpen(false)}
        >
          Go back
        </button>
      </div>
    </Modal>
  );
}

SearchLocation.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default SearchLocation;
