/* eslint-disable camelcase */

export const geocodeErr =
  '> 💡use-places-autocomplete: Please provide an address when using getGeocode() with the componentRestrictions.';

export function getGeocode(args) {
  const geocoder = new window.google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode(args, (results, status) => {
      if (status !== 'OK') reject(status);

      if (!args.address && args.componentRestrictions) {
        // eslint-disable-next-line no-console
        console.error(geocodeErr);
        resolve(results);
      }

      resolve(results);
    });
  });
}

function gmapComponentParse(result, name, nameType = 'long_name') {
  let response;

  const addressComponent = result.address_components.find((add) =>
    add.types.some((type) => type === name),
  );

  if (addressComponent) {
    response = addressComponent[`${nameType}`];
  }

  return response;
}

function parseResult(result) {
  const { lat, lng } = result.geometry.location;

  let address;

  const streetNumber = gmapComponentParse(result, 'street_number');

  const route = gmapComponentParse(result, 'route', 'short_name');

  if (streetNumber && route) {
    address = `${streetNumber} ${route}`;
  }

  return {
    lat: lat(),
    lng: lng(),
    address,
    city: gmapComponentParse(result, 'locality'),
    postal: gmapComponentParse(result, 'postal_code'),
    region: gmapComponentParse(result, 'administrative_area_level_1'),
    country: gmapComponentParse(result, 'country', 'short_name'),
    formattedAddress: result.formatted_address,
  };
}

export function getLatLng(result) {
  return new Promise((resolve, reject) => {
    try {
      const response = parseResult(result);

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function getZipCode(result, useShortName) {
  return new Promise((resolve, reject) => {
    try {
      let zipCode = null;

      result.address_components.forEach(({ long_name, short_name, types }) => {
        if (types.includes('postal_code'))
          zipCode = useShortName ? short_name : long_name;
      });

      resolve(zipCode);
    } catch (error) {
      reject(error);
    }
  });
}
