import { gql } from '@apollo/client';

export default gql`
  query accountNote($id: ID!) {
    accountNote(id: $id) {
      id
      message
      accountId
      patientId
      accountNoteableType
      accountNoteable {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;
