import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { BaseLoader } from '../../Loader';
import { SubmitButton } from '../Buttons';

function Form({
  children,
  schema,
  initialValues,
  sidebarTitle = null,
  sidebarDescription = null,
  customSidebar = null,
  secondColumn = null,
  createAction,
  updateAction,
  updateQuery,
  afterNewLink,
  afterEditLink,
  showOnEdit = false,
  showOnNew = false,
  dataName,
}) {
  const { pathname } = useLocation();
  const { slug, id } = useParams();
  const navigate = useNavigate();
  const [mutationErrors, setMutationErrors] = useState(null);

  const app = import.meta.env.VITE_APP_NAME;

  const [create, { error: cError, loading: cLoading }] =
    useMutation(createAction);
  const [update, { error: uError, loading: uLoading }] =
    useMutation(updateAction);

  let variables = {};

  if (slug) {
    variables = { slug };
  }

  if (id) {
    variables = { id };
  }

  const {
    data: qData,
    error: qError,
    loading: qLoading,
  } = useQuery(updateQuery, {
    variables,
    skip: variables.slug === undefined && variables.id === undefined,
  });

  const {
    register,
    handleSubmit,
    formState: { isLoading, isSubmitting, isValid },
    formState,
    getValues,
    setValue,
    control,
    ...rest
  } = useForm({
    resolver: yupResolver(schema()),
    defaultValues: initialValues({ record: null }),
    values: initialValues({ record: qData ? qData[dataName] : null }),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  function mutationResponseObject() {
    return `${pathname.endsWith('/new') ? 'create' : 'update'}${dataName
      .charAt(0)
      .toUpperCase()}${dataName.slice(1)}`;
  }

  function constructNavLink(afterLink, showOn, data) {
    return `/${afterLink}${showOn ? '/' : ''}${
      showOn ? data[mutationResponseObject()][dataName].slug : ''
    }`;
  }

  function mutationErrorsObject(data) {
    return data[mutationResponseObject()].errorMessages;
  }

  async function submitRecord(values) {
    try {
      const params = {
        variables: {
          input: {
            input: { ...values },
            clientMutationId: uuidv4(),
          },
        },
      };

      if (pathname.endsWith('/new')) {
        const { data } = await create(params);

        const mErrors = mutationErrorsObject(data);

        if (mErrors.length > 0) {
          setMutationErrors(mErrors);
        } else {
          const link = constructNavLink(afterNewLink, showOnNew, data);

          navigate(link, {
            state: {
              showToast: true,
              title: 'Success',
              message: 'Record created successfully',
            },
          });
        }
      }

      if (pathname.endsWith('/edit')) {
        const { data } = await update(params);

        const mErrors = mutationErrorsObject(data);

        if (mErrors.length > 0) {
          setMutationErrors(mErrors);
        } else {
          const link = constructNavLink(afterEditLink, showOnEdit, data);

          navigate(link, {
            state: {
              showToast: true,
              title: 'Success',
              message: 'Record updated successfully',
            },
          });
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  if (qLoading) {
    return <BaseLoader title={sidebarTitle} />;
  }

  return (
    <FormProvider
      {...rest}
      control={control}
      register={register}
      handleSubmit={handleSubmit}
      formState={formState}
      getValues={getValues}
      setValue={setValue}
    >
      <form onSubmit={handleSubmit(submitRecord)} noValidate>
        {app.includes('partner') && (sidebarDescription || sidebarTitle) && (
          <div className="mb-2 flex flex-col items-start rounded-md bg-white p-4 shadow sm:flex-row sm:justify-between">
            {sidebarTitle && (
              <h2 className="mr-4 text-xl font-bold leading-6 text-black/90">
                {pathname.endsWith('/new') ? 'New ' : 'Edit '}
                {sidebarTitle}
              </h2>
            )}
            {sidebarDescription && (
              <p className="mt-1 text-sm text-black/50">{sidebarDescription}</p>
            )}
          </div>
        )}
        <div className="mb-24 grid grid-cols-1 gap-x-8 md:grid-cols-4">
          <div
            className={`rounded-md bg-white p-4 shadow ${
              app.includes('admin') ? 'md:col-span-3' : 'md:col-span-4'
            }`}
          >
            <div className="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-6">
              <div
                className={`col-span-6 grid grid-cols-1 gap-4 sm:grid-cols-6 ${
                  secondColumn ? 'md:col-span-4' : 'md:col-span-6'
                } `}
              >
                {children}

                <div className="col-span-full mb-8">
                  {cError && (
                    <div className="col-span-full mt-4">
                      <ErrorMessage error={cError} />
                    </div>
                  )}
                  {uError && (
                    <div className="col-span-full mt-4">
                      <ErrorMessage error={uError} />
                    </div>
                  )}
                  {qError && (
                    <div className="col-span-full mt-4">
                      <ErrorMessage error={qError} />
                    </div>
                  )}

                  {mutationErrors && (
                    <div className="col-span-full mt-4">
                      <ErrorMessage errorMessages={mutationErrors} />
                    </div>
                  )}
                  <div className="col-span-full mt-4 flex items-center justify-end gap-x-4">
                    <SubmitButton
                      isLoading={isLoading || cLoading || uLoading || qLoading}
                      isSubmitting={isSubmitting}
                      isValid={isValid}
                      className="w-1/3"
                      label={pathname.endsWith('/new') ? 'Create' : 'Update'}
                      full={false}
                      notRounded
                    />
                  </div>
                </div>
              </div>
              {secondColumn && (
                <div className="col-span-6 md:col-span-2">{secondColumn}</div>
              )}
            </div>
          </div>
          {app.includes('admin') && (
            <div className="hidden sm:block">
              {customSidebar || (
                <>
                  {sidebarTitle && (
                    <h2 className="text-xl font-bold leading-6 text-black">
                      {pathname.endsWith('/new') ? 'New ' : 'Edit '}
                      {sidebarTitle}
                    </h2>
                  )}
                  {sidebarDescription && (
                    <p className="mt-1 text-sm text-black/40">
                      {sidebarDescription}
                    </p>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
}

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  schema: PropTypes.func.isRequired,
  initialValues: PropTypes.func.isRequired,
  sidebarTitle: PropTypes.string,
  sidebarDescription: PropTypes.string,
  customSidebar: PropTypes.node,
  secondColumn: PropTypes.node,
  createAction: PropTypes.object.isRequired,
  updateAction: PropTypes.object.isRequired,
  afterEditLink: PropTypes.string.isRequired,
  afterNewLink: PropTypes.string.isRequired,
  showOnEdit: PropTypes.bool,
  showOnNew: PropTypes.bool,
  dataName: PropTypes.string.isRequired,
  updateQuery: PropTypes.object.isRequired,
};

export default Form;
