import { useApp, useAuth } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { RcDl, RcLink, ShowDetailsSection } from '../../../shared';
import { timestampFormatter } from '../../../transforms';

function ReferralShowDetails({ referral }) {
  const { isAdmin, isPartner } = useApp();
  const { currentUser } = useAuth();

  return (
    <RcDl dividers={false} gridCols="sm:grid-cols-3 gap-y-4">
      {(isAdmin || (isPartner && !currentUser.scopeToLocation)) && (
        <ShowDetailsSection
          horizontal={false}
          title={`${isAdmin ? 'Partner Location' : 'Location'}`}
        >
          <RcLink
            to={`${isPartner ? '/locations/' : '/partners/locations/'}${
              referral?.partnerLocation?.slug
            }`}
            className="text-sm"
          >
            {referral?.partnerLocation?.name}
          </RcLink>
          <br />
          {referral?.partnerLocation?.address?.city},{' '}
          {referral?.partnerLocation?.address?.region}
        </ShowDetailsSection>
      )}
      <ShowDetailsSection title="Source" horizontal={false}>
        <span className="capitalize">
          {referral.source === 'qr_code'
            ? 'QR Code'
            : referral.source.replace(/_/g, ' ')}
        </span>
      </ShowDetailsSection>
      <ShowDetailsSection title="Last Sent" horizontal={false}>
        {referral.sentAt === null ? (
          'N/A'
        ) : (
          <time dateTime={referral.sentAt}>
            {timestampFormatter(referral.sentAt)}
          </time>
        )}
      </ShowDetailsSection>
      <ShowDetailsSection title="Reminders Sent" horizontal={false}>
        {referral.reminders}
      </ShowDetailsSection>
      <ShowDetailsSection title="Activated At" horizontal={false}>
        {referral.consumedAt
          ? timestampFormatter(referral.consumedAt)
          : 'Not Activated'}
      </ShowDetailsSection>
      <ShowDetailsSection title="Created At" horizontal={false}>
        <time dateTime={referral.createdAt}>
          {timestampFormatter(referral.createdAt)}
        </time>
      </ShowDetailsSection>
    </RcDl>
  );
}

ReferralShowDetails.propTypes = {
  referral: PropTypes.object.isRequired,
};

export default ReferralShowDetails;
