import { gql } from '@apollo/client';
import { PartnerLocationInfoShared } from '../fragments';

export default gql`
  query partnerLocations($partnerOrganizationId: ID) {
    partnerLocations(partnerOrganizationId: $partnerOrganizationId) {
      ...PartnerLocationInfoShared
    }
  }
  ${PartnerLocationInfoShared}
`;
