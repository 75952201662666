import { gql } from '@apollo/client';
import { PartnerOrganizationInfo } from '../fragments';

export default gql`
  query partnerOrganizations {
    partnerOrganizations {
      ...PartnerOrganizationInfo
    }
  }
  ${PartnerOrganizationInfo}
`;
