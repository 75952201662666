import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function ErrorMessage({ error = null, errorMessages = null }) {
  const { t } = useTranslation('forms');

  return error || errorMessages ? (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="shrink-0">
          <ExclamationCircleIcon
            className="size-5 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-base font-bold text-red-800">
            {t('Something went wrong')}
          </h3>

          <div className="mt-2 text-sm text-red-700">
            {error && <p>{t(error)}</p>}
            {errorMessages &&
              errorMessages.map((message, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <p key={i}>
                  <span className="font-medium capitalize">{message.path}</span>
                  : {message.message}
                </p>
              ))}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errorMessages: PropTypes.array,
};

export default ErrorMessage;
