import { useFormValues } from '@retainerclub/hooks';
import valid from 'card-validator';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { get, useFormContext } from 'react-hook-form';
import FieldContainer from '../FieldContainer/FieldContainer';

const cardBrandImages = [
  {
    name: 'visa',
    src: 'https://cdn.retainerclub.com/images/payment-icons/light/visa.png',
  },
  {
    name: 'mastercard',
    src: 'https://cdn.retainerclub.com/images/payment-icons/light/mastercard.png',
  },
  {
    name: 'american-express',
    src: 'https://cdn.retainerclub.com/images/payment-icons/light/american-express.png',
  },
  {
    name: 'discover',
    src: 'https://cdn.retainerclub.com/images/payment-icons/light/discover.png',
  },
];

function CreditCardNumberInput({
  name,
  brandName,
  label = 'Card Number',
  required = false,
  style = {},
  className = '',
  hideLabel = false,
  disabled = false,
  placeholder = '',
  attributes = {},
}) {
  const [filteredBrands, setFilteredBrand] = useState(cardBrandImages);

  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const values = useFormValues();
  const cardValue = get(values, name);
  const brandValue = get(values, brandName);

  useEffect(() => {
    const validation = valid.number(cardValue);

    if (validation.card) {
      setValue(brandName, validation.card.type);
    } else {
      setValue(brandName, null);
    }
  }, [brandName, cardValue, setValue]);

  useEffect(() => {
    if (brandValue) {
      setFilteredBrand(
        cardBrandImages.filter((item) => item.name === brandValue),
      );
    }

    if (brandValue === '' || brandValue === undefined || brandValue === null) {
      setFilteredBrand(cardBrandImages);
    }
  }, [brandValue]);

  return (
    <FieldContainer
      style={style}
      className={className}
      required={required}
      hideLabel={hideLabel}
      name={name}
      label={label}
      disabled={disabled}
    >
      <div className="relative w-full rounded-md shadow-sm">
        <input
          {...register(name)}
          {...attributes}
          name={name}
          type="tel"
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={19}
          inputMode="numeric"
          autoComplete="cc-number"
          pattern="[0-9\s]{13,19}"
          className={`disabled:bg-rcgray-600 disabled:ring-rcgray-700 disabled:text-rcgray-600 } block w-full rounded-md border-0 py-1.5 pl-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset disabled:cursor-not-allowed  sm:text-sm
        sm:leading-6 ${
          errors && errors[name]
            ? 'text-red-900 ring-red-500 placeholder:text-red-500 focus:ring-red-500'
            : 'focus:ring-rcprimary-400 ring-gray-300 placeholder:text-gray-400'
        }`}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          {filteredBrands.map((item) => (
            <img
              key={item.name}
              src={item.src}
              className="h-7"
              alt={item.name}
            />
          ))}
        </div>
      </div>
    </FieldContainer>
  );
}

CreditCardNumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  attributes: PropTypes.object,
  placeholder: PropTypes.string,
};

export default CreditCardNumberInput;
