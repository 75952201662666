import { TruckIcon, UserIcon, UserPlusIcon } from '@heroicons/react/20/solid';

export const accountNav = [
  {
    name: 'My Profile',
    to: '/profile',
    icon: UserIcon,
    description:
      'Update my contact info, password or make other changes to my account.',
  },
  {
    name: 'My Family',
    to: '/my-family',
    icon: UserPlusIcon,
    description:
      'Add or update family members to access my account or receive treatment.',
  },
  {
    name: 'My Address Book',
    to: '/addresses',
    icon: TruckIcon,
    description:
      'Manage my shipping and billing addresses for timely delivery.',
  },
];

export const shopNav = [
  { name: 'Shop', to: '/shop' },
  { name: 'Shop 2', to: '/shop2' },
  { name: 'Shop 3', to: '/shop3' },
];
