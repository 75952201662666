import { gql } from '@apollo/client';

const PriceBookInfo = gql`
  fragment PriceBookInfo on PriceBook {
    id
    name
    description
    slug
    adminLocationId
    partnerLocations {
      id
      name
      slug
      phone
      email
      website
      address {
        id
        address
        city
        region
      }
      imageUrl
    }
    priceBookProductsAttributes {
      id
      price
      wholesalePrice
      showInStore
      overPrice
      overrideWholesalePrice
      productId
      product {
        id
        name
        internalName
        price
        wholesalePrice
      }
    }
    createdAt
    updatedAt
  }
`;

export default PriceBookInfo;
