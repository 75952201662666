import { gql } from '@apollo/client';
import { ScannerBrandInfo } from '../fragments';

export default gql`
  query scannerBrand($slug: String, $id: ID) {
    scannerBrand(slug: $slug, id: $id) {
      ...ScannerBrandInfo
    }
  }
  ${ScannerBrandInfo}
`;
