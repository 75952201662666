import { gql } from '@apollo/client';

export default gql`
  query legacyUsers {
    legacyUsers {
      id
      name
      email
      tel
      lastLogin
      enabled
      partner {
        id
        name
      }
    }
  }
`;
