function useApp() {
  const app = import.meta.env.VITE_APP_NAME;

  return {
    app,
    isAdmin: app.includes('admin'),
    isPartner: app.includes('partner'),
    isCustomer: app.includes('customer'),
    isLocator: app.includes('locator'),
    isProduction: app.includes('production'),
  };
}

export default useApp;
