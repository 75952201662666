import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { ReferralInfoPublic } from '../fragments';

export default gql`
  mutation updateReferral($input: ReferralUpdateInput!) {
    updateReferral(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      referral {
        ...ReferralInfoPublic
      }
    }
  }
  ${ReferralInfoPublic}
  ${ErrorMessageInfo}
`;
