import { gql } from '@apollo/client';
import { PartnerLocationInfoPublic } from '../fragments';

export default gql`
  query partnerLocation($id: ID!) {
    partnerLocation(id: $id) {
      ...PartnerLocationInfoPublic
    }
  }
  ${PartnerLocationInfoPublic}
`;
