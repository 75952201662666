import { gql } from '@apollo/client';

const PartnerUserInfo = gql`
  fragment PartnerUserInfo on PartnerUser {
    id
    firstName
    lastName
    email
    phone
    language
    roleTypeId
    state
    partnerLocation {
      id
      name
      slug
    }
    partnerLocationId
    partnerOrganizationId
    partnerOrganization {
      id
      name
      slug
    }
    roleType {
      id
      name
    }
    imageUrl
    slug
    lastLoginAt
    createdAt
    updatedAt
  }
`;

export default PartnerUserInfo;
