import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { ScannerBrandInfo } from '../fragments';

export default gql`
  mutation updateScannerBrand($input: ScannerBrandUpdateInput!) {
    updateScannerBrand(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      scannerBrand {
        ...ScannerBrandInfo
      }
    }
  }
  ${ScannerBrandInfo}
  ${ErrorMessageInfo}
`;
