import { gql } from '@apollo/client';
import { StoreInfo } from '../fragments';

export default gql`
  query store($slug: String, $id: ID) {
    store(slug: $slug, id: $id) {
      ...StoreInfo
    }
  }
  ${StoreInfo}
`;
