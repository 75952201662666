import { useQuery } from '@apollo/client';
import { GET_ACCOUNT } from '@retainerclub/customer-api';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from './useAuth';

const AccountContext = createContext();

export function AccountProvider() {
  const { currentUser } = useAuth();
  const { data, error, loading, refetch } = useQuery(GET_ACCOUNT, {
    skip: !currentUser,
  });

  const [currentPatient, setCurrentPatient] = useState(null);

  useEffect(() => {
    const patients = data?.account?.patients || [];

    if (patients.length > 0 && !currentPatient) {
      const sortedPatients = patients.sort((a, b) =>
        a.firstName.localeCompare(b.firstName),
      );
      setCurrentPatient(sortedPatients[0]);
    }
  }, [currentPatient, data?.account?.patients]);

  const value = useMemo(
    () => ({
      account: data?.account,
      accountId: data?.account?.id,
      accountAddresses: data?.account?.accountAddresses,
      partnerLocationId: data?.account?.partnerLocationId,
      users: data?.account?.users || [],
      patients: data?.account?.patients || [],
      orders: data?.account?.orders || [],
      referrals: data?.account?.referrals || [],
      accountRefetch: refetch,
      accountError: error,
      accountLoading: loading,
      currentPatient,
      setCurrentPatient,
    }),
    [currentPatient, data?.account, error, loading, refetch],
  );

  return (
    <AccountContext.Provider value={value}>
      <Outlet />
    </AccountContext.Provider>
  );
}

export function useAccount() {
  return useContext(AccountContext);
}
