import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation actionS3SignUrl($input: SignS3UrlActionInput!) {
    actionSignS3Url(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      signedS3Link {
        url
      }
    }
  }
  ${ErrorMessageInfo}
`;
