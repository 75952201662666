import { useFormContext, useWatch } from 'react-hook-form';

function useFormValues() {
  const { getValues } = useFormContext();

  return {
    ...useWatch(),
    ...getValues(),
  };
}

export default useFormValues;
