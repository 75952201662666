import { gql } from '@apollo/client';
import { OrderInfo } from '../fragments';

export default gql`
  query orders {
    orders {
      ...OrderInfo
    }
  }
  ${OrderInfo}
`;
