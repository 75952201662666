import PropTypes from 'prop-types';
import Card from '../../Card/Card';
import ShowCustomerUsersDetails from './ShowCustomerUsersDetails';

function ShowCustomerUsers({ users = [], className = '', singleUser = null }) {
  return (
    <Card
      className={`h-full p-4 md:mt-0 ${className}`}
      wrapperClassName="h-full"
      heading={
        users.length > 1 || singleUser === null ? 'Customers' : 'Customer'
      }
    >
      <ul className="divide-y divide-gray-100">
        {singleUser && <ShowCustomerUsersDetails user={singleUser} />}
        {users.length > 0 &&
          users.map((user) => (
            <ShowCustomerUsersDetails user={user} key={user.id} />
          ))}
      </ul>
    </Card>
  );
}

ShowCustomerUsers.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array,
  singleUser: PropTypes.object,
};

export default ShowCustomerUsers;
