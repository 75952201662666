import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { OrderInfo } from '../fragments';

export default gql`
  mutation actionOrderStatus($input: OrderStatusActionInput!) {
    actionOrderStatus(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      order {
        ...OrderInfo
      }
    }
  }
  ${OrderInfo}
  ${ErrorMessageInfo}
`;
