import { gql } from '@apollo/client';
import { ErrorMessageInfo, ScanFileInfoShared } from '../fragments';

export default gql`
  mutation createScanFile($input: ScanFileCreateInput!) {
    createScanFile(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      scanFile {
        ...ScanFileInfoShared
      }
    }
  }
  ${ScanFileInfoShared}
  ${ErrorMessageInfo}
`;
