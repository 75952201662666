import { gql } from '@apollo/client';
import { ContactFormInfoShared, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createContactForm($input: ContactFormCreateInput!) {
    createContactForm(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      contactForm {
        ...ContactFormInfoShared
      }
    }
  }
  ${ContactFormInfoShared}
  ${ErrorMessageInfo}
`;
