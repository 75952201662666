import { gql } from '@apollo/client';

const OrderInfo = gql`
  fragment OrderInfo on Order {
    id
    createdAt
    updatedAt
  }
`;

export default OrderInfo;
