import { gql } from '@apollo/client';

const SubscriptionInfoShared = gql`
  fragment SubscriptionInfoShared on Subscription {
    id
    name
    description
    hexCode
    startedAt
    endedAt
    firstPaymentAt
    lastPaymentAt
    renewalAt
    state
    renewalPrice
    initialPrice
    unitsPerInterval
    createdAt
    updatedAt
  }
`;

export default SubscriptionInfoShared;
