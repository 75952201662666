import { gql } from '@apollo/client';

export default gql`
  query productionOrderCountsByState {
    productionOrderCountsByState {
      pending
      predesign
      inDesign
      designQa
      redesign
      atManufacturer
      manufacturing
      shippedToHq
      receivedAtHq
      holdAtHq
      collectingShipment
      shippedToRecipient
      deliveredToRecipient
      returnedToHq
    }
  }
`;
