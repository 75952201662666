/* eslint-disable camelcase */
import { Combobox } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { FaceFrownIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { LocationContext } from '../../../../../shared/Context';
import usePlacesAutocomplete from './usePlacesAutocomplete/usePlacesAutocomplete';
import { getGeocode, getLatLng } from './usePlacesAutocomplete/utils';

function SearchLocationInput({ setOpen }) {
  const { t } = useTranslation('navigation');
  const { setLocationData } = useContext(LocationContext);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['rcGeo']);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  function handleInput(v) {
    setValue(v);
  }

  function handleSelect({ description = null }) {
    if (description !== null && description !== undefined) {
      setValue(description, false);
      clearSuggestions();

      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then((locData) => {
          setCookie('rcGeo', locData, {
            path: '/',
            maxAge: 3600 * 24 * 365,
          });
          setLocationData(locData);
          setValue('');
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(`😱 Error: ${error}`);
        });

      setOpen(false);
    }
  }

  return (
    <Combobox onChange={(item) => handleSelect(item)}>
      <div className="relative mt-4">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>

        <Combobox.Input
          className="focus:ring-rcprimary-400 block w-full rounded-md border-0 py-1.5 pl-10 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm
          sm:leading-6"
          placeholder={`${t('Search')}...`}
          onChange={(event) => handleInput(event.target.value)}
          aria-disabled={!ready}
        />
      </div>

      {value === '' && (
        <div className="h-96 border-t border-white/20 px-6 py-14 text-center text-sm sm:px-14">
          <p className="text-rcgray-700 mt-4 font-semibold">&nbsp;</p>
        </div>
      )}

      {status === 'OK' && (
        <Combobox.Options
          static
          className="mt-1 h-96 max-h-96 scroll-py-3 overflow-y-auto rounded-lg border border-black/20"
        >
          {data.map((suggestion) => {
            const {
              place_id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
              <Combobox.Option
                key={place_id}
                value={suggestion}
                className={({ active }) =>
                  `flex cursor-pointer select-none py-3 ${
                    active && 'bg-black/10 text-black'
                  }`
                }
              >
                {({ active }) => (
                  <div className="ml-4 flex-auto">
                    <p
                      className={`text-sm font-medium ${
                        active ? 'text-rcprimary-400' : 'text-black'
                      }`}
                    >
                      {main_text}
                    </p>
                    <p
                      className={`text-sm ${
                        active ? 'text-rcprimary-400' : 'text-black'
                      }`}
                    >
                      {secondary_text}
                    </p>
                  </div>
                )}
              </Combobox.Option>
            );
          })}
        </Combobox.Options>
      )}

      {value !== '' && data.length === 0 && (
        <div className="h-96 border-t border-white/20 px-6 py-14 text-center text-sm sm:px-14">
          <FaceFrownIcon
            className="mx-auto h-6 w-6 text-black/60"
            aria-hidden="true"
          />
          <p className="mt-4 font-semibold text-black">
            {t('No results found')}
          </p>
          <p className="text-black-70 mt-2">
            {t("We couldn't locate you with that search. Please try again.")}
          </p>
        </div>
      )}
    </Combobox>
  );
}

SearchLocationInput.propTypes = {
  setOpen: PropTypes.func.isRequired,
};

export default SearchLocationInput;
