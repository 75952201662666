import { gql } from '@apollo/client';

const AccountInfoShared = gql`
  fragment AccountInfoShared on Account {
    id
    accountNumber
    slug
    active
    users {
      id
      slug
      firstName
      lastName
      email
      phone
    }
    accountAddresses {
      id
      address
      address2
      city
      region
      postalCode
      country
      isBilling
      isShipping
      isDefault
      accountId
    }
    patients {
      id
      firstName
      lastName
      slug
      arches
      dob
      birthDay
      birthMonth
      birthYear
      settingGroupOptionIds
      settingsOverride
      notes
      includeEot
      scanReady
      scanReadyAt
      scanSentAt
      sendToClinic
    }
    referrals {
      id
      slug
      hexCode
      state
      reminders
      consumedAt
      patientAttributes {
        id
        includeEot
      }
      source
      sent
      sentAt
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
`;

export default AccountInfoShared;
