import L from 'leaflet';

const Svg = `<svg width="37" height="48" xmlns="http://www.w3.org/2000/svg"><path d="M19.323 47.1a1.38 1.38 0 01-1.766 0C6.23 37.662.5 28.11.5 18.44.5 8.532 8.532.5 18.44.5c9.908 0 17.94 8.032 17.94 17.94 0 9.67-5.73 19.222-17.057 28.66zm2.92-24.062c-1.929 2.15-4.987 2.15-6.916 0a1.38 1.38 0 00-2.054 1.844c3.025 3.37 7.999 3.37 11.024 0a1.38 1.38 0 00-2.054-1.844zM13.61 18.44a2.07 2.07 0 100-4.14 2.07 2.07 0 000 4.14zm9.66 0a2.07 2.07 0 100-4.14 2.07 2.07 0 000 4.14z" fill="#37b87b"/></svg>`;

const iconUrl = `data:image/svg+xml,${encodeURIComponent(Svg)}`;

const MarkerSvg = L.icon({
  iconUrl,
  iconSize: [37, 48],
  shadowSize: [20, 30],
  iconAnchor: [18, 24],
  shadowAnchor: [4, 62],
  popupAnchor: [0, -26],
});

export default MarkerSvg;
