function initialValues(props) {
  const { record = {} } = props;

  return {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    message: '',
    postalCode: null,
    partnerLocationId: record?.id || null,
    partnerOrganizationId: record?.partnerorganizationid || null,
    adminLocationId: record?.adminlocationid || null,
    contactFormType: 'scan_request',
  };
}

export default initialValues;
