import { gql } from '@apollo/client';
import { AdminUserInfo } from '../fragments';

export default gql`
  query adminUsers {
    adminUsers {
      ...AdminUserInfo
    }
  }
  ${AdminUserInfo}
`;
