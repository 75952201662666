import { gql } from '@apollo/client';
import { ProductRenewalIntervalInfo } from '../fragments';

export default gql`
  query productRenewalInterval($slug: String, $id: ID) {
    productRenewalInterval(slug: $slug, id: $id) {
      ...ProductRenewalIntervalInfo
    }
  }
  ${ProductRenewalIntervalInfo}
`;
