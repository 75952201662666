import { gql } from '@apollo/client';

const UserInfoShared = gql`
  fragment UserInfoShared on User {
    id
    firstName
    lastName
    email
    phone
    imageUrl
    slug
    state
    withoutPlan
    partnerLocation {
      id
      name
      slug
    }
    account {
      id
      accountNumber
      slug
      patients {
        id
        firstName
        lastName
        slug
        arches
        dob
        birthDay
        birthMonth
        birthYear
      }
      accountAddresses {
        id
        address
        address2
        city
        region
        postalCode
        country
        isBilling
        isShipping
        isDefault
        accountId
      }
    }
    referrals {
      id
      hexCode
      slug
      state
      source
      patientAttributes {
        id
        includeEot
        sendToClinic
      }
      reminders
      consumedAt
      sent
      sentAt
      createdAt
    }
    lastLoginAt
    createdAt
    updatedAt
  }
`;

export default UserInfoShared;
