import { gql } from '@apollo/client';
import { PartnerLocationInfoPartner } from '../fragments';

export default gql`
  query partnerLocation($slug: String, $id: ID) {
    partnerLocation(slug: $slug, id: $id) {
      ...PartnerLocationInfoPartner
    }
  }
  ${PartnerLocationInfoPartner}
`;
