import PropTypes from 'prop-types';
import Card from '../../Card/Card';
import ShowPatientDetails from './ShowPatientDetails';

function ShowPatient({
  patient = null,
  className = '',
  patients = null,
  heading = 'Patient',
}) {
  return (
    <Card
      className={`mt-4 h-full p-4 md:mt-0 ${className}`}
      wrapperClassName="h-full"
      heading={heading}
    >
      <ul className="divide-y divide-gray-100">
        {patient && <ShowPatientDetails patient={patient} />}
        {patients &&
          patients.map((p) => <ShowPatientDetails key={p.id} patient={p} />)}
      </ul>
    </Card>
  );
}

ShowPatient.propTypes = {
  patient: PropTypes.object,
  patients: PropTypes.array,
  className: PropTypes.string,
  heading: PropTypes.string,
};

export default ShowPatient;
