import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';
import { NavLink } from 'react-router-dom';
import RcLink from '../../RcLink/RcLink';
import ShowStatus from '../ShowStatus/ShowStatus';

const NOT_AVAILABLE = 'Not Available';

function ShowCustomerUsersDetails({ user }) {
  return (
    <li
      key={user.id}
      className="flex items-center justify-between gap-x-6 py-4"
    >
      <div className="min-w-0">
        <div className="flex items-start gap-x-3">
          <p className="text-sm font-semibold leading-6 text-black">
            {user.firstName} {user.lastName}
          </p>
          <ShowStatus state={user.state} />
        </div>
        <div className="mt-1 flex flex-col items-start gap-x-2 text-xs leading-5 text-black/70">
          <p className="whitespace-nowrap">
            Email:{' '}
            {user.email ? (
              <RcLink
                href={`mailto: ${user.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {user.email}
              </RcLink>
            ) : (
              NOT_AVAILABLE
            )}
          </p>
          <p className="whitespace-nowrap">
            Phone:{' '}
            {user.phone ? (
              <RcLink
                href={`tel: ${user.phone}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatPhoneNumber(user.phone)}
              </RcLink>
            ) : (
              NOT_AVAILABLE
            )}
          </p>
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        <Menu as="div" className="relative flex-none">
          <Menu.Button className="-m-2.5 block p-2.5 text-black/70 hover:text-black">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-black py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              <Menu.Item>
                <NavLink
                  to={`/customers/users/${user?.slug}`}
                  className="block px-3 py-1 text-sm leading-6 text-white"
                >
                  View
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  to={`/customers/users/${user?.slug}/edit`}
                  className="block px-3 py-1 text-sm leading-6 text-white"
                >
                  Edit
                </NavLink>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </li>
  );
}

ShowCustomerUsersDetails.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ShowCustomerUsersDetails;
