function initialValues() {
  return {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    message: null,
    postalCode: null,
    contactFormType: 'no_office_found',
  };
}

export default initialValues;
