import {
  CheckCircleIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import { timestampFormatter } from '../../transforms';
import RcLink from '../RcLink/RcLink';

function ActivityFeed({ activity }) {
  const app = import.meta.env.VITE_APP_NAME;

  function getSubjectLink(slug, subject) {
    if (app.includes('admin') && subject === 'customer') {
      return `/customers/users/${slug}`;
    }

    if (app.includes('admin') && subject === 'referral') {
      return `/customers/referrals/${slug}`;
    }

    if (app.includes('admin') && subject === 'account') {
      return `/customers/accounts/${slug}`;
    }

    return '';
  }

  function getUserLink(slug, type) {
    if (app.includes('admin') && type === 'User') {
      return `/customers/users/${slug}`;
    }

    if (app.includes('admin') && type === 'AdminUser') {
      return `/admins/users/${slug}`;
    }

    if (app.includes('admin') && type === 'PartnerUser') {
      return `/partners/users/${slug}`;
    }

    return '';
  }

  function getIcon(eventVerb) {
    if (eventVerb === 'created') {
      return (
        <CheckCircleIcon className="size-6 text-black" aria-hidden="true" />
      );
    }

    if (eventVerb === 'updated') {
      return (
        <PencilSquareIcon className="size-6 text-black" aria-hidden="true" />
      );
    }

    if (eventVerb === 'deleted') {
      return <TrashIcon className="size-6 text-black" aria-hidden="true" />;
    }

    return <div className="size-1.5 rounded-full bg-black ring-1 ring-black" />;
  }

  return (
    <ul className="mt-6 space-y-6">
      {[...activity]
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .map((activityItem, activityItemIdx) => (
          <li key={activityItem.id} className="relative flex gap-x-4">
            <div
              className={`
                ${activityItemIdx === activity.length - 1 ? 'h-6' : '-bottom-6'}
                absolute left-0 top-0 flex w-6 justify-center
              `}
            >
              <div className="w-px bg-black/60" />
            </div>

            <div className="bg-rcprimary-400 relative flex size-6 flex-none items-center justify-center">
              {getIcon(activityItem.eventVerb)}
            </div>
            <p className="flex-auto py-0.5 text-xs leading-5 text-black/80">
              <RcLink
                to={getUserLink(
                  activityItem.user.slug,
                  activityItem.user.userKlass,
                )}
                className="text-xs font-bold"
              >
                {activityItem?.user?.name}
              </RcLink>{' '}
              {app.includes('admin') && activityItem?.user?.memberOf?.name && (
                <span>({activityItem?.user?.memberOf?.name})</span>
              )}{' '}
              <span className="italic">{activityItem.eventVerb}</span>{' '}
              <span className="font-bold text-black">
                {activityItem.eventSubject}
              </span>{' '}
              <RcLink
                to={getSubjectLink(
                  activityItem.affectedObject.slug,
                  activityItem.eventSubject,
                )}
                textSize="text-xs"
              >
                {activityItem.affectedObject.name}
              </RcLink>
              .
            </p>
            <time
              dateTime={activityItem.createdAt}
              className="flex-none py-0.5 text-xs leading-5 text-black/60"
            >
              {timestampFormatter(activityItem.createdAt)}
            </time>
          </li>
        ))}
    </ul>
  );
}

ActivityFeed.propTypes = {
  activity: PropTypes.array.isRequired,
};

export default ActivityFeed;
