/* eslint-disable no-console */
import { useApolloClient, useMutation } from '@apollo/client';
import { UPDATE_CURRENT_USER, UPDATE_PASSWORD } from '@retainerclub/shared-api';
import {
  confirmResetPassword,
  fetchUserAttributes,
  getCurrentUser,
  resetPassword,
  signIn,
  signOut,
} from 'aws-amplify/auth';
import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const AuthContext = createContext();

const CUSTOM_LANGUAGE = 'custom:language';
const CUSTOM_IMAGE = 'custom:imageUrl';

export function AuthProvider(props) {
  const { children } = props;
  const [authLoading, setAuthLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [tempCredentials, setTempCredentials] = useState({});
  const [error, setError] = useState();
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const app = import.meta.env.VITE_APP_NAME;

  const [update] = useMutation(UPDATE_CURRENT_USER);
  const [updatePassword] = useMutation(UPDATE_PASSWORD);

  const updateCurrentUser = useCallback(async (bypassCache = false) => {
    try {
      const user = await fetchUserAttributes({
        bypassCache,
      });

      const userSecondData = await getCurrentUser();

      const navName = `${user.name.charAt().toUpperCase()}. ${
        user.family_name
      }`;

      const roleLevel = parseInt(user['custom:roleLevel'], 10);

      const newCurrentUser = {
        username: userSecondData.username,
        navName,
        roleLevel,
        firstName: user.name,
        lastName: user.family_name,
        email: user.email,
        emailVerified: user.email_verified,
        phone: user.phone_number,
        phoneVerified: user.phone_number_verified,
        language: user[CUSTOM_LANGUAGE],
        imageUrl: user[CUSTOM_IMAGE],
      };

      setCurrentUser(newCurrentUser);

      const defaultLanguage = window.localStorage.getItem('defaultLanguage');

      i18n.changeLanguage(user[CUSTOM_LANGUAGE]);

      if (defaultLanguage !== user[CUSTOM_LANGUAGE]) {
        navigate('/profile');
      }
      setAuthLoading(false);
    } catch (err) {
      // console.error(err);
      setAuthLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = useCallback(
    async (values) => {
      const { email, phone, password, skipNavigate } = values;
      const from = location.state?.from?.pathname || '/';

      let username = email;

      if (app.includes('customer')) {
        username = phone;
      }

      try {
        const { nextStep } = await signIn({ username, password });

        if (
          nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
        ) {
          setError(null);
          setTempCredentials({ email, phone });
          navigate('/new-password');
        } else {
          setError(null);
          updateCurrentUser();

          const params = {
            variables: {
              input: {
                clientMutationId: uuidv4(),
              },
            },
          };

          await update(params);

          if (!skipNavigate) {
            navigate(from, { replace: true });
          }
        }
      } catch (err) {
        console.error('err', err);
        setError(err.message);
      }
    },
    [app, location.state?.from?.pathname, navigate, update, updateCurrentUser],
  );

  const newPassword = useCallback(
    async (values) => {
      const { password } = values;

      let klass = 'AdminUser';

      if (app.includes('customer')) {
        klass = 'User';
      }

      if (app.includes('partner')) {
        klass = 'PartnerUser';
      }

      if (app.includes('production')) {
        klass = 'ProductionUser';
      }

      try {
        const params = {
          variables: {
            input: {
              clientMutationId: uuidv4(),
              input: {
                password,
                klass,
                email: tempCredentials.email,
                phone: tempCredentials.phone,
              },
            },
          },
        };

        await updatePassword(params);

        setError(null);
        navigate('/');
      } catch (err) {
        console.error('err', err);
        setError(err.message);
      }
    },
    [
      app,
      navigate,
      tempCredentials.email,
      tempCredentials.phone,
      updatePassword,
    ],
  );

  const forgot = useCallback(
    async (values) => {
      const { email, phone } = values;

      let username = email;

      if (app.includes('customer')) {
        username = phone;
      }

      try {
        await resetPassword({ username });
        setError(null);
        setTempCredentials({ email, phone });
        navigate('/reset-password');
      } catch (err) {
        console.error('err', err);
        setError(err.message);
      }
    },
    [app, navigate],
  );

  const reset = useCallback(
    async (values) => {
      const { email, phone, token, password } = values;

      let username = email;

      if (app.includes('customer')) {
        username = phone;
      }

      try {
        await confirmResetPassword({
          username,
          confirmationCode: token,
          newPassword: password,
        });

        setError(null);
        setTempCredentials({});
        navigate('/signin');
      } catch (err) {
        console.error('err', err);
        setError(err.message);
      }
    },
    [app, navigate],
  );

  const signOutUser = useCallback(async () => {
    try {
      const defaultLanguage = window.localStorage.getItem('defaultLanguage');

      await signOut();
      client.resetStore();

      window.localStorage.removeItem('apollo-cache-persist');
      window.localStorage.setItem('defaultLanguage', defaultLanguage || 'en');

      setCurrentUser(null);
    } catch (err) {
      console.error(err);
    }
  }, [client]);

  useEffect(() => {
    if (currentUser === null) {
      updateCurrentUser();
    }
  }, [currentUser, updateCurrentUser]);

  const value = useMemo(
    () => ({
      error,
      login,
      signOut: signOutUser,
      forgot,
      reset,
      tempCredentials,
      newPassword,
      currentUser,
      updateCurrentUser,
      authLoading,
    }),
    [
      currentUser,
      error,
      forgot,
      login,
      newPassword,
      reset,
      signOutUser,
      tempCredentials,
      updateCurrentUser,
      authLoading,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAuth() {
  return useContext(AuthContext);
}
