import { gql } from '@apollo/client';

const CartTransactionInfoPublic = gql`
  fragment CartTransactionInfoPublic on CartTransaction {
    id
    state
    discountAmount
    taxAmount
    shippingAmount
    total
    authcode
    avs
    cvv
    transactionId
    message
    status
    code
    amountApproved
  }
`;

export default CartTransactionInfoPublic;
