import * as Yup from 'yup';

function schema() {
  return Yup.object().shape({
    firstName: Yup.string().required('first name is required'),
    lastName: Yup.string().required('last name is required'),
    email: Yup.string().email().required('email is required'),
    phone: Yup.string().required('phone is required'),
    message: Yup.string().nullable(true),
    postalCode: Yup.string().required('postal code is required'),
    partnerLocationId: Yup.string().nullable(true),
    partnerOrganizationId: Yup.string().nullable(true),
    adminLocationId: Yup.string().nullable(true),
    contactFormType: Yup.string().required('contact form type is required'),
  });
}

export default schema;
