import { gql } from '@apollo/client';
import { ProductionOrderInfo } from '../fragments';

export default gql`
  query productionOrders($status: String) {
    productionOrders(status: $status) {
      ...ProductionOrderInfo
    }
  }
  ${ProductionOrderInfo}
`;
