import { gql } from '@apollo/client';
import { AccountInfoShared } from '@retainerclub/shared-api';

export default gql`
  query accounts {
    accounts {
      ...AccountInfoShared
    }
  }
  ${AccountInfoShared}
`;
