import PropTypes from 'prop-types';

function RcDl({ children, className = '', gridCols = '', dividers = true }) {
  return (
    <dl
      className={`grid grid-cols-1 gap-x-4 ${
        dividers && 'divide-y divide-black/10'
      } ${className} ${gridCols}`}
    >
      {children}
    </dl>
  );
}

RcDl.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  className: PropTypes.string,
  gridCols: PropTypes.string,
  dividers: PropTypes.bool,
};

export default RcDl;
