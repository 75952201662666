import {
  AuthProvider,
  LocaleProvider,
  PolicyProvider,
  ToastProvider,
} from '@retainerclub/hooks';
import { Outlet } from 'react-router-dom';
import { Toast } from '../shared';
import { LegalModal } from '../views/Legal';

function AppInner() {
  return (
    <AuthProvider>
      <LocaleProvider>
        <ToastProvider>
          <PolicyProvider>
            <Outlet />
            <Toast />
            <LegalModal />
          </PolicyProvider>
        </ToastProvider>
      </LocaleProvider>
    </AuthProvider>
  );
}

export default AppInner;
