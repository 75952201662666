import PropTypes from 'prop-types';

function CustomerCard({
  children,
  className = '',
  title = null,
  description = null,
  wrapperClassName = '',
  action = null,
}) {
  return (
    <section
      aria-labelledby="applicant-information-title"
      className={wrapperClassName}
    >
      <div
        className={`bg-rcgray-50 p-4 md:rounded-lg md:border md:shadow-lg ${className}`}
      >
        {title && (
          <div className="mb-4 flex flex-wrap items-center justify-between border-b border-black/20 pb-2 sm:flex-nowrap">
            <div>
              <h2 className=" text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                {title}
              </h2>
              {description && (
                <p className="text-base text-black/60">{description}</p>
              )}
            </div>
            {action && <div>{action}</div>}
          </div>
        )}

        {children}
      </div>
    </section>
  );
}

CustomerCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  wrapperClassName: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  action: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
};

export default CustomerCard;
