import { gql } from '@apollo/client';
import { AccountAddressInfoShared, ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createAccountAddress($input: AccountAddressCreateInput!) {
    createAccountAddress(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      accountAddress {
        ...AccountAddressInfoShared
      }
    }
  }
  ${AccountAddressInfoShared}
  ${ErrorMessageInfo}
`;
