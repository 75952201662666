import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { ManufacturerInfo } from '../fragments';

export default gql`
  mutation createManufacturer($input: ManufacturerCreateInput!) {
    createManufacturer(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      manufacturer {
        ...ManufacturerInfo
      }
    }
  }
  ${ManufacturerInfo}
  ${ErrorMessageInfo}
`;
