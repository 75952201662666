import { gql } from '@apollo/client';

const ScanFileInfoShared = gql`
  fragment ScanFileInfoShared on ScanFile {
    id
    fileUrl
    fileType
    arches
    originalFilename
    accountId
    patientId
    createdAt
    updatedAt
  }
`;

export default ScanFileInfoShared;
