/* eslint-disable no-unused-vars */
import _debounce from 'lodash/debounce';
import { useCallback, useEffect, useRef, useState } from 'react';
import useLatest from './useLatest';

export const loadApiErr =
  '> 💡use-places-autocomplete: Google Maps Places API library must be loaded.';

function usePlacesAutocomplete({
  requestOptions,
  debounce = 200,
  googleMaps,
  callbackName,
  defaultValue = '',
}) {
  const [ready, setReady] = useState(false);
  const [value, setVal] = useState('');
  const [suggestions, setSuggestions] = useState({
    loading: false,
    status: '',
    data: [],
  });
  const asRef = useRef(null);
  const requestOptionsRef = useLatest(requestOptions);
  const googleMapsRef = useLatest(googleMaps);

  const init = useCallback(() => {
    const { google } = window;
    const { current: gMaps } = googleMapsRef;
    const placesLib = gMaps?.places || google?.maps?.places;

    if (!placesLib) {
      // eslint-disable-next-line no-console
      console.error(loadApiErr);
      return;
    }

    asRef.current = new placesLib.AutocompleteService();
    setReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearSuggestions = useCallback(() => {
    setSuggestions({ loading: false, status: '', data: [] });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchPredictions = useCallback(
    _debounce((val) => {
      if (!val) {
        clearSuggestions();
        return;
      }
      // To keep the previous suggestions
      setSuggestions((prevState) => ({ ...prevState, loading: true }));
      asRef.current.getPlacePredictions(
        { ...requestOptionsRef.current, input: val },
        (data, status) => {
          setSuggestions({ loading: false, status, data: data || [] });
        },
      );
    }, debounce),
    [debounce, clearSuggestions],
  );

  const setValue = useCallback(
    (val, shouldFetchData = true) => {
      setVal(val);
      if (shouldFetchData) fetchPredictions(val);
    },
    [fetchPredictions],
  );

  useEffect(() => {
    const { google } = window;

    if (!googleMapsRef.current && !google?.maps && callbackName) {
      window[callbackName] = init;
    } else {
      init();
    }

    return () => {
      if (window[callbackName]) delete window[callbackName];
    };
  }, [callbackName, googleMapsRef, init]);

  return { ready, value, suggestions, setValue, clearSuggestions };
}

export default usePlacesAutocomplete;
