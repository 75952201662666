import { gql } from '@apollo/client';

const CartInfoPublic = gql`
  fragment CartInfoPublic on Cart {
    id
    state
    subtotal
    discountAmount
    taxAmount
    shippingAmount
    total
    currency
    hexCode
    cartItemsAttributes {
      id
      name
      price
      discountAmount
      taxAmount
      shippingAmount
      total
      quantity
      patientId
      patient {
        id
        firstName
        lastName
      }
      productId
      product {
        id
        featuredImage
        description
        productFeatures {
          id
          text
        }
        planSubscription {
          id
          name
          featuredImage
          description
          productFeatures {
            id
            text
          }
        }
        planProducts {
          id
          price
          overridePrice
          assignedProduct {
            id
            name
            featuredImage
            price
            description
            productFeatures {
              id
              text
            }
          }
        }
      }
    }
  }
`;

export default CartInfoPublic;
