/* eslint-disable react/jsx-no-bind */
import { useQuery } from '@apollo/client';
import { useMediaQuery } from '@retainerclub/hooks';
import { GET_PARTNER_MAP } from '@retainerclub/shared-api';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  ListFilterProvider,
  LocationContext,
  MapViewProvider,
} from '../../shared/Context';
import calcDistance from '../../transforms/calcDistance';
import LocatorMap from './LocatorMap/LocatorMap';
import LocatorMapSidebar from './LocatorMapSidebar/LocatorMapSidebar';
import NoResultsForm from './NoResultsForm/NoResultsForm';
import RequestScanForm from './RequestScanForm/RequestScanForm';

function Locator() {
  const markersRef = useRef([]);
  const { locationData } = useContext(LocationContext);

  const isMobile = useMediaQuery('(max-width: 768px)');

  const [requestScanOpen, setRequestScanOpen] = useState(false);
  const [noResultsOpen, setNoResultsOpen] = useState(false);
  const [boundingBox, setBoundingBox] = useState([]);
  const [center, setCenter] = useState([]);
  const [totalObjects, setTotalObjects] = useState(0);
  const [results, setResults] = useState([]);
  const [currentPartner, setCurrentPartner] = useState(null);
  const [filters, setFilters] = useState({
    search: '',
    radius: 100.0,
    offset: 0,
  });

  const { search, radius, offset } = filters;

  const setVariables = useCallback(
    (variables) => {
      let vars = {
        ...variables,
        coordinates: [
          locationData.lat ? locationData.lat : 39.7392,
          locationData.lng ? locationData.lng : -104.9903,
        ],
        radius,
      };

      if (!isMobile) {
        vars = { ...vars, boundingBox };
      }

      if (search && search !== '') {
        vars = { search, ...vars };
      }

      return vars;
    },
    [boundingBox, isMobile, locationData.lat, locationData.lng, radius, search],
  );

  const { data, error, loading, networkStatus, refetch } = useQuery(
    GET_PARTNER_MAP,
    {
      variables: setVariables({ offset }),
      notifyOnNetworkStatusChange: true,
      skip: boundingBox.length < 1,
    },
  );

  useEffect(() => {
    if (data?.partnerMap?.results) {
      let adjustedResults = data.partnerMap.results;

      adjustedResults = adjustedResults.map((rad) => ({
        ...rad,
        distance: calcDistance(
          [rad.attributes.coordinates.lon, rad.attributes.coordinates.lat],
          [
            locationData.lng ? locationData.lng : 0,
            locationData.lat ? locationData.lat : 0,
          ],
        ),
      }));

      setResults(adjustedResults);

      if (data.partnerMap.radius) {
        setFilters((fil) => ({ ...fil, radius: data.partnerMap.radius }));
      }

      setTotalObjects(data.partnerMap.totalCount);
    }
  }, [
    data?.partnerMap?.radius,
    data?.partnerMap?.results,
    data?.partnerMap?.totalCount,
    locationData.lat,
    locationData.lng,
  ]);

  function resetFilters() {
    refetch();
    setFilters((fil) => ({
      ...fil,
      search: '',
      offset: 0,
      radius: 10.0,
    }));
  }

  function listItemClick(index) {
    if (markersRef.current && markersRef.current[index]) {
      markersRef.current[index].openPopup();
    }
  }

  return (
    <ListFilterProvider
      value={{
        setVariables,
        filters,
        setFilters,
      }}
    >
      <MapViewProvider
        value={{
          results,
          markersRef,
          boundingBox,
          center,
          setCenter,
          setBoundingBox,
          setCurrentPartner,
          setRequestScanOpen,
        }}
      >
        <RequestScanForm
          open={requestScanOpen}
          setOpen={setRequestScanOpen}
          currentPartner={currentPartner}
          setCurrentPartner={setCurrentPartner}
        />
        <NoResultsForm open={noResultsOpen} setOpen={setNoResultsOpen} />
        <div className="flex h-full flex-row">
          <aside className="flex h-[calc(100vh-67px)] w-full flex-none flex-col overflow-hidden border-r border-black/10 bg-white/60 md:w-[28rem]">
            <LocatorMapSidebar
              resetFilters={resetFilters}
              results={results}
              loading={loading}
              error={error}
              networkStatus={networkStatus}
              listItemClick={listItemClick}
              totalObjects={totalObjects}
              setNoResultsOpen={setNoResultsOpen}
            />
          </aside>

          <main className="hidden h-[calc(100vh-67px)] w-full md:block">
            <LocatorMap />
          </main>
        </div>
      </MapViewProvider>
    </ListFilterProvider>
  );
}

export default Locator;
