import {
  NavigationDesktopDropdown,
  NavigationDesktopLink,
} from '@retainerclub/common';
import { useApp } from '@retainerclub/hooks';
import { NavLink } from 'react-router-dom';
import { accountNav } from './helpers/navLinks';

function DesktopNavAuthed() {
  const { isCustomer } = useApp();

  return isCustomer ? (
    <div className="hidden lg:flex lg:gap-x-12">
      <NavigationDesktopLink to="/" title="Dashboard" />
      <NavigationDesktopLink to="/shop" title="Shop" />
      <NavigationDesktopLink to="/my-orders" title="My Orders" />
      <NavigationDesktopDropdown menuItems={accountNav} title="My Account" />
    </div>
  ) : (
    <div className="hidden lg:flex lg:gap-x-12">
      <NavLink
        to="/"
        className="hover:text-rcprimary-400 text-sm font-normal leading-6 text-gray-900 hover:underline"
      >
        Home
      </NavLink>
      <a
        href="https://locator.retainerclub.com"
        className="hover:text-rcprimary-400 text-sm font-normal leading-6 text-gray-900 hover:underline"
      >
        Doctor Locator
      </a>
      <a
        href="https://v2.retainerclub.com/why-replace-retainers"
        className="hover:text-rcprimary-400 text-sm font-normal leading-6 text-gray-900 hover:underline"
      >
        Why Replace Retainers?
      </a>
      <a
        href="https://v2.retainerclub.com/support"
        className="hover:text-rcprimary-400 text-sm font-normal leading-6 text-gray-900 hover:underline"
      >
        Support
      </a>
    </div>
  );
}

export default DesktopNavAuthed;
