import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Locize from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';
import detector from './lngDetect';

const languageDetector = new LanguageDetector();
languageDetector.addDetector(detector);

i18next
  .use(Locize)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    ns: ['forms', 'navigation', 'signin', 'ui'],
    defaultNS: 'signin',
    debug: false,
    fallbackLng: 'en',
    nonExplicitWhitelist: true,
    whitelist: ['en', 'de', 'es', 'fr', 'it', 'jp'],
    saveMissing: import.meta.env.VITE_APP_ENV === 'development',
    version:
      import.meta.env.VITE_APP_ENV === 'development'
        ? 'latest'
        : import.meta.env.VITE_APP_ENV,
    keySeparator: false,
    backend: {
      projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
      apiKey: import.meta.env.VITE_LOCIZE_API_KEY,
      referenceLng: 'en',
      version:
        import.meta.env.VITE_APP_ENV === 'development'
          ? 'latest'
          : import.meta.env.VITE_APP_ENV,
      allowedAddOrUpdateHosts: ['localhost'],
    },
    detection: {
      order: ['detector', 'localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupLocalStorage: 'defaultLanguage',
    },
    react: {
      useSuspense: true,
    },
  });

export default i18next;
