import { gql } from '@apollo/client';

const PriceBookProductInfoShared = gql`
  fragment PriceBookProductInfoShared on PriceBookProduct {
    id
    price
    wholesalePrice
    showInStore
    overPrice
    overrideWholesalePrice
    productId
    product {
      id
      name
      arches
      wholesalePrice
      isRetainer
      isEot
      description
      defaultSubscriptionRetainer
      featuredImage
      productType
      productFeatures {
        id
        text
      }
    }
  }
`;

export default PriceBookProductInfoShared;
