import { gql } from '@apollo/client';

const ManufacturerInfo = gql`
  fragment ManufacturerInfo on Manufacturer {
    id
    name
    active
    slug
    imageUrl
    address {
      id
      address
      address2
      city
      region
      postalCode
      country
      latitude
      longitude
    }
  }
`;

export default ManufacturerInfo;
