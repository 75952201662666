import { gql } from '@apollo/client';
import { UserInfoShared } from '../fragments';

export default gql`
  query users(
    $partnerOrganizationId: ID
    $partnerLocationId: ID
    $accountId: ID
  ) {
    users(
      partnerOrganizationId: $partnerOrganizationId
      partnerLocationId: $partnerLocationId
      accountId: $accountId
    ) {
      ...UserInfoShared
    }
  }
  ${UserInfoShared}
`;
