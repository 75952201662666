import { gql } from '@apollo/client';

const OrderInfoShared = gql`
  fragment OrderInfoShared on Order {
    id
    state
    paymentState
    paidByPartner
    hexCode
    slug
    total
    discountAmount
    taxAmount
    shippingAmount
    toplineRevenue
    earnedRevenue
    partnerRevenueOffice
    partnerRevenuePlatform
    partnerRevenueTotal
    partnerFee
    deliveryMethod
    account {
      id
      accountNumber
    }
    referral {
      id
      hexCode
      slug
      source
      sentAt
      consumedAt
      createdAt
    }
    user {
      id
      firstName
      lastName
      email
      state
      phone
      slug
    }
    orderItems {
      id
      name
      price
      quantity
      taxAmount
      shippingAmount
      discountAmount
      total
      product {
        id
        name
        slug
        featuredImage
      }
      patient {
        id
        firstName
        lastName
        slug
        arches
        dob
        birthDay
        birthMonth
        birthYear
        scanReady
        scanReadyAt
      }
    }
    partnerLocation {
      id
      slug
      name
      address {
        id
        city
        region
      }
    }
    productionOrders {
      id
      productionOrderItems {
        id
        trackingNumber
        shippingCarrier {
          id
          name
        }
      }
    }
    createdAt
    updatedAt
  }
`;

export default OrderInfoShared;
