import { gql } from '@apollo/client';
import { PartnerLocationInfoShared } from '../fragments';

export default gql`
  query partnerLocation($slug: String, $id: ID) {
    partnerLocation(slug: $slug, id: $id) {
      ...PartnerLocationInfoShared
    }
  }
  ${PartnerLocationInfoShared}
`;
