import { gql } from '@apollo/client';
import { PatientInfoShared } from '../fragments';

export default gql`
  query patient($slug: String, $id: ID) {
    patient(slug: $slug, id: $id) {
      ...PatientInfoShared
    }
  }
  ${PatientInfoShared}
`;
