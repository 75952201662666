import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { CartItemInfoPublic } from '../fragments';

export default gql`
  mutation updateCartItem($input: CartItemUpdateInput!) {
    updateCartItem(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      cartItem {
        ...CartItemInfoPublic
      }
    }
  }
  ${CartItemInfoPublic}
  ${ErrorMessageInfo}
`;
