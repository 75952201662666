import { gql } from '@apollo/client';
import { OrderInfoShared } from '../fragments';

export default gql`
  query orders(
    $futureFulfillment: Boolean
    $accountId: ID
    $patientId: ID
    $partnerLocationId: ID
    $states: [String!]
  ) {
    orders(
      futureFulfillment: $futureFulfillment
      accountId: $accountId
      patientId: $patientId
      partnerLocationId: $partnerLocationId
      states: $states
    ) {
      ...OrderInfoShared
    }
  }
  ${OrderInfoShared}
`;
