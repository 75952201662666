import { gql } from '@apollo/client';
import { AccountAddressInfoShared } from '../fragments';

export default gql`
  mutation destroyAccountAddress($input: AccountAddressDestroyInput!) {
    destroyAccountAddress(input: $input) {
      clientMutationId
      accountAddress {
        ...AccountAddressInfoShared
      }
    }
  }
  ${AccountAddressInfoShared}
`;
