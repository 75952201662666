import { Outlet } from 'react-router-dom';
import Header from './Header/Header';

function MainLayout() {
  return (
    <div className="relative flex min-h-full flex-col">
      <Header />

      <Outlet />
    </div>
  );
}

export default MainLayout;
