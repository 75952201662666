import { gql } from '@apollo/client';
import { ReferralInfoPublic } from '../fragments';

export default gql`
  query referral($id: ID!) {
    referral(id: $id) {
      ...ReferralInfoPublic
    }
  }
  ${ReferralInfoPublic}
`;
