import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { ProductCategoryInfo } from '../fragments';

export default gql`
  mutation updateProductCategory($input: ProductCategoryUpdateInput!) {
    updateProductCategory(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      productCategory {
        ...ProductCategoryInfo
      }
    }
  }
  ${ProductCategoryInfo}
  ${ErrorMessageInfo}
`;
