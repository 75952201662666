import PropTypes from 'prop-types';
import { Input } from '../Inputs';
import { CountrySelect, RegionSelect } from '../Selects';

function AddressGroup({ requiredFields = false }) {
  return (
    <div className="sm:col-span-6">
      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
        <div className="sm:col-span-3">
          <CountrySelect name="address.country" required />
        </div>
        <div className="sm:col-span-3">&nbsp;</div>
        <div className="sm:col-span-3">
          <Input
            name="address.address"
            label="Address"
            required={requiredFields}
          />
        </div>
        <div className="sm:col-span-3">
          <Input name="address.address2" label="Address Line 2" />
        </div>
        <div className="sm:col-span-2">
          <Input name="address.city" label="City" required={requiredFields} />
        </div>
        <div className="sm:col-span-2">
          <RegionSelect
            name="address.region"
            countryFieldName="address.country"
          />
        </div>
        <div className="sm:col-span-2">
          <Input
            name="address.postalCode"
            label="Postal Code"
            required={requiredFields}
          />
        </div>
      </div>
    </div>
  );
}

AddressGroup.propTypes = {
  requiredFields: PropTypes.bool,
};

export default AddressGroup;
