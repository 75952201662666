import { gql } from '@apollo/client';
import { ProductInfo } from '../fragments';

export default gql`
  query product($slug: String, $id: ID) {
    product(slug: $slug, id: $id) {
      ...ProductInfo
    }
  }
  ${ProductInfo}
`;
