import PropTypes from 'prop-types';
import Badge from '../../Badge/Badge';

function ShowStatus({ state = null, active = null }) {
  function getName() {
    if (state) {
      if (state === 'unsent') {
        return 'not sent';
      }

      if (state === 'overdue1') {
        return 'overdue 1 day';
      }

      if (state === 'overdue2') {
        return 'overdue 3 days';
      }

      if (state === 'overdue3') {
        return 'overdue 5+ days';
      }

      return state.replace(/_/g, ' ');
    }

    if (active === false) {
      return 'Inactive';
    }

    return 'Active';
  }

  function getStateColor() {
    switch (state) {
      case 'pending':
      case 'processing':
      case 'unsent':
      case 'on_hold':
      case 'returned':
      case 'inactive':
      case 'ready_to_produce':
      case 'unpaid':
      case 'partially_paid':
      case 'No':
        return 'yellow';
      case 'sent':
      case 'complete':
      case 'refunded':
        return 'blue';
      case 'active':
      case 'completed':
      case 'being_manufactured':
      case 'preparing_shipment':
      case 'partially_shipped':
      case 'shipped':
      case 'delivered':
      case 'paid':
      case 'Yes':
        return 'green';
      case 'overdue1':
      case 'overdue2':
      case 'overdue3':
      case 'cancelled':
      case 'needs_attention':
      case 'payment_failed':
        return 'red';
      default:
        return 'gray';
    }
  }

  function getColor() {
    if (state) {
      return getStateColor();
    }

    if (active === false) {
      return 'red';
    }

    return 'blue';
  }

  return <Badge name={getName()} color={getColor()} withDot={false} />;
}

ShowStatus.propTypes = {
  state: PropTypes.string,
  active: PropTypes.bool,
};

export default ShowStatus;
