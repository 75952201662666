import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function BaseLoader({
  title = '',
  titlePrefix = 'Loading',
  hideText = false,
  className = '',
}) {
  const { t } = useTranslation('ui');

  return (
    <div
      className={`absolute z-10 flex h-[calc(100%-2em)] w-[calc(100%-8px)] flex-col items-center justify-center rounded-md bg-white/5 ${className}`}
    >
      <div className="mb-4">
        <svg
          className="text-rcprimary-400 inline size-8 animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </div>
      {!hideText && (
        <div>
          <span className="text-xl text-black/70">
            {t(`${titlePrefix} ${title}`)}
          </span>
        </div>
      )}
    </div>
  );
}

BaseLoader.propTypes = {
  title: PropTypes.string,
  titlePrefix: PropTypes.string,
  hideText: PropTypes.bool,
  className: PropTypes.string,
};

export default BaseLoader;
