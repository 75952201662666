import { gql } from '@apollo/client';

export default gql`
  mutation destroyAccountNote($input: AccountNoteDestroyInput!) {
    destroyAccountNote(input: $input) {
      clientMutationId
      accountNote {
        id
        message
        accountId
        patientId
        accountNoteableType
        accountNoteable {
          id
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
    }
  }
`;
