import { gql } from '@apollo/client';

const ReferralInfoPublic = gql`
  fragment ReferralInfoPublic on Referral {
    id
    state
    customerOptions
    accountId
    source
    account {
      id
      accountAddresses {
        id
        address
        address2
        city
        region
        postalCode
        country
        isBilling
        isShipping
        isDefault
      }
    }
    cart {
      id
      cartItemsAttributes {
        id
        name
        price
        discountAmount
        taxAmount
        shippingAmount
        total
        quantity
        productId
        product {
          id
          featuredImage
          description
        }
      }
    }
    referralType {
      id
      name
      isPreTreatment
      partnerPays
      product {
        id
        name
        internalName
        price
        renewalPrice
        description
        active
        featuredImage
        productType
        productFeatures {
          id
          text
        }
      }
      products {
        id
        name
        internalName
        price
        renewalPrice
        description
        active
        featuredImage
        productType
        productFeatures {
          id
          text
        }
      }
    }
    partnerOrganization {
      id
      name
      slug
      imageUrl
      address {
        id
        city
        region
        country
      }
    }
    partnerLocation {
      id
      name
      slug
      imageUrl
      address {
        id
        city
        region
        country
      }
    }
    userAttributes {
      id
      firstName
      lastName
      email
      phone
      slug
      state
      acceptTerms
      acceptSms
      acceptMarketing
    }
    patientAttributes {
      id
      firstName
      lastName
      slug
      arches
      dob
      birthDay
      birthMonth
      birthYear
      includeEot
    }
    createdAt
    updatedAt
  }
`;

export default ReferralInfoPublic;
