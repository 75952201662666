import { gql } from '@apollo/client';

const ReferralInfoShared = gql`
  fragment ReferralInfoShared on Referral {
    id
    slug
    state
    reminders
    consumedAt
    sent
    sentAt
    customerOptions
    source
    orders {
      id
    }
    account {
      id
      accountNumber
    }
    partnerOrganizationId
    partnerOrganization {
      id
      name
      slug
      address {
        id
        city
        region
        country
      }
    }
    partnerLocationId
    partnerLocation {
      id
      name
      slug
      settingGroupOptionIds
      address {
        id
        city
        region
        country
      }
    }
    userId
    userAttributes {
      id
      firstName
      lastName
      state
      email
      phone
      slug
    }
    patientAttributes {
      id
      firstName
      lastName
      slug
      arches
      dob
      birthDay
      birthMonth
      birthYear
      settingGroupOptionIds
      settingsOverride
      notes
      includeEot
      scanReady
      scanReadyAt
      scanSentAt
      sendToClinic
    }
    createdAt
    updatedAt
  }
`;

export default ReferralInfoShared;
