import { gql } from '@apollo/client';

export default gql`
  query subscriptionProducts {
    subscriptionProducts {
      id
      price
      wholesalePrice
      showInStore
      overPrice
      overrideWholesalePrice
      productId
      product {
        id
        name
        arches
        wholesalePrice
        isRetainer
        isEot
        description
        defaultSubscriptionRetainer
        featuredImage
        productType
        productFeatures {
          id
          text
        }
      }
    }
  }
`;
