import { gql } from '@apollo/client';
import { ScanFileInfoShared } from '../fragments';

export default gql`
  mutation destroyScanFile($input: ScanFileDestroyInput!) {
    destroyScanFile(input: $input) {
      clientMutationId
      scanFile {
        ...ScanFileInfoShared
      }
    }
  }
  ${ScanFileInfoShared}
`;
