import { gql } from '@apollo/client';
import { AdminTagInfo } from '../fragments';

export default gql`
  query adminTag($slug: String, $id: ID) {
    adminTag(slug: $slug, id: $id) {
      ...AdminTagInfo
    }
  }
  ${AdminTagInfo}
`;
