import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { ReferralInfoPublic } from '../fragments';

export default gql`
  mutation createReferral($input: ReferralCreateInput!) {
    createReferral(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      referral {
        ...ReferralInfoPublic
      }
    }
  }
  ${ReferralInfoPublic}
  ${ErrorMessageInfo}
`;
