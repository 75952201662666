import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FieldContainer } from '../FieldContainer';
import RcSelect from './RcSelect';

function DynamicSelect({
  name,
  style = {},
  className = '',
  required = false,
  hideLabel = false,
  dynamicQuery,
  dataName,
  disableOnEdit = false,
  variables = {},
  label = null,
  searchable = false,
  clearable = true,
  callback = null,
}) {
  const [remoteData, setRemoteData] = useState([]);
  const [urlSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const { setValue } = useFormContext();

  const { data, error, loading } = useQuery(dynamicQuery, {
    variables,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      if (callback) {
        callback(d[dataName]);
      }
    },
  });

  useEffect(() => {
    if (data && data[dataName]) {
      setRemoteData(
        [...data[dataName]].sort((a, b) => a.name.localeCompare(b.name)),
      );
    }
  }, [data, dataName]);

  useEffect(() => {
    const searchVal = urlSearchParams.get(name);

    if (searchVal && remoteData.length) {
      const found = remoteData.find((item) => item.id === searchVal);

      if (found) {
        setValue(name, found.id);
      }
    }
  }, [name, remoteData, setValue, urlSearchParams]);

  return (
    <FieldContainer
      style={style}
      className={className}
      required={required}
      hideLabel={hideLabel}
      name={name}
      label={label}
    >
      {error ? (
        <div className="text-red-500">Error...</div>
      ) : (
        <RcSelect
          name={name}
          data={remoteData}
          disabled={
            remoteData === undefined ||
            remoteData === null ||
            remoteData.length === 0 ||
            (disableOnEdit && pathname.includes('edit'))
          }
          loading={loading}
          searchable={searchable}
          clearable={clearable}
        />
      )}
    </FieldContainer>
  );
}

DynamicSelect.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  dynamicQuery: PropTypes.object.isRequired,
  dataName: PropTypes.string.isRequired,
  variables: PropTypes.object,
  label: PropTypes.string,
  disableOnEdit: PropTypes.bool,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  callback: PropTypes.func,
};

export default DynamicSelect;
