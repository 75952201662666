import { gql } from '@apollo/client';

export default gql`
  query roleTypes($modelScope: String!) {
    roleTypes(modelScope: $modelScope) {
      id
      name
      symbol
      level
      modelScope
      scopeToLocation
    }
  }
`;
