import { useQuery } from '@apollo/client';
import { Dialog } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { useFormValues } from '@retainerclub/hooks';
import { GET_PRICE_BOOK_PRODUCTS } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, LoadingOrError, Modal } from '../../../../shared';

function ReferralsProductionListModalConfirm({
  open,
  setOpen,
  isLoading,
  loading,
  isSubmitting,
  isValid,
  partnerLocationId = null,
  handleSubmit,
}) {
  const { productIds } = useFormValues();
  const [products, setProducts] = useState([]);

  const { error: qError, loading: qLoading } = useQuery(
    GET_PRICE_BOOK_PRODUCTS,
    {
      variables: {
        productIds,
        partnerLocationId,
      },
      skip: !productIds?.length || !partnerLocationId,
      onCompleted: (data) => setProducts(data.priceBookProducts),
    },
  );

  if (!open) {
    return null;
  }

  return (
    <Modal open={open} setOpen={setOpen} size="xl">
      <div>
        <div className="bg-rcprimary-100 mx-auto flex size-12 items-center justify-center rounded-full">
          <ExclamationTriangleIcon
            className="text-rcprimary-400 size-6"
            aria-hidden="true"
          />
        </div>
        <div className="mt-2 text-center">
          <Dialog.Title
            as="h2"
            className="text-2xl font-semibold leading-6 text-gray-900"
          >
            Confirm your order
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              You are about to place an order for the selected products. Please
              review.
            </p>
          </div>
        </div>
        <div>
          {qError || qLoading ? (
            <div className="mt-4 min-h-[100px]">
              <LoadingOrError error={qError} loading={qLoading} />
            </div>
          ) : (
            <ul className="divide-y divide-gray-200">
              {products.map((product) => (
                <li key={product.id} className="flex px-4 py-6 sm:px-6">
                  <div className="shrink-0">
                    <img
                      src={product.product.featuredImage}
                      alt={product.product.name}
                      className="w-20 rounded-md"
                    />
                  </div>
                  <div className="ml-6 flex flex-1 flex-col">
                    <div className="flex">
                      <div className="min-w-0 flex-1">
                        <h4 className="text-sm">
                          <span className="font-bold text-gray-700 hover:text-gray-800">
                            {product.product.name}
                          </span>
                        </h4>
                        <p className="mt-1 text-sm text-gray-500">
                          {product.product.description}
                        </p>
                        <p className="mt-0 text-xs text-gray-500">
                          {product.product.isRetainer
                            ? `Arches: ${product.product.arches}`
                            : ''}
                        </p>
                        <p className="mt-0 text-xs text-gray-500">
                          {product.product.isEot
                            ? `End of Treatment Retainer`
                            : ''}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-1 items-end justify-between pt-2">
                      <p className="mt-1 text-sm font-medium text-gray-700">
                        $
                        {(
                          (product.overrideWholesalePrice
                            ? product.wholesalePrice
                            : product.product.wholesalePrice) / 100
                        ).toFixed(2)}{' '}
                        charged to office
                      </p>

                      <div className="ml-4 text-sm font-medium">Qty: 1</div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="mt-4 grid grid-cols-2 gap-4">
          <Button
            name="Make Changes"
            onClick={() => setOpen(false)}
            className="inline-flex w-full items-center justify-center px-5 py-2.5 font-semibold"
            color="gray"
          />
          <Button
            disabled={isLoading || loading || isSubmitting || !isValid}
            name="Place Order"
            className="inline-flex w-full items-center justify-center px-5 py-2.5 font-semibold"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
}

ReferralsProductionListModalConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  partnerLocationId: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
};

export default ReferralsProductionListModalConfirm;
