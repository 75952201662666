import { gql } from '@apollo/client';
import { ContactFormInfoShared } from '../fragments';

export default gql`
  query contactForm($id: ID!) {
    contactForm(id: $id) {
      ...ContactFormInfoShared
    }
  }
  ${ContactFormInfoShared}
`;
