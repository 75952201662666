import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { Logo } from '@retainerclub/common';
import { useAuth } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import DesktopNavAuthed from './DesktopNav/DesktopNavAuthed';
import DesktopNavCart from './DesktopNav/DesktopNavCart';
import DesktopNavProfile from './DesktopNav/DesktopNavProfile';
import DesktopNavUnauthed from './DesktopNav/DesktopNavUnauthed';
import MobileNavAuthed from './MobileNav/MobileNavAuthed';
import MobileNavProfile from './MobileNav/MobileNavProfile';
import MobileNavUnauthed from './MobileNav/MobileNavUnauthed';

function Header({ className = '' }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { currentUser } = useAuth();

  const app = import.meta.env.VITE_APP_NAME;

  return (
    <header
      className={`shrink-0 border-b border-black/10 bg-white ${className}`}
    >
      <nav
        className="flex items-center justify-between px-6 py-4 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/">
            <span className="sr-only">Retainer Club</span>
            <Logo color="black" width={122} height={35} />
          </Link>
        </div>
        <div className="flex">
          {app === 'rc_customer' && (
            <div className="mr-4">
              <DesktopNavCart />
            </div>
          )}

          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 lg:hidden"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="size-6" aria-hidden="true" />
          </button>
        </div>

        <div className="hidden lg:flex lg:gap-x-12">
          {currentUser ? <DesktopNavAuthed /> : <DesktopNavUnauthed />}
        </div>
        <div className="hidden items-center gap-x-4 lg:flex lg:flex-1 lg:justify-end">
          {app === 'rc_customer' ? (
            <DesktopNavProfile />
          ) : (
            <a
              href={`${import.meta.env.VITE_CUSTOMER_APP}/signin`}
              className="flex items-center text-sm font-semibold leading-6 text-gray-900"
            >
              <UserCircleIcon className="mr-2 size-8" aria-hidden="true" />
              <span className="text-rcprimary-400 text-base font-normal">
                Sign In
              </span>
            </a>
          )}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white p-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link
              to={currentUser ? '/' : 'https://retainerclub.com'}
              className="-m-1.5 p-1.5"
            >
              <span className="sr-only">Retainer Club</span>
              <Logo color="black" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="size-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-black/20">
              <div className="space-y-2 py-4">
                {currentUser ? <MobileNavAuthed /> : <MobileNavUnauthed />}
              </div>
              <div className="space-y-2 py-4">
                <MobileNavProfile />
              </div>
              <div className="py-4">
                {app === 'rc_customer' ? (
                  <div className="flex flex-row items-center justify-start gap-x-4">
                    <DesktopNavCart />
                  </div>
                ) : (
                  <a
                    href={`${import.meta.env.VITE_CUSTOMER_APP}/signin`}
                    className="flex items-center text-sm font-semibold leading-6 text-gray-900"
                  >
                    <UserCircleIcon
                      className="mr-2 size-8"
                      aria-hidden="true"
                    />
                    <span className="text-rcprimary-400 text-base font-normal">
                      Sign In
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
