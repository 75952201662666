import { gql } from '@apollo/client';
import {
  ErrorMessageInfo,
  ReferralTypeInfoShared,
} from '@retainerclub/shared-api';

export default gql`
  mutation updateReferralType($input: ReferralTypeUpdateInput!) {
    updateReferralType(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      referralType {
        ...ReferralTypeInfoShared
      }
    }
  }
  ${ReferralTypeInfoShared}
  ${ErrorMessageInfo}
`;
