import { gql } from '@apollo/client';

export default gql`
  query partnerLocations {
    partnerLocations {
      id
      name
    }
  }
`;
