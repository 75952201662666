import { useEffect, useState } from 'react';

function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const matchQueryList = window.matchMedia(query);

    function handleChange(e) {
      setInitialized(true);
      setMatches(e.matches);
    }

    if (matchQueryList.matches === true && initialized === false) {
      setInitialized(true);
      setMatches(true);
    }

    matchQueryList.addEventListener('change', handleChange);
    return () => {
      matchQueryList.removeEventListener('change', handleChange);
    };
  }, [initialized, matches, query]);

  return matches;
}

export default useMediaQuery;
