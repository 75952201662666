/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';

function ShowTableStandardRow({ children, light = false }) {
  return (
    <tr
      className={`divide-x ${
        light
          ? 'bg-rcgray-200/40 divide-black/0 text-black odd:bg-white/80'
          : 'bg-rcgray-200/50 divide-black/20 text-black'
      }`}
    >
      {children}
    </tr>
  );
}

ShowTableStandardRow.propTypes = {
  children: PropTypes.node.isRequired,
  light: PropTypes.bool,
};

export default ShowTableStandardRow;
