import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation updateCurrentUser($input: CurrentUserUpdateInput!) {
    updateCurrentUser(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
    }
  }
  ${ErrorMessageInfo}
`;
