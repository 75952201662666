import { useMutation } from '@apollo/client';
import { Dialog } from '@headlessui/react';
import { EnvelopeIcon } from '@heroicons/react/20/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BaseLoader,
  EmailInput,
  ErrorMessage,
  Input,
  Modal,
  SubmitButton,
  TextArea,
} from '@retainerclub/common';
import { useToast } from '@retainerclub/hooks';
import { CREATE_CONTACT_FORM } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { initialValues, schema } from './helpers';

function NoResultsForm({ open, setOpen }) {
  const [mutationErrors, setMutationErrors] = useState(null);
  const [update, { error, loading }] = useMutation(CREATE_CONTACT_FORM);
  const { setToast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, isLoading },
    formState,
    control,
    getValues,
    setValue,
    ...rest
  } = useForm({
    resolver: yupResolver(schema()),
    defaultValues: initialValues(),
    mode: 'onBlur',
  });

  async function submitRecord(values) {
    try {
      const params = {
        variables: {
          input: {
            input: { ...values },
            clientMutationId: uuidv4(),
          },
        },
      };

      const { data } = await update(params);

      if (data?.errors?.length > 0) {
        setMutationErrors(data.errors);
      } else {
        setOpen(false);
        setToast({
          show: true,
          title: 'Success',
          message: 'Thank you for your submission!',
          success: true,
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  if (loading) {
    return <BaseLoader title="Details" />;
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <FormProvider
        {...rest}
        control={control}
        register={register}
        handleSubmit={handleSubmit}
        formState={formState}
        getValues={getValues}
        setValue={setValue}
      >
        <form
          onSubmit={handleSubmit(submitRecord)}
          className="space-y-8"
          noValidate
        >
          <div>
            <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
              <EnvelopeIcon
                className="text-rcprimary-400 size-6"
                aria-hidden="true"
              />
            </div>
            <div className="mt-2 sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-center text-base font-semibold leading-6 text-black"
              >
                Help us find you a partner in your area
              </Dialog.Title>
              <div className="mt-4">
                <div className="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-6">
                  <Input
                    className="sm:col-span-3"
                    name="firstName"
                    label="First Name"
                  />
                  <Input
                    className="sm:col-span-3"
                    name="lastName"
                    label="Last Name"
                  />
                  <EmailInput className="sm:col-span-3" />
                  <Input className="sm:col-span-3" name="phone" label="Phone" />

                  <Input
                    className="sm:col-span-3"
                    required
                    name="postalCode"
                    label="Postal Code"
                  />
                  <TextArea className="sm:col-span-full" name="message" />
                </div>
                {mutationErrors && (
                  <div className="mt-6 sm:col-span-6">
                    <ErrorMessage errorMessages={mutationErrors} />
                  </div>
                )}
                {error && (
                  <div className="mt-6 sm:col-span-6">
                    <ErrorMessage errorMessages={[error]} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <SubmitButton
              isLoading={isLoading}
              isSubmitting={isSubmitting}
              isValid={isValid}
              label="Send"
              full
              notRounded
            />
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

NoResultsForm.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default NoResultsForm;
