import { gql } from '@apollo/client';

const CartItemInfoPublic = gql`
  fragment CartItemInfoPublic on CartItem {
    id
    name
    price
    discountAmount
    taxAmount
    shippingAmount
    total
    quantity
    productId
    patientId
    patient {
      id
      firstName
      lastName
    }
    product {
      id
      featuredImage
      description
      productFeatures {
        id
        text
      }
      planSubscription {
        id
        name
        featuredImage
        description
        productFeatures {
          id
          text
        }
      }
      planProducts {
        id
        price
        overridePrice
        assignedProduct {
          id
          name
          featuredImage
          price
          description
          productFeatures {
            id
            text
          }
        }
      }
    }
  }
`;

export default CartItemInfoPublic;
