import { gql } from '@apollo/client';
import { ProductInfoPublic } from '../fragments';

export default gql`
  query product($slug: String, $id: ID) {
    product(slug: $slug, id: $id) {
      ...ProductInfoPublic
    }
  }
  ${ProductInfoPublic}
`;
