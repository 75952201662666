import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import FieldContainer from '../FieldContainer/FieldContainer';

function CvvInput({
  name,
  label = 'CVV',
  required = false,
  style = {},
  className = '',
  hideLabel = false,
  disabled = false,
  placeholder = '',
  attributes = {},
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <FieldContainer
      style={style}
      className={className}
      required={required}
      hideLabel={hideLabel}
      name={name}
      label={label}
      disabled={disabled}
    >
      <div className="relative rounded-md shadow-sm">
        <input
          {...register(name)}
          {...attributes}
          name={name}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={4}
          inputMode="numeric"
          pattern="[0-9]*"
          className={`disabled:bg-rcgray-600 disabled:ring-rcgray-700 disabled:text-rcgray-600 } block w-full rounded-md border-0 py-1.5 pl-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset disabled:cursor-not-allowed  sm:text-sm
        sm:leading-6 ${
          errors && errors[name]
            ? 'text-red-900 ring-red-500 placeholder:text-red-500 focus:ring-red-500'
            : 'focus:ring-rcprimary-400 ring-gray-300 placeholder:text-gray-400'
        }`}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <img
            src="https://cdn.retainerclub.com/images/payment-icons/cvv.svg"
            className="size-7"
            alt="CVV"
          />
        </div>
      </div>
    </FieldContainer>
  );
}

CvvInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  attributes: PropTypes.object,
  placeholder: PropTypes.string,
};

export default CvvInput;
