import { gql } from '@apollo/client';
import { PartnerUserInfoShared } from '../fragments';

export default gql`
  query partnerUser($slug: String, $id: ID) {
    partnerUser(slug: $slug, id: $id) {
      ...PartnerUserInfoShared
    }
  }
  ${PartnerUserInfoShared}
`;
