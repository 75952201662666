import { gql } from '@apollo/client';
import { ProductInfo } from '../fragments';

export default gql`
  query products {
    products {
      ...ProductInfo
    }
  }
  ${ProductInfo}
`;
