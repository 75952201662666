import { gql } from '@apollo/client';
import { OrderInfoPublic } from '../fragments';

export default gql`
  query order($id: ID, $slug: String) {
    order(id: $id, slug: $slug) {
      ...OrderInfoPublic
    }
  }
  ${OrderInfoPublic}
`;
