import { gql } from '@apollo/client';
import { ManufacturerInfo } from '../fragments';

export default gql`
  query manufacturer($slug: String, $id: ID) {
    manufacturer(slug: $slug, id: $id) {
      ...ManufacturerInfo
    }
  }
  ${ManufacturerInfo}
`;
