import { gql } from '@apollo/client';
import { SubscriptionInfo } from '../fragments';

export default gql`
  query subscription($id: ID) {
    subscription(id: $id) {
      ...SubscriptionInfo
    }
  }
  ${SubscriptionInfo}
`;
