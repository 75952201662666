import { gql } from '@apollo/client';
import { CartInfoPublic } from '../fragments';

export default gql`
  mutation destroyCart($input: CartDestroyInput!) {
    destroyCart(input: $input) {
      clientMutationId
      cart {
        ...CartInfoPublic
      }
    }
  }
  ${CartInfoPublic}
`;
