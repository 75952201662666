import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { CartTransactionInfoPublic } from '../fragments';

export default gql`
  mutation createCartTransaction($input: CartTransactionCreateInput!) {
    createCartTransaction(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      cartTransaction {
        ...CartTransactionInfoPublic
      }
    }
  }
  ${CartTransactionInfoPublic}
  ${ErrorMessageInfo}
`;
