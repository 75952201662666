import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import FieldContainer from '../FieldContainer/FieldContainer';

function Input({
  name,
  label = null,
  type = 'text',
  style = {},
  className = '',
  placeholder = '',
  required = false,
  hideLabel = false,
  autoComplete = null,
  website = false,
  inputClass = {},
  disabled = false,
  attributes = {},
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <FieldContainer
      style={style}
      className={className}
      required={required}
      hideLabel={hideLabel}
      name={name}
      label={label}
      type={type}
      website={website}
      disabled={disabled}
    >
      <input
        {...register(name)}
        {...attributes}
        name={name}
        type={type}
        required={required}
        autoComplete={autoComplete}
        placeholder={placeholder}
        disabled={disabled}
        className={`disabled:bg-rcgray-600 disabled:ring-rcgray-700 disabled:text-rcgray-600 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset disabled:cursor-not-allowed sm:text-sm sm:leading-6 ${
          errors && errors[name]
            ? 'text-red-900 ring-red-500 placeholder:text-red-500 focus:ring-red-500'
            : 'focus:ring-rcprimary-400 ring-gray-300 placeholder:text-gray-400'
        } ${
          type === 'password' || type === 'email' ? 'pl-10' : 'pl-2'
        } ${inputClass} ${
          website &&
          'flex-1 rounded-none rounded-r-md border-0 bg-transparent pl-1 focus:ring-0'
        }`}
      />
    </FieldContainer>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  autoComplete: PropTypes.string,
  website: PropTypes.bool,
  inputClass: PropTypes.string,
  disabled: PropTypes.bool,
  attributes: PropTypes.object,
};

export default Input;
