import { gql } from '@apollo/client';

export default gql`
  query partnerMap(
    $limit: Int
    $offset: Int
    $search: String
    $coordinates: [Float!]
    $radius: Float
    $boundingBox: [Float!]
  ) {
    partnerMap(
      limit: $limit
      offset: $offset
      search: $search
      coordinates: $coordinates
      radius: $radius
      boundingBox: $boundingBox
    ) {
      radius
      results {
        id
        attributes
      }
      totalCount
    }
  }
`;
