import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { PatientInfoShared } from '../fragments';

export default gql`
  mutation createRetainerBankOrder($input: RetainerBankOrderCreateInput!) {
    createRetainerBankOrder(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      patient {
        ...PatientInfoShared
      }
    }
  }
  ${PatientInfoShared}
  ${ErrorMessageInfo}
`;
