import { Tab } from '@headlessui/react';
import PropTypes from 'prop-types';

function RcTab(props) {
  const { name } = props;

  return (
    <Tab
      className={({ selected }) =>
        `${selected && 'text-rcprimary-400 bg-white font-bold'} ${
          !selected &&
          'hover:border-rcprimary-400 hover:text-rcprimary-400 border-transparent bg-white/60 font-medium text-black hover:bg-white'
        } whitespace-nowrap rounded-t-lg px-6 py-3 text-sm focus:outline-none`
      }
    >
      {name}
    </Tab>
  );
}

RcTab.propTypes = {
  name: PropTypes.string.isRequired,
};

export default RcTab;
