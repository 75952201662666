import {
  EyeIcon,
  ListBulletIcon,
  PencilSquareIcon,
  PlusIcon,
} from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import TooltipText from '../Tooltip/TooltipText';

function Button({
  name = '',
  show = false,
  edit = false,
  add = false,
  list = false,
  size = 'md',
  rounded = false,
  disabled = false,
  color = 'rcprimary',
  onClick = null,
  to = null,
  className = '',
  tooltip = null,
  tooltipPosition = 'tooltip-top',
}) {
  function getSize() {
    switch (size) {
      case 'xs':
        return 'px-2 py-1 text-xs';
      case 'sm':
        return 'px-2 py-1 text-sm';
      case 'md':
        return 'px-2.5 py-1.5 text-sm';
      case 'lg':
        return 'px-3 py-2 text-base';
      case 'xl':
        return 'px-3.5 py-2.5 text-base';

      default:
        return 'px-2.5 py-1.5 text-sm';
    }
  }

  function getColor() {
    switch (color) {
      case 'indigo':
        return 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 text-white';
      case 'red':
        return 'bg-red-600 hover:bg-red-500 focus-visible:outline-red-600 text-white';
      case 'gray':
        return 'bg-gray-600 hover:bg-gray-500 focus-visible:outline-gray-600 text-white';
      case 'yellow':
        return 'bg-yellow-600 hover:bg-yellow-500 focus-visible:outline-yellow-600 text-white';
      case 'green':
        return 'bg-green-600 hover:bg-green-500 focus-visible:outline-green-600 text-white';
      case 'blue':
        return 'bg-blue-600 hover:bg-blue-500 focus-visible:outline-blue-600 text-white';
      case 'pink':
        return 'bg-pink-600 hover:bg-pink-500 focus-visible:outline-pink-600 text-white';
      case 'purple':
        return 'bg-purple-600 hover:bg-purple-500 focus-visible:outline-purple-600 text-white';
      case 'rcprimary':
        return 'bg-rcprimary-400 hover:bg-rcprimary-400/0 text-black outline outline-white/0 outline-2 hover:text-rcprimary-400 hover:outline-rcprimary-400 outline-offset-0';

      default:
        return 'bg-rcprimary-400 hover:bg-rcprimary-400/0 text-black outline outline-white/0 outline-2 hover:text-rcprimary-400 hover:outline-rcprimary-400 outline-offset-0';
    }
  }

  return to ? (
    <NavLink
      to={to}
      disabled={disabled}
      className={`inline-flex items-center gap-x-1.5 font-medium shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${getSize()} ${
        rounded ? 'rounded-xl' : ''
      } ${getColor()} ${className} ${tooltip ? 'tooltip' : ''} ${
        tooltip && tooltipPosition ? tooltipPosition : ''
      }`}
    >
      {tooltip && <TooltipText tooltip={tooltip} />}
      {edit && (
        <PencilSquareIcon className="-ml-0.5 size-5" aria-hidden="true" />
      )}

      {list && <ListBulletIcon className="-ml-0.5 size-5" aria-hidden="true" />}

      {add && <PlusIcon className="-ml-0.5 size-5" aria-hidden="true" />}

      {show && <EyeIcon className="-ml-0.5 size-5" aria-hidden="true" />}

      {name}
    </NavLink>
  ) : (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={`inline-flex items-center gap-x-1.5 font-medium shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-60 ${getSize()} ${
        rounded ? 'rounded-xl' : ''
      } ${getColor()} ${className} ${tooltip ? 'tooltip' : ''} ${
        tooltip && tooltipPosition ? tooltipPosition : ''
      } ${
        disabled ? 'cursor-not-allowed' : ''
      } disabled:bg-rcgray-400 disabled:text-black`}
    >
      {tooltip && <TooltipText tooltip={tooltip} />}
      {edit && (
        <PencilSquareIcon className="-ml-0.5 size-5" aria-hidden="true" />
      )}

      {list && <ListBulletIcon className="-ml-0.5 size-5" aria-hidden="true" />}

      {add && <PlusIcon className="-ml-0.5 size-5" aria-hidden="true" />}

      {show && <EyeIcon className="-ml-0.5 size-5" aria-hidden="true" />}

      {name}
    </button>
  );
}

Button.propTypes = {
  name: PropTypes.string,
  edit: PropTypes.bool,
  show: PropTypes.bool,
  add: PropTypes.bool,
  list: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  rounded: PropTypes.bool,
  color: PropTypes.oneOf([
    'indigo',
    'red',
    'gray',
    'yellow',
    'green',
    'blue',
    'pink',
    'purple',
    'rcprimary',
  ]),
  onClick: PropTypes.func,
  to: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
};

export default Button;
