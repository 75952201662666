import { gql } from '@apollo/client';
import { AdminUserInfo } from '../fragments';

export default gql`
  query adminUser($slug: String, $id: ID) {
    adminUser(slug: $slug, id: $id) {
      ...AdminUserInfo
    }
  }
  ${AdminUserInfo}
`;
