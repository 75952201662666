import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { PartnerLocationInfo } from '../fragments';

export default gql`
  mutation createPartnerLocation($input: PartnerLocationCreateInput!) {
    createPartnerLocation(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      partnerLocation {
        ...PartnerLocationInfo
      }
    }
  }
  ${PartnerLocationInfo}
  ${ErrorMessageInfo}
`;
