import { gql } from '@apollo/client';
import {
  ErrorMessageInfo,
  ReferralTypeInfoShared,
} from '@retainerclub/shared-api';

export default gql`
  mutation createReferralType($input: ReferralTypeCreateInput!) {
    createReferralType(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      referralType {
        ...ReferralTypeInfoShared
      }
    }
  }
  ${ReferralTypeInfoShared}
  ${ErrorMessageInfo}
`;
