import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import DeleteButton from '../../../Button/DeleteButton';

function DeleteImageButton({ name = null, setPreview, defaultImage }) {
  const { setValue } = useFormContext();

  function removeImage() {
    setValue(name, null);
    setPreview(defaultImage);
  }

  return name !== null ? <DeleteButton onClick={() => removeImage()} /> : null;
}

DeleteImageButton.propTypes = {
  name: PropTypes.string,
  setPreview: PropTypes.func.isRequired,
  defaultImage: PropTypes.string.isRequired,
};

export default DeleteImageButton;
