import { useQuery } from '@apollo/client';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useFormValues } from '@retainerclub/hooks';
import { GET_PRODUCT_LAB_NOTE } from '@retainerclub/partner-api';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { LoadingOrError } from '../../../../../shared';

function AdditionalSmileCarePlanSelection({
  partnerLocationId,
  hasPlan = false,
}) {
  const [productId, setProductId] = useState(null);
  const { setValue } = useFormContext();
  const { productIds } = useFormValues();

  const productAdded = useMemo(
    () => productIds.includes(productId),
    [productId, productIds],
  );

  const { error, loading } = useQuery(GET_PRODUCT_LAB_NOTE, {
    variables: {
      partnerLocationId,
      isPlan: true,
    },
    onCompleted: (data) => {
      setProductId(data.productLabNote.id);
    },
  });

  function toggleProductSelection() {
    if (productIds.includes(productId)) {
      setValue(
        'productIds',
        productIds.filter((id) => id !== productId),
      );
    } else {
      setValue('productIds', [...productIds, productId]);
    }
  }

  if (loading || error)
    return <LoadingOrError loading={loading} error={error} />;

  return (
    <div
      className={`relative flex basis-1/2 flex-col justify-between rounded-lg border ${productAdded ? 'border-rcprimary-400 bg-rcprimary-400/20' : 'border-black/20'} ${hasPlan ? 'bg-rcgray-200' : 'bg-rcprimary-400/20'} p-4`}
    >
      {hasPlan && (
        <div className="bg-rcprimary-400 absolute right-0 top-8 z-10 w-2/3 rounded-l-lg p-4 font-bold text-white shadow-md sm:top-28">
          Currently Enrolled
        </div>
      )}

      {productAdded && (
        <CheckCircleIcon className="text-rcprimary-400 absolute -right-6 -top-6 z-20 size-12" />
      )}
      <div className="relative">
        <div className="relative h-72 w-full overflow-hidden rounded-lg">
          <img
            src="https://cdn.retainerclub.com/images/products/scp-pi.jpg"
            alt="Additional Smile Care Plan"
            className="size-full object-cover object-center"
          />
        </div>
        <div className="relative mt-4">
          <h3 className="text-sm font-medium text-gray-900">
            Smile Care Plan - Paid in Office
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Click here to show this patient has purchased a Smile Care Plan in
            your office. A registration email will be sent to the patient and
            you will see the Retainer Club fee on your next invoice.
          </p>
        </div>
      </div>
      {!hasPlan && (
        <div className="mt-6">
          <button
            onClick={toggleProductSelection}
            type="button"
            className={`${productAdded ? 'bg-rcprimary-300 hover:bg-rcprimary-400' : 'bg-rcprimary-400 hover:bg-gray-200'} relative flex w-full items-center justify-center rounded-md border border-transparent px-8 py-2 text-sm font-medium text-gray-900`}
            disabled={!productId}
          >
            {productAdded ? 'Remove Smile Care Plan' : 'Add A Smile Care Plan'}
          </button>
        </div>
      )}
    </div>
  );
}

AdditionalSmileCarePlanSelection.propTypes = {
  partnerLocationId: PropTypes.string.isRequired,
  hasPlan: PropTypes.bool,
};

export default AdditionalSmileCarePlanSelection;
