/* eslint-disable no-underscore-dangle */
import { useContext } from 'react';
import { useMapEvents } from 'react-leaflet';
import { MapViewContext } from '../../../shared/Context';

function equals(a, b) {
  return a.length === b.length && a.every((v, i) => v === b[i]);
}

function updateBounds(map, setBoundingBox, boundingBox, setCenter) {
  const bounds = map.getBounds();

  const boundsArray = [
    bounds._northEast.lat,
    bounds._northEast.lng,
    bounds._southWest.lat,
    bounds._southWest.lng,
  ];

  if (!equals(boundingBox, boundsArray)) {
    const center = map.getCenter();
    setBoundingBox(boundsArray);
    setCenter([center.lat, center.lng]);
  }
}

function BoundsOnEvents() {
  const { setBoundingBox, boundingBox, setCenter } = useContext(MapViewContext);

  const map = useMapEvents({
    moveend: () => updateBounds(map, setBoundingBox, boundingBox, setCenter),
    resize: () => updateBounds(map, setBoundingBox, boundingBox, setCenter),
    load: () => updateBounds(map, setBoundingBox, boundingBox, setCenter),
    dragend: () => updateBounds(map, setBoundingBox, boundingBox, setCenter),
  });

  return null;
}

export default BoundsOnEvents;
