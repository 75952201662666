import { EyeIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function GridCardItem({
  name,
  description = '',
  status = null,
  editLink = null,
  showLink = null,
  imageUrl = null,
}) {
  return (
    <li className="col-span-1 divide-y divide-black/10 rounded-lg bg-white/80 shadow">
      <div className="flex w-full items-center justify-between space-x-6 p-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="truncate text-sm font-medium text-black">{name}</h3>
            {status && (
              <span
                className={`inline-flex shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ${
                  status.toLocaleLowerCase() === 'active'
                    ? 'bg-rcprimary-400 ring-rcprimary-600 text-black'
                    : 'bg-red-50 text-red-700 ring-red-600/20'
                }`}
              >
                {status}
              </span>
            )}
          </div>
          <p className="mt-1 truncate text-sm text-black/70">
            {description}&nbsp;
          </p>
        </div>
        {imageUrl && (
          <img
            className="bg-rcgray-300 h-10 w-10 shrink-0"
            src={imageUrl}
            alt={name}
          />
        )}
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-black/10">
          {editLink && (
            <div className="flex w-0 flex-1">
              <NavLink
                to={editLink}
                className="text-rcprimary-400 hover:text-rcprimary-400 relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-bl-lg border border-transparent py-4 text-sm font-semibold"
              >
                <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
                Edit
              </NavLink>
            </div>
          )}

          {showLink && (
            <div className="-ml-px flex w-0 flex-1">
              <NavLink
                to={showLink}
                className="text-rcgray-900 relative inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-br-lg border border-transparent py-4 text-sm font-semibold"
              >
                <EyeIcon
                  className="text-rcgray-400 h-5 w-5"
                  aria-hidden="true"
                />
                View
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </li>
  );
}

GridCardItem.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  status: PropTypes.string,
  editLink: PropTypes.string,
  showLink: PropTypes.string,
  imageUrl: PropTypes.string,
};

export default GridCardItem;
