import { gql } from '@apollo/client';

export default gql`
  query priceBookProducts {
    priceBookProducts {
      id
      price
      wholesalePrice
      showInStore
      overPrice
      overrideWholesalePrice
      productId
      product {
        id
        name
        price
        description
        renewalPrice
        description
        sku
        slug
        defaultSubscriptionRetainer
        productCategories {
          id
          name
          slug
        }
        featuredImage
        productRenewalInterval {
          id
          name
        }
        productFeatures {
          id
          text
        }
        productImages {
          id
          imageUrl
        }
        productType
        planSubscription {
          id
          name
        }
        planProducts {
          id
          overridePrice
          price
        }
      }
    }
  }
`;
