import { gql } from '@apollo/client';

const PartnerLocationInfoShared = gql`
  fragment PartnerLocationInfoShared on PartnerLocation {
    id
    name
    email
    phone
    website
    active
    slug
    imageUrl
    insertImageUrl
    hiresLogoUrl
    perArchEotFee
    priceBookId
    priceBook {
      id
      name
      slug
    }
    scannerBrandId
    scannerBrand {
      id
      name
    }
    address {
      id
      address
      address2
      city
      region
      postalCode
      country
      latitude
      longitude
    }
    partnerOrganizationId
    partnerOrganization {
      id
      name
      slug
    }
    createdAt
    updatedAt
  }
`;

export default PartnerLocationInfoShared;
