import { useQuery } from '@apollo/client';
import { GET_SETTING_GROUPS } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { Fieldset, LoadingOrError } from '../../shared';
import ChooseManyCheckbox from './ChooseManyCheckbox';
import ChooseOneRadio from './ChooseOneRadio';

function CaseSettingsFormFields({ usePatientAttributes = false }) {
  const { error, loading, data } = useQuery(GET_SETTING_GROUPS, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading || error) {
    return (
      <LoadingOrError error={error} loading={loading} title="Case Settings" />
    );
  }

  return data.settingGroups ? (
    <div className="col-span-full grid grid-cols-1 gap-x-12 gap-y-4 sm:grid-cols-6">
      {data.settingGroups.map((settingGroup) => (
        <Fieldset
          key={settingGroup.id}
          title={settingGroup.name}
          description={
            settingGroup.optionRestriction === 'choose_one'
              ? 'Select One'
              : 'Select All That Apply'
          }
          className="border-b-0 pb-0 sm:col-span-3"
        >
          {settingGroup.optionRestriction === 'choose_one' && (
            <>
              {settingGroup.settingGroupOptions.map((setting) => (
                <div className="col-span-full" key={setting.id}>
                  <ChooseOneRadio
                    setting={setting}
                    settingGroup={settingGroup}
                    usePatientAttributes={usePatientAttributes}
                  />
                </div>
              ))}
            </>
          )}

          {settingGroup.optionRestriction === 'choose_many' && (
            <>
              {settingGroup.settingGroupOptions.map((setting) => (
                <div className="col-span-full" key={setting.id}>
                  <ChooseManyCheckbox
                    setting={setting}
                    usePatientAttributes={usePatientAttributes}
                  />
                </div>
              ))}
            </>
          )}
        </Fieldset>
      ))}
    </div>
  ) : null;
}

CaseSettingsFormFields.propTypes = {
  usePatientAttributes: PropTypes.bool,
};

export default CaseSettingsFormFields;
