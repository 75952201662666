import distance from '@turf/distance';
import { point } from '@turf/helpers';

function calcDistance(to, from) {
  const turfFrom = point(from);
  const turfTo = point(to);
  const options = { units: 'miles' };

  return distance(turfFrom, turfTo, options);
}

export default calcDistance;
