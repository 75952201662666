import { gql } from '@apollo/client';

const AccountInfoPublic = gql`
  fragment AccountInfoPublic on Account {
    id
    users {
      id
      firstName
      lastName
      email
      phone
      imageUrl
      state
      createdAt
    }
    accountAddresses {
      id
      address
      address2
      city
      region
      postalCode
      country
      isBilling
      isShipping
      isDefault
      accountId
    }
    referrals {
      id
      state
      customerOptions
      accountId
      source
    }
    patients {
      id
      firstName
      lastName
      hasPlan
      planStartAt
      dob
      birthDay
      birthMonth
      birthYear
      retainerBank
      scheduledShipments {
        id
        consumed
        shippedAt
        expectedShippedAt
        broughtForward
      }
    }
    orders {
      id
      hexCode
      createdAt
      total
      state
      orderItems {
        id
        name
        quantity
        total

        product {
          id
          featuredImage
          description
        }
      }
    }
    partnerLocationId
    partnerOrganizationId
  }
`;

export default AccountInfoPublic;
