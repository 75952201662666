import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation createPaidInOfficeOrder($input: PaidInOfficeOrderCreateInput!) {
    createPaidInOfficeOrder(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      patient {
        id
        firstName
        lastName
        dob
        birthDay
        birthMonth
        birthYear
        arches
        includeEot
        scanReady
        scanReadyAt
        scanSentAt
        sendToClinic
        settingsOverride
        settingGroupOptionIds
        settingGroupOptions {
          id
          name
          settingGroup {
            id
            name
          }
        }
        notes
        accountId
        hasPlan
        retainerBank
        partnerOrganizationId
        partnerLocationId
        partnerLocation {
          id
          name
          settingGroupOptionIds
          slug
          address {
            id
            city
            region
          }
        }
        account {
          id
          accountNumber
          slug
        }
        slug
        orders {
          id
        }
        referral {
          id
          state
          reminders
          consumedAt
          source
          sent
          sentAt
          hexCode
          partnerOrganizationId
          partnerOrganization {
            id
            name
            slug
          }
          partnerLocationId
          partnerLocation {
            id
            name
            slug
            address {
              id
              city
              region
            }
          }
          userId
          user {
            id
            firstName
            lastName
            slug
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
  ${ErrorMessageInfo}
`;
