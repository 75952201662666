import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function RcLink({
  children,
  href = null,
  to = null,
  rel = '',
  target = '_self',
  textSize = 'text-sm',
  className = '',
}) {
  return href ? (
    <a
      href={href}
      rel={rel}
      target={target}
      className={`text-rcprimary-600 hover:text-rcprimary-400 ${textSize} ${className}}`}
    >
      {children}
    </a>
  ) : (
    <NavLink
      to={to}
      className={`text-rcprimary-600 hover:text-rcprimary-400 ${textSize} ${className}`}
    >
      {children}
    </NavLink>
  );
}

RcLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  to: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.string,
  textSize: PropTypes.string,
  className: PropTypes.string,
};

export default RcLink;
