import { gql } from '@apollo/client';
import { ScanFileInfoShared } from '../fragments';

export default gql`
  query scanFiles($patientId: ID!) {
    scanFiles(patientId: $patientId) {
      ...ScanFileInfoShared
    }
  }
  ${ScanFileInfoShared}
`;
