import { gql } from '@apollo/client';
import { StoreInfo } from '../fragments';

export default gql`
  mutation destroyStore($input: StoreDestroyInput!) {
    destroyStore(input: $input) {
      clientMutationId
      store {
        ...StoreInfo
      }
    }
  }
  ${StoreInfo}
`;
