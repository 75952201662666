import { Menu } from '@headlessui/react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const ACTIVE_TEXT_COLOR = 'text-rcprimary-400';
const DEFAULT_TEXT_COLOR = 'text-white';
const LINK_CLASSES = 'group flex items-center px-4 py-2 text-sm';
const ICON_CLASSES =
  'group-hover:text-rcprimary-400 h-5 w-5 mr-2 text-white/60';

function SelectMenuItem({
  to = null,
  onClick = null,
  children,
  icon: Icon = null,
}) {
  return (
    <Menu.Item>
      {({ active }) =>
        to ? (
          <NavLink
            to={to}
            className={`${
              active ? ACTIVE_TEXT_COLOR : DEFAULT_TEXT_COLOR
            } ${LINK_CLASSES}}`}
          >
            {Icon && <Icon className={ICON_CLASSES} />}
            {children}
          </NavLink>
        ) : (
          <button
            type="button"
            onClick={onClick}
            className={`${
              active ? ACTIVE_TEXT_COLOR : DEFAULT_TEXT_COLOR
            } ${LINK_CLASSES}}`}
          >
            {Icon && <Icon className={ICON_CLASSES} />}
            {children}
          </button>
        )
      }
    </Menu.Item>
  );
}

SelectMenuItem.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  icon: PropTypes.object,
};

export default SelectMenuItem;
