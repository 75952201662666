import { gql } from '@apollo/client';

const ReferralTypeInfoShared = gql`
  fragment ReferralTypeInfoShared on ReferralType {
    id
    name
    description
    partnerPays
    isPreTreatment
    isSelectableByPartner
    productId
    productIds
    slug
    createdAt
    updatedAt
  }
`;

export default ReferralTypeInfoShared;
