import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '../fragments';

export default gql`
  mutation actionS3Sign($input: SignS3ActionInput!) {
    actionSignS3(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      s3Response {
        signedRequest
        url
      }
    }
  }
  ${ErrorMessageInfo}
`;
