const awsAuthConfig = {
  development: {
    Auth: {
      Cognito: {
        identityPoolId: 'us-west-2:5fb9553a-d8a6-4500-be3e-15c73c521093',
        userPoolId: 'us-west-2_2CSQ3NaFb',
        userPoolClientId: '3n856ujuq2o0bb525e3kmumt31',
      },
    },
  },
  staging: {
    Auth: {
      Cognito: {
        identityPoolId: 'us-west-2:fcd87314-6702-4c9c-8a7a-a8cc2d76d5eb',
        userPoolId: 'us-west-2_hSZN4iGXn',
        userPoolClientId: '2boedjiq8jnft2s9mn5oksa6qb',
      },
    },
  },
  production: {
    Auth: {
      Cognito: {
        identityPoolId: 'us-west-2:e38d6230-0ab2-47b7-98a7-7eef571f9e71',
        userPoolId: 'us-west-2_eFbSm995A',
        userPoolClientId: '7k4aa7rbir9ae728a2i0tjmtc6',
      },
    },
  },
};

export default awsAuthConfig;
