import { useFormValues } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, SubmitButton } from '../../../../shared';
import ReferralsProductionListModalConfirm from './ReferralsProductionListModalConfirm';

function ReferralsProductionListModalFormSubmit({
  isSubmitting,
  isValid,
  isLoading,
  loading,
  partnerLocationId = null,
  handleSubmit,
}) {
  const [open, setOpen] = useState(false);
  const { productIds } = useFormValues();

  return (
    <>
      <div className="col-span-full mt-4 flex items-center justify-end gap-x-4">
        {productIds?.length ? (
          <Button
            onClick={() => setOpen(true)}
            name="Place Order & Update Case Details"
            className="inline-flex w-full items-center justify-center px-5 py-2.5 font-semibold"
          />
        ) : (
          <SubmitButton
            isLoading={isLoading || loading}
            isSubmitting={isSubmitting}
            isValid={isValid}
            label="Update Case Details"
            full
            notRounded
          />
        )}
      </div>
      {open && (
        <ReferralsProductionListModalConfirm
          open={open}
          setOpen={setOpen}
          isLoading={isLoading}
          loading={loading}
          isSubmitting={isSubmitting}
          isValid={isValid}
          partnerLocationId={partnerLocationId}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
}

ReferralsProductionListModalFormSubmit.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  partnerLocationId: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
};

export default ReferralsProductionListModalFormSubmit;
