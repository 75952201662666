/* eslint-disable jsx-a11y/label-has-associated-control */
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className="h-5 w-5 shrink-0 self-center" />
    </components.DropdownIndicator>
  );
}

function ClearIndicator(props) {
  return (
    <components.ClearIndicator {...props}>
      <XMarkIcon className="h-5 w-5 shrink-0 self-center" />
    </components.ClearIndicator>
  );
}

function MultiValueRemove(props) {
  return (
    <components.MultiValueRemove {...props}>
      <XMarkIcon className="h-3 w-3 shrink-0 self-center" />
    </components.MultiValueRemove>
  );
}

const controlStyles = {
  base: 'border rounded-lg bg-white hover:cursor-pointer',
  focus: 'border-rcprimary-400 ring-1 ring-rcprimary-400',
  nonFocus: 'border-gray-300 hover:border-gray-400',
};
const placeholderStyles = 'text-gray-500 pl-1 py-0.5';
const selectInputStyles = 'pl-1 py-0.5';
const valueContainerStyles = 'p-1 gap-1';
const singleValueStyles = 'leading-7 ml-1 capitalize';
const multiValueStyles =
  'bg-rcprimary-400 rounded items-center py-0.5 pl-2 pr-1 gap-1.5';
const multiValueLabelStyles = 'leading-6 py-0.5';
const multiValueRemoveStyles =
  'border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md';
const indicatorsContainerStyles = 'p-1 gap-1';
const clearIndicatorStyles =
  'text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800';
const indicatorSeparatorStyles = 'bg-gray-300';
const dropdownIndicatorStyles =
  'p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black';
const menuStyles = 'p-1 mt-2 border border-gray-200 bg-white rounded-lg ';
const groupHeadingStyles = 'ml-3 mt-2 mb-1 text-gray-500 text-sm';
const optionStyles = {
  base: 'hover:cursor-pointer px-3 py-2 rounded capitalize',
  focus: 'bg-rcprimary-400 active:bg-gray-200',
  selected:
    "after:content-['✔'] after:ml-2 after:text-rcprimary-400 text-black",
};
const noOptionsMessageStyles =
  'text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm';

function TableStateSelect({
  value = null,
  onChange,
  states = [
    { id: 'recent', name: 'recent (30 Days)' },
    { id: 'sent', name: 'sent' },
    { id: 'unsent', name: 'not sent' },
    { id: 'overdue', name: 'overdue' },
    { id: 'completed', name: 'completed' },
    { id: 'canceled', name: 'canceled' },
  ],
}) {
  return (
    <div className="z-[59] flex w-1/3 flex-row items-center">
      <label className="mr-4 text-sm font-medium capitalize leading-6 text-black/40">
        Status
      </label>
      <Select
        classNamePrefix="addl-class"
        options={states}
        value={states.find((c) => value === c?.id)}
        onChange={(val) => onChange(val?.id)}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        closeMenuOnSelect
        hideSelectedOptions={false}
        unstyled
        components={{
          DropdownIndicator,
          ClearIndicator,
          MultiValueRemove,
        }}
        styles={{
          input: (base) => ({
            ...base,
            'input:focus': {
              boxShadow: 'none',
            },
          }),
          multiValueLabel: (base) => ({
            ...base,
            whiteSpace: 'normal',
            overflow: 'visible',
          }),
          control: (base) => ({
            ...base,
            transition: 'none',
          }),
          container: (base) => ({
            ...base,
            width: '100%',
          }),
        }}
        classNames={{
          control: ({ isFocused }) =>
            `${isFocused ? controlStyles.focus : controlStyles.nonFocus}
                     ${controlStyles.base}`,
          placeholder: () => placeholderStyles,
          input: () => selectInputStyles,
          valueContainer: () => valueContainerStyles,
          singleValue: () => singleValueStyles,
          multiValue: () => multiValueStyles,
          multiValueLabel: () => multiValueLabelStyles,
          multiValueRemove: () => multiValueRemoveStyles,
          indicatorsContainer: () => indicatorsContainerStyles,
          clearIndicator: () => clearIndicatorStyles,
          indicatorSeparator: () => indicatorSeparatorStyles,
          dropdownIndicator: () => dropdownIndicatorStyles,
          menu: () => menuStyles,
          groupHeading: () => groupHeadingStyles,
          option: ({ isFocused, isSelected }) =>
            `${isFocused && optionStyles.focus} ${
              isSelected && optionStyles.selected
            } ${optionStyles.base}`,
          noOptionsMessage: () => noOptionsMessageStyles,
        }}
      />
    </div>
  );
}

TableStateSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  states: PropTypes.array,
};

export default TableStateSelect;
