import { MapPinIcon, PaperAirplaneIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { MapViewContext } from '../../../shared/Context';

function SidebarCard({ result, distance = null, index, listItemClick }) {
  const { setCurrentPartner, setRequestScanOpen } = useContext(MapViewContext);

  const phoneNumber = useMemo(
    () => parsePhoneNumber(result.phone || '', 'US'),
    [result.phone],
  );

  function handleRequestScan() {
    setCurrentPartner(result);
    setRequestScanOpen(true);
  }

  return (
    <li
      className={`flex items-center justify-between gap-x-2 p-8 ${
        index % 2 === 0 ? 'bg-white' : 'bg-white/50'
      }`}
    >
      <div className="flex w-full min-w-0 gap-x-4">
        <img
          className="size-20 flex-none bg-gray-50"
          src={result.imageUrl || `https://cdn.themjm.com/logos/default.svg`}
          alt={result.name}
        />
        <div className="flex w-full min-w-0 flex-auto flex-col">
          <p className="leading-1 text-sm font-semibold text-black">
            {result.name.length > 55
              ? `${result.name.substring(0, 55)}...`
              : result.name}
          </p>
          <div className="flex grow flex-row items-start justify-between">
            <p className="mt-0 truncate text-xs leading-5 text-black/70">
              {result.city ? `${result.city},` : null}{' '}
              {result.region ? result.region : null}{' '}
              {result.postalCode ? result.postalCode : null}
            </p>
            <p className="mt-0 truncate text-xs leading-5 text-black/50">{`${distance.toFixed(
              2,
            )}mi`}</p>
          </div>
          {result.phone && (
            <div>
              <p className="mt-0 text-xs leading-4 text-black/70">
                <a href={`tel:${result.phone}`}>
                  {phoneNumber
                    ? formatPhoneNumber(phoneNumber.number)
                    : result.phone}
                </a>
              </p>
            </div>
          )}

          {result.website && (
            <div>
              <p className="mt-0 text-xs leading-4 text-black/70">
                <a
                  href={`https://${result.website}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-rcprimary-400"
                >
                  {result.website}
                </a>
              </p>
            </div>
          )}

          <div className="mt-1 flex grow flex-row justify-between">
            <button
              type="button"
              onClick={() => listItemClick(index)}
              className="hover:bg-rcprimary-50 flex flex-row items-center bg-white px-2.5 py-1 text-xs font-semibold text-black shadow-sm ring-1 ring-inset ring-gray-300"
            >
              <MapPinIcon
                className="text-rcprimary-400 mr-1 size-4"
                aria-hidden="true"
              />
              View on Map
            </button>
            <button
              type="button"
              onClick={() => handleRequestScan()}
              className="hover:bg-rcprimary-50 flex flex-row items-center bg-white px-2.5 py-1 text-xs font-semibold text-black shadow-sm ring-1 ring-inset ring-gray-300"
            >
              <PaperAirplaneIcon
                className="text-rcprimary-400 mr-1 size-4"
                aria-hidden="true"
              />
              Request A Scan
            </button>
          </div>
        </div>
      </div>
    </li>
  );
}

SidebarCard.propTypes = {
  result: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  listItemClick: PropTypes.func.isRequired,
  distance: PropTypes.number,
};

export default SidebarCard;
