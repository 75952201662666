import PropTypes from 'prop-types';
import Card from '../../Card/Card';

function ShowImage({ imageUrl = null, imageAlt = null }) {
  return imageUrl ? (
    <Card className="size-full overflow-hidden p-8" wrapperClassName="h-full">
      <img
        src={imageUrl}
        alt={imageAlt}
        className="size-full object-contain object-center"
      />
    </Card>
  ) : null;
}

ShowImage.propTypes = {
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
};

export default ShowImage;
