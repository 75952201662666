import { gql } from '@apollo/client';
import { SubscriptionInfo } from '../fragments';

export default gql`
  query subscriptions {
    subscriptions {
      ...SubscriptionInfo
    }
  }
  ${SubscriptionInfo}
`;
