import { gql } from '@apollo/client';
import { PriceBookInfo } from '../fragments';

export default gql`
  query priceBook($slug: String, $id: ID) {
    priceBook(slug: $slug, id: $id) {
      ...PriceBookInfo
    }
  }
  ${PriceBookInfo}
`;
