import { gql } from '@apollo/client';

export default gql`
  query productRenewalIntervalScales {
    productRenewalIntervalScales {
      id
      name
    }
  }
`;
