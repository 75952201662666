import { gql } from '@apollo/client';

const PartnerLocationInfoPublic = gql`
  fragment PartnerLocationInfoPublic on PartnerLocationShared {
    id
    name
    email
    phone
    website
    slug
    imageUrl
    address {
      id
      address
      address2
      city
      region
      postalCode
      country
    }
  }
`;

export default PartnerLocationInfoPublic;
