import { Button } from '@retainerclub/common';
import PropTypes from 'prop-types';
import SidebarActions from './SidebarActions/SidebarActions';
import SidebarCards from './SidebarCards';

function LocatorMapSidebar({
  resetFilters,
  results,
  listItemClick,
  loading,
  error = null,
  networkStatus = null,
  totalObjects = 0,
  setNoResultsOpen,
}) {
  return (
    <>
      <SidebarActions resetFilters={resetFilters} totalObjects={totalObjects} />
      <div className="overflow-y-scroll">
        {results.length === 0 ? (
          <div className="my-8 flex flex-col items-center justify-center">
            <p className="text-center text-2xl font-semibold text-black/70">
              Sorry, we couldn&apos;t find a partner near you.
            </p>
            <p className="text-center text-sm text-black/50">
              Try adjusting your location
            </p>
            <div className="relative my-4 w-1/2">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-black/50" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-rcgray-50 px-2 text-sm text-gray-500">
                  OR
                </span>
              </div>
            </div>
            <Button name="Let Us Know" onClick={() => setNoResultsOpen(true)} />
          </div>
        ) : (
          <SidebarCards
            results={results}
            listItemClick={listItemClick}
            loading={loading}
            error={error}
            networkStatus={networkStatus}
          />
        )}
      </div>
    </>
  );
}

LocatorMapSidebar.propTypes = {
  resetFilters: PropTypes.func.isRequired,
  results: PropTypes.array.isRequired,
  listItemClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  networkStatus: PropTypes.number,
  totalObjects: PropTypes.number,
  setNoResultsOpen: PropTypes.func.isRequired,
};

export default LocatorMapSidebar;
