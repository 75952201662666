import { useFormValues } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { DatePicker } from '../../../shared';

function ReferralsFormPatientScanReady({
  scanReadyAt = 'patientAttributes.scanReadyAt',
  scanSentAt = 'patientAttributes.scanSentAt',
  usePatientAttributes = true,
}) {
  const { patientAttributes, scanReady } = useFormValues();

  return (usePatientAttributes && patientAttributes?.scanReady) ||
    (!usePatientAttributes && scanReady) ? (
    <div className="relative col-span-full sm:col-span-2">
      <DatePicker name={scanSentAt} label="Date the scan was sent" />
    </div>
  ) : (
    <div className="relative col-span-full sm:col-span-2">
      <DatePicker
        name={scanReadyAt}
        label="Est. date the scan will be ready"
        isFutureDate
      />
    </div>
  );
}

ReferralsFormPatientScanReady.propTypes = {
  scanReadyAt: PropTypes.string,
  scanSentAt: PropTypes.string,
  usePatientAttributes: PropTypes.bool,
};

export default ReferralsFormPatientScanReady;
