import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import PropTypes from 'prop-types';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

function ShowLocationMap({ address }) {
  L.Icon.Default.imagePath =
    '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.4/images/';

  return (
    <div className="hidden h-52 sm:block">
      <MapContainer
        center={[address.latitude, address.longitude]}
        zoom={14}
        style={{ height: '100%' }}
      >
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/retainerclub/clmsbq9b701z701rc20a42o7w/tiles/256/{z}/{x}/{y}@2x?access_token=${
            import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
          }`}
          zIndex={0}
        />
        <Marker position={[address.latitude, address.longitude]} />
      </MapContainer>
    </div>
  );
}

ShowLocationMap.propTypes = {
  address: PropTypes.object.isRequired,
};

export default ShowLocationMap;
