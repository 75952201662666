import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { StoreInfo } from '../fragments';

export default gql`
  mutation createStore($input: StoreCreateInput!) {
    createStore(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      store {
        ...StoreInfo
      }
    }
  }
  ${StoreInfo}
  ${ErrorMessageInfo}
`;
