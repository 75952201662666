import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { ProductRenewalIntervalInfo } from '../fragments';

export default gql`
  mutation updateProductRenewalInterval(
    $input: ProductRenewalIntervalUpdateInput!
  ) {
    updateProductRenewalInterval(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      productRenewalInterval {
        ...ProductRenewalIntervalInfo
      }
    }
  }
  ${ProductRenewalIntervalInfo}
  ${ErrorMessageInfo}
`;
