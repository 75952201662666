import { gql } from '@apollo/client';
import { ProductRenewalIntervalInfo } from '../fragments';

export default gql`
  query productRenewalIntervals {
    productRenewalIntervals {
      ...ProductRenewalIntervalInfo
    }
  }
  ${ProductRenewalIntervalInfo}
`;
