import { gql } from '@apollo/client';

export default gql`
  query referralTypes($partnerLocationId: ID, $isSelectableByPartner: Boolean) {
    referralTypes(
      partnerLocationId: $partnerLocationId
      isSelectableByPartner: $isSelectableByPartner
    ) {
      id
      name
      description
      slug
      isPreTreatment
      isSelectableByPartner
      createdAt
      updatedAt
    }
  }
`;
