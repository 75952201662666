import PropTypes from 'prop-types';
import { Card, ShowCustomerUsers, ShowPatient } from '../../../shared';
import OrderShowDetails from './OrderShowDetails';

function OrderShowBody({ order }) {
  return (
    <div className="flex flex-col gap-2 md:flex-row">
      <div className="flex basis-1 flex-col md:basis-1/3">
        <Card
          className="h-full p-4"
          heading="Details"
          wrapperClassName="h-full"
        >
          <div className="mt-4" />
          <OrderShowDetails order={order} />
        </Card>
      </div>

      <div className="flex basis-1 flex-col md:basis-1/3">
        <ShowCustomerUsers singleUser={order.user} />
      </div>
      <div className="flex basis-1 flex-col md:basis-1/3">
        <ShowPatient
          heading="Patients"
          patients={order.orderItems.map((orderItem) => orderItem.patient)}
        />
      </div>
    </div>
  );
}

OrderShowBody.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderShowBody;
