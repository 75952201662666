import { gql } from '@apollo/client';

const AccountAddressInfoShared = gql`
  fragment AccountAddressInfoShared on AccountAddress {
    id
    address
    address2
    city
    region
    postalCode
    country
    isBilling
    isShipping
    isDefault
    accountId
  }
`;

export default AccountAddressInfoShared;
