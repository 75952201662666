import PropTypes from 'prop-types';
import Card from '../../Card/Card';

const NOT_AVAILABLE = 'No Active Subscriptions';

function ShowContact({ subscriptions = [], className = '' }) {
  return (
    <Card
      className={`h-full p-4 md:mt-0 ${className}`}
      wrapperClassName="h-full"
      heading="Subscriptions"
    >
      {subscriptions.length > 0 ? (
        <div className="text-black">subscriptions go here</div>
      ) : (
        <div className="flex h-full w-full flex-col items-center justify-center">
          <h1 className="text-black">{NOT_AVAILABLE}</h1>
        </div>
      )}
    </Card>
  );
}

ShowContact.propTypes = {
  className: PropTypes.string,
  subscriptions: PropTypes.array,
};

export default ShowContact;
