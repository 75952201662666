import { gql } from '@apollo/client';

const SubscriptionInfo = gql`
  fragment SubscriptionInfo on Subscription {
    id
    createdAt
    updatedAt
  }
`;

export default SubscriptionInfo;
