import { useQuery } from '@apollo/client';
import {
  GET_ACCOUNT_VERSIONS,
  GET_SHOP_VERSIONS,
} from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import ActivityFeed from '../../ActivityFeed/ActivityFeed';
import Card from '../../Card/Card';
import LoadingOrError from '../../Loader/LoadingOrError';

const ACCOUNT_KLASSES = ['Referral', 'User', 'Account', 'Patient'];
const SHOP_KLASSES = ['Order'];

function ShowActivity({ id = null, klass, className = '' }) {
  let dataName;

  if (ACCOUNT_KLASSES.includes(klass)) {
    dataName = 'accountVersions';
  }

  if (SHOP_KLASSES.includes(klass)) {
    dataName = 'shopVersions';
  }

  function getQuery() {
    switch (klass) {
      case 'Account':
      case 'User':
      case 'Referral':
        return GET_ACCOUNT_VERSIONS;
      case 'Order':
        return GET_SHOP_VERSIONS;
      case 'PartnerLocation':
      case 'PartnerUser':
      case 'PartnerOrganization':
        return null;

      default:
        return GET_ACCOUNT_VERSIONS;
    }
  }

  const { data, loading, error } = useQuery(getQuery(), {
    variables: { id, klass },
  });

  return (
    <Card className={`relative p-4 ${className}`} heading="Activity">
      {loading || error || data === null || data === 'undefined' ? (
        <LoadingOrError error={error} loading title="Activity" />
      ) : (
        <div className="max-h-[500px] overflow-y-scroll">
          <ActivityFeed activity={data[dataName]} />
        </div>
      )}
    </Card>
  );
}

ShowActivity.propTypes = {
  id: PropTypes.string,
  klass: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ShowActivity;
