import { PlusIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

function GridCard({ children, newTo = null, newTitle = null }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center justify-end">
        {newTo && newTitle && (
          <NavLink
            to={newTo}
            className="focus-visible:outline-rcprimary-400 bg-rcprimary-400 hover:bg-rcprimary-400/0 hover:text-rcprimary-400 hover:outline-rcprimary-400 inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-center text-xs font-bold text-black outline outline-2 outline-offset-0 outline-white/0 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            {t(`New ${newTitle}`)}
          </NavLink>
        )}
      </div>
      <ul className="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3">
        {children}
      </ul>
    </>
  );
}

GridCard.propTypes = {
  children: PropTypes.node.isRequired,
  newTo: PropTypes.string,
  newTitle: PropTypes.string,
};

export default GridCard;
