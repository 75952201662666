import { gql } from '@apollo/client';
import { PartnerOrganizationInfo } from '../fragments';

export default gql`
  query partnerOrganization($slug: String, $id: ID) {
    partnerOrganization(slug: $slug, id: $id) {
      ...PartnerOrganizationInfo
    }
  }
  ${PartnerOrganizationInfo}
`;
