import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { PartnerUserInfo } from '../fragments';

export default gql`
  mutation createPartnerUser($input: PartnerUserCreateInput!) {
    createPartnerUser(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      partnerUser {
        ...PartnerUserInfo
      }
    }
  }
  ${PartnerUserInfo}
  ${ErrorMessageInfo}
`;
