import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { AdminTagInfo } from '../fragments';

export default gql`
  mutation createAdminTag($input: AdminTagCreateInput!) {
    createAdminTag(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      adminTag {
        ...AdminTagInfo
      }
    }
  }
  ${AdminTagInfo}
  ${ErrorMessageInfo}
`;
