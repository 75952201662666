import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { PartnerOrganizationInfo } from '../fragments';

export default gql`
  mutation createPartnerOrganization($input: PartnerOrganizationCreateInput!) {
    createPartnerOrganization(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      partnerOrganization {
        ...PartnerOrganizationInfo
      }
    }
  }
  ${PartnerOrganizationInfo}
  ${ErrorMessageInfo}
`;
