import { gql } from '@apollo/client';

export default gql`
  query partnerLocationId($slug: String, $id: ID) {
    partnerLocation(slug: $slug, id: $id) {
      id
      settingGroupOptionIds
    }
  }
`;
