import { gql } from '@apollo/client';

export default gql`
  query defaultEotProduct {
    defaultEotProduct {
      id
      name
      description
      featuredImage
    }
  }
`;
