import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';
import { AdminLocationInfo } from '../fragments';

export default gql`
  mutation updateAdminLocation($input: AdminLocationUpdateInput!) {
    updateAdminLocation(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      adminLocation {
        ...AdminLocationInfo
      }
    }
  }
  ${AdminLocationInfo}
  ${ErrorMessageInfo}
`;
