import { gql } from '@apollo/client';
import { SettingGroupInfoShared } from '../fragments';

export default gql`
  query settingGroups {
    settingGroups {
      ...SettingGroupInfoShared
    }
  }
  ${SettingGroupInfoShared}
`;
